import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { activeUser, addChatMessage, paths } from "../api";
import { toArray } from "lodash";
import { withRouter } from "react-router-dom";
import Contexts from "../contexts";
import moment from "moment";
import * as firebase from "firebase";

let trLocale = require("moment/locale/tr");
moment.locale("tr", trLocale);

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a style="color: #00a3b4" href="' + url + '" target="_blank">' + url + ' </a>';
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

class VideoComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: {},
      newMessage: ""
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.video !== nextProps.video) {
      this.loadChatMessages();
    }
  }
  componentDidMount() {
    if (activeUser()) this.loadChatMessages();
  }

  componentDidUpdate() {}
  sendChatMessage() {
    if (this.state.newMessage.length > 0) {
      addChatMessage(
        this.state.newMessage.substring(0, 500),
        this.props.video.id,
        null,
        null,
      ).then(result => {
        this.setState({newMessage: ""});
      });
    }
  }
  loadChatMessages() {
    if (this.props.video)
      var commentsRef = firebase
        .database()
        .ref(paths.videoChatMessages + "/" + this.props.video.id);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.messages[data.key]) {
        that.state.messages[data.key] = data.val();
        that.setState(that.state.messages);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.messages[data.key] = data.val();
      that.setState(that.state.messages);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.messages[data.key];
      that.setState(that.state.messages);
    });
  }
  renderMessageItem(message) {
    return (
      <span className="MainLiveView_Comments_MessageItem" key={message.id}>
        <span
          style={{ display: "flex", alignItems: "baseline", marginBottom: 2 }}
        >
          <strong style={{ marginRight: "8px" }}>{message.userName} </strong>
          <span>
            {moment
              .duration(
                moment().diff(moment(message.date * 1000), "minutes"),
                "minutes"
              )
              .humanize() + Contexts.get(Contexts.Context.ago)}
          </span>
        </span>
        <strong dangerouslySetInnerHTML={{__html: urlify(message.message)}} />
      </span>
    );
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.sendChatMessage();
    }
  }

  scrollToBottom = () => {
    if (this.messageFlow) {
      const scrollHeight = this.messageFlow.scrollHeight;
      const height = this.messageFlow.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageFlow.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  render() {
    const user = activeUser();
    const messages = [];
    if (this.state.messages) {
      toArray(this.state.messages)
        .sort((b, a) => {
          return a.approveDate
            ? a.approveDate - b.approveDate
            : a.date - b.date;
        })
        .forEach(message => {
          if (
            message &&
            user &&
            (message.approveDate || user.uid === message.userId)
          )
            messages.push(this.renderMessageItem(message));
        });
    }
    return (
      <div
        className="MainLiveView_Comments"
        style={{
          marginTop: this.props.isMobile ? this.props.Top + 20 : 20,
          /* height: this.props.isMobile ? 'calc( 100vh - '+this.props.Top+ 'px)' : 10000, */
          padding: this.props.isMobile ? "0px 6px" : 0
        }}
      >
        {messages.length !== 0 && (
          <span className="MainLiveView_Comments_Header">
            {messages.length} Yorum
          </span>
        )}
        {messages.length === 0 && (
          <span className="MainLiveView_Comments_Header">
            {Contexts.get(Contexts.Context.noComment)}
          </span>
        )}
        <div className="MainLiveView_Comments_MessageBox">
          <Input
            placeholder={Contexts.get(Contexts.Context.commentPlaceHolder)}
            value={this.state.newMessage}
            inputProps={{
              "aria-label": "description"
            }}
            onKeyPress={e => this.handleKeyPress(e)}
            onChange={e => this.setState({ newMessage: e.target.value.substring(0, 500) })}
          />
          <div style={{ textAlign: "right", marginTop: "6px" }}>
            <Button
              color="primary"
              style={{ width: 70 }}
              disabled={this.state.newMessage.length < 1 || !activeUser()}
              onClick={() => this.sendChatMessage()}
            >
              {Contexts.get(Contexts.Context.send)}
            </Button>
          </div>
        </div>
        <div
          className="MainLiveView_Comments_Flow"
          ref={r => (this.messageFlow = r)}
        >
          {messages}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <VideoComments {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
