import React from 'react';

export const defaultBase = {
  theme: {},
  data: {
      admins: [
        "9SjL38LRQEf32ijjH1tM6AdCWjP2",
        "AeJM45XQm9MiD9kYH7EKA49e5Hk1",
        "DTRaueQ511gITecvOu3cvPPzfrb2",
        "6bGacUiM0zdnuLkEiPH091VQthE3",
        "t2wpi0E3LfTCB9zIOkK9CDc0PDv2",
        "ym144f8JhhUquIPK0B1K9OmVvWr1",
        "KyLtZ3vFdOeGcIXTNKDXuQ5ELPq1",
      ],
      textContexts: null,
      albums: null,
      albumVideos: [],
      videoAlbums: [],
      videos: null,
      liveEvents: [],
      userFollows: {},
      tagVideos: {},
      videoTags: {},
      tagAlbums: {},
      albumTags: {},
      professions: null,
      specializations: null,
      interestGroups: null,
      interests: null,
      paidEvents: null,
      domain: "cerrahi.tv",
      config: {
        upcomingLiveEvents: [],
        topVideoId: '398311288',
        albums:[
          {
            albumId: '5739238',
            order: 0,
            type: 1,
          },
          {
            albumId: '5742361',
            order: 1,
            type: 1,
          }
        ]
      },
      tags: [],
      hiddenAlbums: ['6829293'],
      currentVideoId : null,
      requestedPath: null,
  },
  session: {
      uid: null,
      admin: false,
      displayName: '',
      userAgreementDone: true,
  },
};

export const BaseContext = React.createContext(
    defaultBase // default value
);