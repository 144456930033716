import React from "react";
import "../Components.css";
import PlayIcon from "../assets/img/play.svg";
import { BaseContext } from "../baseContext";
import {
  setFirebaseData,
  paths,
  addRemoveItemToFollowList,
  isUserEmailValidated,
  activeUser
} from "../api";
import { toArray } from "lodash";
import Button from "../components/materialComponents/CustomButtons/Button.jsx";
import Datetime from "react-datetime";
import EditIcon from "@material-ui/icons/Edit";
import VideoSelectDialog from "./VideoSelectDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import LiveEventReminder from "./LiveEventReminder";
import javascriptStyles from "../assets/jss/material-kit-react/views/componentsSections/javascriptStyles";
import moment from "moment-timezone";

class MainLiveVideoTumbnailMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      openVideoSelectDialog: false,
      mainLiveVideo: this.props.mainLiveVideo,
      liveEvent: null
    };
    this.setLiveEvent(props);
  }
  componentWillReceiveProps(nextProps) {
    this.setLiveEvent(nextProps);
  }
  setLiveEvent(props) {
    let { liveEvents } = this.props.baseContext.baseContext.data;
    if (!liveEvents) {
      liveEvents = [];
    }
    if (props.mainLiveVideo) {
      let liveEvent = liveEvents.find(liveEvent => {
        return liveEvent.videoId === props.mainLiveVideo.id;
      });
      if (
        !liveEvent &&
        props.mainLiveVideo &&
        props.mainLiveVideo.duration === 0
      ) {
        liveEvent = {
          videoId: props.mainLiveVideo.id
        };
        liveEvents.push(liveEvent);
        setFirebaseData(paths.liveEvents, liveEvents);
        setFirebaseData(paths.config + "/lastUpdate", moment().unix());
        this.props.baseContext.setBase(this.props.baseContext.baseContext);
      }
      this.state.mainLiveVideo = props.mainLiveVideo;
      this.state.liveEvent = liveEvent;
      this.setState({ ...this.state });
    }
  }
  selectVideo(e) {
    e.stopPropagation();
    this.setState({ openVideoSelectDialog: true });
  }

  handleSelectedItem(id) {
    const { data } = { ...this.props.baseContext.baseContext };
    data.config.topVideoId = id;
    setFirebaseData(paths.config, data.config);
    this.setState({ mainLiveVideo: data.videos[id] });
  }

  handleChangeReleaseTime(time) {
    if (time.isValid) {
      const { liveEvents } = { ...this.props.baseContext.baseContext.data };
      const liveEvent = liveEvents.find(liveEvent => {
        return liveEvent.videoId === this.state.liveEvent.videoId;
      });
      liveEvent.time = time.unix();
      setFirebaseData(paths.liveEvents, liveEvents);
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
    }
  }
  handleFollow() {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired();
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      const { mainLiveVideo } = this.state;
      const { data } = { ...this.props.baseContext.baseContext };
      if (!mainLiveVideo) {
        return this.renderBlank();
      }

      let userFollow = false;
      if (mainLiveVideo && mainLiveVideo.id && data.userFollows) {
        userFollow =
          data.userFollows[mainLiveVideo.id] &&
          data.userFollows[mainLiveVideo.id].follow;
      }
      addRemoveItemToFollowList(mainLiveVideo.id, !userFollow);
      const { userFollows } =
        { ...this.props.baseContext.baseContext.data } || {};
      userFollows[mainLiveVideo.id] = { follow: !userFollow };
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
    }
  }
  renderBlank() {
    return (
      <div style={{ width: "100%" }}>
        <div className="MainLiveVideoTumbnailContainer_blank" />
        <div className="MainLiveVideoTumbnail_Bottom_blank">
          <div className="MainLiveVideoTumbnail_BottomTitleBar">
            <div className="MainLiveVideoTumbnail_BottomTitle_blank" />
            <div className="MainLiveVideoTumbnail_BottomDescription_blank" />
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { liveEvent, mainLiveVideo } = this.state;
    const { data } = { ...this.props.baseContext.baseContext };
    if (!mainLiveVideo) {
      return this.renderBlank();
    }
    const isOnline =
      liveEvent && (!liveEvent.time || liveEvent.time < moment().unix());
    let userFollow = false;
    if (mainLiveVideo && mainLiveVideo.id && data.userFollows) {
      userFollow =
        data.userFollows[mainLiveVideo.id] &&
        data.userFollows[mainLiveVideo.id].follow;
    }
    let { videoTags, tags } = data;
    let tagItems = null;

    if (mainLiveVideo && videoTags && tags) {
      tagItems = toArray(tags)
        .filter(tag => {
          return (
            videoTags[mainLiveVideo.id] &&
            videoTags[mainLiveVideo.id].indexOf(tag.id) > -1
          );
        })
        .map(map => (
          <div
            key={map.id}
            className={"tag"}
            onClick={() => {
              this.props.history.push(
                "/searchResult?search=" + map.name + "&type=tag"
              );
            }}
          >
            #{map.name}
          </div>
        ));
    }
    return (
      <div className="MainLiveVideoTumbnail">
        {liveEvent && (
          <LiveEventReminder
            time={liveEvent.time}
            userFollow={userFollow}
            onClick={() => this.handleFollow()}
          />
        )}
        <VideoSelectDialog
          open={this.state.openVideoSelectDialog}
          onChange={v => {
            this.handleSelectedItem(v);
          }}
          closeDialog={() => {
            this.setState({ openVideoSelectDialog: false });
          }}
        />
        <div className="MainLiveVideoTumbnailContainer">
          {mainLiveVideo && (
            <div
              className="MainLiveVideoTumbnail_Content"
              onClick={() =>
                !this.props.admin && this.props.onClick(mainLiveVideo.id)
              }
              onMouseEnter={() => this.setState({ hover: true })}
              onMouseLeave={() => this.setState({ hover: false })}
            >
              {this.props.admin && (
                <Button
                  style={{ zIndex: 1 }}
                  justIcon
                  round
                  color="info"
                  onClick={e => this.selectVideo(e)}
                >
                  <EditIcon />
                </Button>
              )}
              {this.props.admin && liveEvent && (
                <div className="MainLiveVideoTumbnail_ReleaseDatePicker">
                  <Datetime
                    onChange={v => {
                      this.handleChangeReleaseTime(v);
                    }}
                    value={moment.unix(new Date(liveEvent.time)).toDate()}
                    inputProps={{ placeholder: "Datetime Picker Here" }}
                  />
                </div>
              )}
              <img
                className="MainLiveVideoTumbnail_Image"
                src={mainLiveVideo.imageUrl}
                alt="thumbnail"
              />
              {this.state.hover &&
                !this.props.admin &&
                mainLiveVideo &&
                mainLiveVideo.duration !== 0 && (
                  <img
                    className="MainLiveVideoTumbnail_Play"
                    src={PlayIcon}
                    alt="play"
                  />
                )}
            </div>
          )}
        </div>
        <div className="MainLiveVideoTumbnail_Bottom">
          {tagItems && tagItems.length > 0 && (
            <div
              style={{ display: "flex", marginTop: "4px", marginBottom: "6px" }}
            >
              {tagItems}
            </div>
          )}
          <div className="MainLiveVideoTumbnail_BottomTitleBar">
            <div className="MainLiveVideoTumbnail_BottomTitle">
              <span>{mainLiveVideo.name}</span>
            </div>
            <div className="MainLiveVideoTumbnail_BottomDate">
              <span>
                {liveEvent &&
                  !isOnline &&
                  moment
                    .tz(new Date(liveEvent.time) * 1000, "Europe/Istanbul")
                    .tz(moment.tz.guess())
                    .format("DD/MM/YYYY HH:mm z")}
                {!liveEvent &&
                  !isOnline &&
                  mainLiveVideo.created_time &&
                  moment
                    .tz(mainLiveVideo.created_time, "Europe/Istanbul")
                    .tz(moment.tz.guess())
                    .format("DD/MM/YYYY HH:mm z")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => (
      <MainLiveVideoTumbnailMobile {...props} baseContext={context} ref={ref} />
    )}
  </BaseContext.Consumer>
));

export default withStyles(javascriptStyles)(withContext);
