import React from "react";
import "../Components.css";
import ResponsiveEmbed from "../components/ResponsiveEmbed";
import { BaseContext } from "../baseContext";
import Button from "components/materialComponents/CustomButtons/Button.jsx";
import Badge from "../components/materialComponents/Badge/Badge.jsx";
import { activeUser } from "../api";
import { withRouter } from "react-router-dom";

class MainLiveVideoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: {
        id: "332452152",
        name: "Çok Yakında",
        description:
          "Nitelikli cerrahi videoların sağlık profesyonelleriyle paylaşıldığı hem seç-izle video hem de canlı yayın tabanlı Türkiye'nin ilk dijital cerrahi paylaşım platformu çok yakında sizlerle. Gelişmelerden haberdar olmak ve videolarınızı şimdiden paylaşmak için kayıt yapabilirsiniz."
      }
    };
  }

  componentWillReceiveProps(nextProps) {}

  renderReactFrame() {
    const url =
      "https://player.vimeo.com/video/" +
      this.state.video.id +
      "?autoplay=1&loop=1&autopause=0&muted=1";
    return (
      <ResponsiveEmbed
        src={url}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
      ></ResponsiveEmbed>
    );
  }
  renderChatFrame() {
    const url = "https://vimeo.com/live-chat/" + this.state.video.id + "?";
    return <ResponsiveEmbed src={url} frameBorder="0"></ResponsiveEmbed>;
  }
  renderDetails() {
    const registerNavLink = {
      color: "#fff",
      top: "3px",
      position: "relative",
      padding: "8px 24px",
      fontWeight: "400",
      fontSize: "16px",
      textTransform: "uppercase",
      lineHeight: "18px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex"
    };
    const video = this.state.video;
    let tags = null;
    if (video && video.tags) {
      tags = video.tags.map(map => <Badge>#{map}</Badge>);
    }
    if (video) {
      return (
        <div className="MainLiveView_Details">
          <div className="h1">{video.name}</div>
          <div style={{ display: "flex", marginBottom: "10px" }}>{tags}</div>
          <div className="h3">{video.description}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              padding: "20px 0px"
            }}
          >
            <Button
              size={this.props.isMobile ? "sm" : ""}
              className={registerNavLink}
              onClick={() => {
                this.props.history.push(
                  activeUser() ? "videoUpload" : "/signUp"
                );
              }}
              color="info"
              round
            >
              {activeUser() ? "Video Yükle" : "Hemen Kaydol"}
            </Button>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="Main">
        <div className="MainLiveView" style={{ flexDirection: "column" }}>
          <div className="MainLiveVideoViewContainer">
            {this.state.video && (
              <div className="MainLiveViewVideo">{this.renderReactFrame()}</div>
            )}
          </div>
          {this.renderDetails()}
          <a style={{ marginTop: "16px" }} href="mailto:info@cerrahi.tv">
            info@cerrahi.tv
          </a>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <MainLiveVideoView {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
