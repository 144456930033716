import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { BaseContext } from "../baseContext";
import DialogContent from "@material-ui/core/DialogContent";
import { toMap } from "../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Contexts from "../contexts";
import { withStyles } from "@material-ui/core/styles";
import { sortBy, pull } from "lodash";
import { setUserTags, activeUser } from "../api";

const CustomCheckbox = withStyles({
  root: {
    color: "#dcdcdc",
    "&$checked": {
      color: "#3cb5ae"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class UserTagsDialog extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props.baseContext.baseContext;
    this.state = {
      search: "",
      userTags: (data.userData && data.userData.userTags) || []
    };
  }
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps.baseContext.baseContext;
    this.setState({
      userTags: (data.userData && data.userData.userTags) || []
    });
  }
  handleChange(id) {
    const { userTags } = this.state;
    if (userTags.indexOf(id) > -1) {
      pull(userTags, id);
    } else userTags.push(id);

    this.setState({ userTags });
  }
  handleSave() {
    setUserTags(activeUser().uid, this.state.userTags);
    this.props.closeDialog();
  }
  renderTags() {
    const { data } = this.props.baseContext.baseContext;
    const { userTags } = this.state;
    let tags = [];
    sortBy(toMap(data.interests), tag => tag.name.toLowerCase()).forEach(tag => {
      tags.push(
        <div
          className="user_tag"
          onClick={() => this.handleChange(tag.id)}
          style={{ marginBottom: 12, marginRight: 12 }}
        >
          <CustomCheckbox
            onChanhe={() => this.handleChange(tag.id)}
            checked={userTags.indexOf(tag.id) > -1}
            fontSize="small"
            value={userTags.indexOf(tag.id) > -1}
            color="primary"
          />
          {tag.name}
        </div>
      );
    });
    return (
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {tags}
      </div>
    );
  }

  render() {
    const { data } = this.props.baseContext.baseContext;
    if (!data.userData) {
      return null;
    }
    return (
      <div>
        <Dialog
          maxWidth="md"
          open={this.props.open}
          onClose={() => this.props.closeDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {Contexts.get(Contexts.Context.userTags)}
          </DialogTitle>

          <DialogContent>
            <div style={{ marginBottom: 10 }}>
              {Contexts.get(Contexts.Context.userTagsDescription)}
            </div>
            {this.renderTags()}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => this.handleSave()} color="primary">
              {Contexts.get(Contexts.Context.save)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <UserTagsDialog {...props} baseContext={context} ref={ref} />}
  </BaseContext.Consumer>
));

export default withContext;
