import React from 'react';
import '../Layouts.css';
import VideoUpload from '../components/VideoUpload';

export default class App extends React.Component {
  render() {
    return (
        <div className="Main">
            <VideoUpload/>
        </div>
    );
  }
}
