import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Contexts from "../contexts";
import { withRouter } from "react-router-dom";

class RealtimeMessageDialog extends React.Component {
  handleClose(value) {
    if (this.props.closeDialog) {
      this.props.closeDialog();
    }
  }

  render() {
    const { message, actionUrl, videoId, history } = this.props;
    if (!videoId) return null;
    return (
      <div>
        <Dialog
          open={this.props.open}
          maxWidth={"xs"}
          fullWidth={false}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
        >
          {/* <DialogTitle id="form-dialog-title">
            {Contexts.get(Contexts.Context.sessionIsOpenTitle)}
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText>
              <span>{message}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button
              onClick={() => {
                history.push("/");
              }}
              color="primary"
            >
              {Contexts.get(Contexts.Context.cancel)}
            </Button> */}
            <Button
              onClick={() => {
                if (actionUrl === "refresh") {
                  window.location.reload(false);
                }
                else if (actionUrl) {
                  window.location = actionUrl;
                } else {
                  this.props.closeDialog();
                }
              }}
              color="primary"
              autoFocus
            >
              {Contexts.get(Contexts.Context.ok)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(RealtimeMessageDialog);
