import React from "react";
import classNames from "classnames";
import Contexts from "../contexts";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
    logOut,
  } from "../api";
import customDropdownStyle from "assets/jss/material-kit-react/components/customDropdownStyle.jsx";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClick() {
    this.setState(state => ({ open: !state.open }));
  }
  handleClose(param) {
    this.setState({ open: false });
    if (param) {
      this.handleMenuAction(param);
    }
  }
  handleCloseAway = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };
  handleMenuAction(action) {
    if (action === "signOut") {
      logOut().then(() => {
        this.props.history.push("/");
      });
    } else if (action === "resetPassword") {
      // logOut();
      // this.props.history.push('/signIn');
    } else if (action === "userTags") {
      this.props.baseContext.setShowUserTagsDialog(true);
    }else {
      this.props.history.push(action);
    }
  }
  getItems() {
    if (!this.props.isAdmin) {
      return [
        {
          action: "history",
          title: Contexts.get(Contexts.Context.myHistory)
        },
        { action: "signOut", title: Contexts.get(Contexts.Context.signOut) }
      ];
    } else {
      return [
        {
          action: "userTags",
          title: Contexts.get(Contexts.Context.userTags)
        },
        { action: "users", title: "Users" },
        { action: "newMessages", title: "New Messages" },
        { action: "tags", title: "Tags" },
        { action: "videos", title: "Videos" },
        { action: "albums", title: "Albums" },
        { action: "uploadedVideos", title: "Uploaded Videos" },
        {  action: "history",
        title: Contexts.get(Contexts.Context.myHistory)},
        { action: "signOut", title: Contexts.get(Contexts.Context.signOut) }
      ];
    }
  }
  render() {
    const { open } = this.state;
    const dropdownList = this.getItems();
    const {
      classes,
      dropup,
      dropdownHeader,
      hoverColor,
      rtlActive,
      noLiPadding,
      user
    } = this.props;

    const image = user.photoURL;
    const dropdownItem = classNames({
      [classes.dropdownItem]: true,
      [classes[hoverColor + "Hover"]]: true,
      [classes.noLiPadding]: noLiPadding,
      [classes.dropdownItemRTL]: rtlActive
    });
    return (
      <div>
        <div>
          <IconButton
            buttonRef={node => {
              this.anchorEl = node;
            }}
            onClick={this.handleClick}
          >
            {!image && <MenuIcon />}
            {image && (
              <img style={{ width: 32, borderRadius: "50%" }} src={image} alt="profile"/>
            )}
          </IconButton>
        </div>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={
            dropup
              ? "top-start"
              : "bottom-start"
          }
          className={classNames({
            [classes.popperClose]: !open,
            [classes.pooperResponsive]: true
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleCloseAway}>
                  <MenuList role="menu" className={classes.menuList}>
                    {dropdownHeader !== undefined ? (
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownHeader}
                      >
                        {dropdownHeader}
                      </MenuItem>
                    ) : null}
                    {dropdownList.map((prop, key) => {
                      if (prop.divider) {
                        return (
                          <Divider
                            key={key}
                            onClick={this.handleClose}
                            className={classes.dropdownDividerItem}
                          />
                        );
                      }
                      return (
                        <MenuItem
                          key={key}
                          onClick={() => this.handleClose(prop.action)}
                          className={dropdownItem}
                        >
                          {prop.title}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}
export default withStyles(customDropdownStyle)(Menu);
