import React from "react";
import "../../Components.css";
import { BaseContext } from "../../baseContext";
import { withRouter } from "react-router-dom";
import Button from "../materialComponents/CustomButtons/Button.jsx";
import Done from "@material-ui/icons/Done";
import Contexts from "../../contexts";
import SuccessIcon from "../../assets/img/success.png";

class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { paidEvent } = this.props;
    const videoId = paidEvent.videos[0];
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div className="card-form__inner" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 60, marginTop: 20 }}>
          <img width={'100px'} src={SuccessIcon}/>
          <h3 style={{ textAlign: "center", marginBottom: 20 }}>    {Contexts.get(Contexts.Context.paymentSuccessful)}</h3>
          <h4 style={{ textAlign: "center", marginBottom: 20 }}> {Contexts.get(Contexts.Context.paymentSuccessfulDetails).replace('title', paidEvent.name)}</h4>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button color="info" onClick={()=>{this.props.history.push("/watchLive?event="+videoId);}}>
              {Contexts.get(Contexts.Context.contunie)}
            </Button>
          </div>
          {this.state.error && (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.state.error}
            </span>
          )}
          {this.state.loading && (
            <div className="SignIn_Header">Lütfen bekleyin</div>
          )}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <PaymentSuccess {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
