import React from "react";
import "../Layouts.css";

export default class TermsAndConditions_v3 extends React.Component {
  render() {
    return (
      <div className="TermsAndConditions">
        <p>
          <strong>KULLANIM SÖZLEŞMESİ</strong>
        </p>
        <strong>1. Taraflar</strong>
        <p>
          a) cerrahi.tv &amp; surgical.tv internet sitesinin faaliyetlerini
          yürüten TIDART OÜ (Bundan böyle
          <strong>“Şirket</strong>” olarak anılacaktır).
        </p>
        <p>
          b) cerrahi.tv &amp; surgical.tv internet sitesine giriş yapan kısaca
          “Kullanıcı “olarak, üye olan ise “Üye” olarak adlandırılacaktır. (“
          <strong>Kullanıcı/ Üye</strong>”)
        </p>
        <strong>2. Sözleşmenin Konusu</strong>
        <p>
          İşbu Sözleşme’nin konusu Şirket’in sahip olduğu internet sitesi
          cerrahi.tv &amp; surgical.tv’den kullanıcı / Üyenin faydalanma
          şartlarının belirlenmesidir.
        </p>
        <strong>3. Tarafların Hak ve Yükümlülükleri</strong>
        <p>
          3.1. Kullanıcı, cerrahi.tv &amp; surgical.tv internet sitesine üye
          olurken ve/veya cerrahi.tv &amp; surgical.tv internet sitesini
          kullanırken verdiği kişisel ve diğer sair bilgilerin kanunlar önünde
          doğru olduğunu, Şirket’in bu bilgilerin gerçeğe aykırılığı nedeniyle
          uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini beyan ve
          taahhüt eder. Kullanıcının / Üye olmak isteyen kullanıcının 18 (on
          sekiz) yaşını doldurmuş olması ve sağlık profesyoneli olması
          gerekmektedir.
        </p>
        <p>
          3.2. Üye/ Kullanıcı, cerrahi.tv &amp; surgical.tv internet sitesine
          giriş için oluşturmuş olduğu şifreyi ve varsa cerrahi.tv &amp;
          surgical.tv internet sitesi tarafından kendisine verilmiş olan şifreyi
          başka kişi ya da kurum ya da kuruluşlara veremez, söz konusu şifreyi
          kullanma hakkı bizzat kendisine aittir. Bu sebeple doğabilecek tüm
          sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından ileri
          sürülebilecek tüm iddia ve taleplere karşı tek muhatap üyedir.
          Şirket’in söz konusu izinsiz kullanımdan kaynaklanan her türlü
          tazminat ve sair talep hakkı saklıdır.
        </p>
        <p>
          3.3. Üye/ Kullanıcı cerrahi.tv &amp; surgical.tv internet sitesini
          kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal
          etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm
          hukuki ve cezai yükümlülükler tamamen ve münhasıran üyeyi
          bağlayacaktır.
        </p>
        <p>
          3.4. Üye/ Kullanıcı, cerrahi.tv &amp; surgical.tv internet sitesini
          hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını
          rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için,
          başkalarının fikri ve telif haklarına tecavüz edecek şekilde
          kullanamaz. Ayrıca, üye/ kullanıcı başkalarının hizmetleri
          kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva
          atı, vb.) ve işlemlerde bulunamaz.
        </p>
        <p>
          3.5. cerrahi.tv &amp; surgical.tv internet sitesinde kullanıcı /
          üyeler tarafından beyan edilen, yazılan, kullanılan fikir ve
          düşünceler, tamamen üyelerin kendi kişisel görüşleridir ve görüş
          sahibini bağlar. Bu görüş ve düşüncelerin Şirket ile hiçbir ilgi ve
          bağlantısı yoktur. Şirket’in, üyenin beyan edeceği fikir ve görüşler
          nedeniyle üçüncü kişilerin uğrayabileceği zararlardan ve üçüncü
          kişilerin beyan edeceği fikir ve görüşler nedeniyle üyenin/
          kullanıcının uğrayabileceği zararlardan dolayı herhangi bir
          sorumluluğu bulunmamaktadır.
        </p>
        <p>
          Bununla birlikte, cerrahi.tv &amp; surgical.tv internet sitesinde
          sponsorlu reklamlı içerik ve yayınlar ve/veya cerrahi.tv &amp;
          surgical.tv internet sitesinde banner reklamlar yer alabilecek olup,
          bu reklam ve tanıtımlarda yer alan bilgi, belge ve içerikler ile
          ilgili tek yetkili ve sorumlu ilgili reklam firması olup, firmaların
          cerrahi.tv &amp; surgical.tv internet sitesi üzerinden yapmış olduğu
          tanıtımlar, reklamlar nedeni ile kullanıcıların, üyelerin ve üçüncü
          kişilerin uğrayabileceği her türlü zararlardan Şirket’in herhangi bir
          sorumluluğu bulunmamaktadır.
        </p>
        <p>
          3.6. Şirket, kullanıcı/üye verilerinin yetkisiz kişilerce okunmasından
          ve üye yazılım ve verilerine gelebilecek zararlardan dolayı sorumlu
          olmayacaktır. Kullanıcı/ Üye, cerrahi.tv &amp; surgical.tv internet
          sitesinin kullanılmasından dolayı uğrayabileceği herhangi bir zarar
          yüzünden Şirket’den tazminat talep etmemeyi peşinen kabul etmiştir.
        </p>
        <p>
          3.7.Kullanıcı/ Üye, diğer internet kullanıcılarının yazılımlarına ve
          verilerine izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul
          etmiştir. Aksi takdirde, bundan doğacak hukuki ve cezai sorumluluklar
          tamamen üyeye aittir.
        </p>
        <p>
          3.8. İşbu sözleşme içerisinde sayılan maddelerden bir ya da birkaçını
          ihlal eden kullanıcı /üye işbu ihlal nedeniyle cezai ve hukuki olarak
          şahsen sorumlu olup, Şirket’i bu ihlallerin hukuki ve cezai
          sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın
          hukuk alanına intikal ettirilmesi halinde, Şirket’in kullanıcıya/
          üyeye karşı üyelik sözleşmesine ve Kullanım sözleşmesine
          uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
        </p>
        <p>
          3.9. Şirket’in her zaman tek taraflı olarak gerektiğinde, kullanıcının
          siteye girişini engelleme, üyenin üyeliğini silme, üyeye ait dosya,
          belge ve bilgileri silme hakkı vardır. Kullanıcı/Üye işbu tasarrufu
          önceden kabul eder. Bu durumda, Şirket’in hiçbir sorumluluğu yoktur.
        </p>
        <p>
          3.10. cerrahi.tv &amp; surgical.tv internet sitesi yazılım ve tasarımı
          Şirket’in mülkiyetinde olup, bunlara ilişkin telif hakkı ve/veya diğer
          fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar
          Kullanıcı/ üye tarafından izinsiz kullanılamaz, iktisap edilemez ve
          değiştirilemez. Bu web sitesinde adı geçen başkaca şirketler ve
          ürünleri, eserleri sahiplerinin ticari markalarıdır ve ayrıca fikri
          mülkiyet hakları kapsamında korunmaktadır. Kullanıcı/Üye, Şirket’in
          veya üçüncü kişilerin mülkiyetine tabi fikri mülkiyet haklarını yazılı
          izni olmaksızın kullanamaz, paylaşamaz, dağıtamaz, sergileyemez,
          çoğaltamaz. Kullanıcı/Üye'nin, üçüncü kişilerin veya Şirket’in fikri
          mülkiyet haklarını ihlal edecek şekilde davranması halinde,
          Kullanıcı/Üye, Şirket’in ve/veya söz konusu üçüncü kişinin tüm
          doğrudan ve dolaylı zararları ile masraflarını tazmin etmekle
          yükümlüdür.
        </p>
        <p>
          3.11. Şirket tarafından cerrahi.tv &amp; surgical.tv internet
          sitesinin iyileştirilmesi, geliştirilmesine yönelik olarak ve/veya
          yasal mevzuat çerçevesinde siteye erişmek için kullanılan İnternet
          servis sağlayıcısının adı ve Internet Protokol (IP) adresi, Siteye
          erişilen tarih ve saat, sitede bulunulan sırada erişilen sayfalar ve
          siteye doğrudan bağlanılmasını sağlayan Web sitesinin Internet adresi
          gibi birtakım bilgiler toplanabilir.
        </p>
        <p>
          &nbsp;3.12. Şirket, Kullanıcı/üyenin kişisel bilgilerini yasal bir
          zorunluluk olarak istendiğinde veya (a) yasal gereklere uygun hareket
          etmek veya Şirket’e tebliğ edilen yasal işlemlere uymak; (b) Şirket ve
          cerrahi.tv &amp; surgical.tv web sitesi ailesinin haklarını ve
          mülkiyetini korumak ve savunmak için gerekli olduğuna iyi niyetle
          kanaat getirdiği hallerde açıklayabilir.
        </p>
        <p>
          3.13. cerrahi.tv &amp; surgical.tv web sitesinin virus ve benzeri
          amaçlı yazılımlardan arındırılmış olması için mevcut imkanlar
          dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması
          için üyenin, kendi virus koruma sistemini tedarik etmesi ve gerekli
          korunmayı sağlaması gerekmektedir. Bu bağlamda üye cerrahi.tv &amp;
          surgical.tv web sitesi’ne girmesiyle, kendi yazılım ve işletim
          sistemlerinde oluşabilecek tüm hata ve bunların doğrudan yada dolaylı
          sonuçlarından kendisinin sorumlu olduğunu kabul etmiş sayılır.
        </p>
        <p>
          3.14. Şirket, sitenin içeriğini dilediği zaman değiştirme,
          kullanıcılara/ üyelere sağlanan herhangi bir hizmeti değiştirme yada
          sona erdirme veya cerrahi.tv &amp; surgical.tv web sitesi’nde kayıtlı
          kullanıcı / üye bilgi ve verilerini silme hakkını saklı tutar.
        </p>
        <p>
          3.15. Şirket, kullanım koşullarını hiçbir şekil ve surette ön ihbara
          ve/veya ihtara gerek kalmaksızın her zaman değiştirebilir,
          güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen yada
          yürürlükten kaldırılan her hüküm, yayın tarihinde tüm üyeler/
          kullanıcılar bakımından hüküm ifade edecektir.
        </p>
        <p>
          3.16. Taraflar, Şirket’e ait tüm bilgisayar ve ticari kayıtlarının tek
          ve gerçek münhasır delil olarak, HUMK madde 287′ye uygun şekilde esas
          alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil
          ettiği hususunu kabul ve beyan eder.
        </p>
        <p>
          3.17.Şirket, iş bu sözleşme uyarınca, kullanıcı / üyelerinin
          kendisinde kayıtlı elektronik posta adreslerine bilgilendirme mailleri
          ve cep telefonlarına bilgilendirme SMS’leri gönderme yetkisine sahip
          olmakla beraber, üye işbu üyelik sözleşmesini onaylamasıyla beraber
          bilgilendirme maillerinin elektronik posta adresine ve bilgilendirme
          SMS’lerinin cep telefonuna gönderilmesini kabul etmiş sayılacaktır.
        </p>
        <strong>4. Sözleşmenin Feshi</strong>
        <p>
          İşbu sözleşme üyenin üyeliğini iptal etmesi veya Şirket tarafından
          üyeliğinin iptal edilmesine, Şirket tarafından kullanıcının cerrahi.tv
          &amp; surgical.tv web sitesi giriş yapmasının engellenmesine kadar
          yürürlükte kalacaktır. Şirket, Kullanıcı / üyenin sözleşmenin herhangi
          bir hükmünü ihlal etmesi durumunda Kullanıcı /üyenin üyeliğini iptal
          ederek, internet sitesine girişini engelleyerek sözleşmeyi tek taraflı
          olarak feshedebilecektir. Şirket’in tazminat ve yasal talep hakları
          saklıdır.
        </p>
        <strong>5. GİZLİLİK</strong>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          5.1. Şirket, kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması
          Kanunu da dahil her türlü mevzuata uygun bir şekilde işlenmesine,
          güvenliğinin sağlanmasına ve korunmasına önem vermektedir. Şirket, bu
          kapsamda Kullanıcı/Üye'nin sağladığı kişisel veriler&nbsp; yer alan
          Gizlilik Politikası'na uygun olarak toplamakta, kullanmakta,
          aktarmakta ve diğer şekillerde işleyebilmektedir. Gizlilik Politikası,
          işbu Sözleşme'nin ayrılmaz bir parçasıdır.
        </p>
        <p>&nbsp;</p>
        <p>
          5.2. Üye /Kullanıcı, hizmetler'den faydalanarak ve/veya bir hesap
          oluşturarak Gizlilik Politikası'nda belirlendiği şekilde kişisel
          verilerinin toplanmasına, kullanılmasına, aktarılmasına ve diğer
          şekillerde işlenmesine açık bir şekilde ve özgür iradesiyle rıza
          göstermektedir.
        </p>
        <p>&nbsp;</p>
        <p>
          5.3.&nbsp;Üye/Kullanıcı tarafından beyan edilen ve paylaşılmasına rıza
          gösterilen kişisel veriler; işbu Üyelik Sözleşmesi ile belirlenen
          yükümlülüklerin ifası, web sitesinin işletilmesi için gereken
          uygulamaların yürütülmesi, Üye için çeşitli avantajların sağlanıp
          sunulabilmesi ve Üye’ye özel reklam, satış, pazarlama, anket, benzer
          amaçlı her türlü elektronik iletişim, profilleme, istatistiksel
          çalışmalar yapılması amacıyla Şirket ya da iş ortakları tarafından
          Gizlilik Politikası'na uygun olarak toplanır, saklanır, işlenir,
          kullanılır ve akdi ilişkilerimize istinaden 3. Kişiler ile
          paylaşılabilir.
        </p>
        <p>&nbsp;</p>
        <p>
          5.4. Beşeri Tıbbi Ürünlerin Tanıtım Faaliyetleri Hakkındaki Yönetmelik
          kapsamında; Üye/Kullanıcı tarafından beyan edilen ve paylaşılmasına
          rıza gösterilen kişisel veriler, sitede bulunulan sırada erişilen
          sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web sitesinin
          Internet adresi gibi birtakım bilgiler; Türkiye İlaç ve Tıbbi Cihaz
          Kurumu’na değer aktarımının bildirimi, toplantı bildirimlerinin
          yapılması amacıyla Türkiye İlaç ve Tıbbi Cihaz Kurumu ve/veya 3.
          Kişiler ile paylaşılabilir.
        </p>
        <p>&nbsp;</p>
        <p>
          5.5. Kullanıcı /Üye, kişisel verilerinin bilgilerinin Şirket
          tarafından bu şekilde kullanımına ve saklanmasına rıza gösterdiğini
          kabul ve beyan eder. Şirket, söz konusu kişisel verilerin 6698 Sayılı
          Kişisel Verilerin Korunması Kanunu mad. 12 uyarınca güvenli şekilde
          saklanması, yetkisiz erişimlerin ve hukuka aykırı veri işlemelerin
          önlenmesi için gereken her türlü tedbiri alacaktır. Üye kişisel
          verileri üzerinde 6698 Sayılı Kişisel Verilerin Korunması Kanunu mad.
          11 uyarınca sahip olduğu hakları kullanma ve bu verilerinde dilediği
          zaman değişiklik veya güncelleme yapma hakkına sahiptir.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>6. Uyuşmazlıkların Çözümü</strong>
        </p>
        <p>
          İşbu sözleşmeye ilişkin ihtilaflarda İstanbul (Çağlayan) Mahkemeleri
          ve İcra Daireleri yetkilidir.
        </p>
        <strong>7.Tadil ve Feragat</strong>
        <p>
          Taraflar'dan birinin Sözleşme'de kendisine verilen herhangi bir hakkı
          kullanmaması ya da icra etmemesi, söz konusu haktan feragat ettiği
          anlamına gelmeyecek veya söz konusu hakkın daha sonra kullanılmasını
          ya da icra edilmesini engellemeyecektir.
        </p>
        <p>
          <strong>8. Sözleşmesi'nin Devri</strong>
        </p>
        <p>
          <em>Kullanıcı/</em>Üye, Şirket’in önceden yazılı onayını almaksızın
          işbu Sözleşme’deki haklarını veya yükümlülüklerini tümüyle veya kısmen
          devir, temlik edemeyecektir<em>.</em>
        </p>
        <p>
          <strong>9. Mücbir Sebep</strong>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          Ayaklanma, ambargo, devlet müdahalesi, isyan, işgal, savaş,
          seferberlik, grev, lokavt, iş eylemleri veya boykotlar dahil olmak
          üzere siber saldırı, iletişim sorunları, altyapı ve internet
          arızaları, sisteme ilişkin iyileştirme veya yenileştirme çalışmaları
          ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi,
          yangın, patlama, fırtına, sel, deprem, göç, salgın veya diğer bir
          doğal felaket veya Şirket’in kontrolü dışında gerçekleşen, kusurundan
          kaynaklanmayan ve makul olarak öngörülemeyecek diğer olaylar ("Mücbir
          Sebep") Şirket’in işbu Sözleşme'den doğan yükümlülüklerini ifa
          etmesini engeller veya geciktirirse, Şirket, ifası Mücbir Sebep
          sonucunda engellenen veya geciken yükümlülüklerinden dolayı sorumlu
          tutulamaz ve bu durum işbu Sözleşme’nin bir ihlali olarak kabul
          edilemez.
        </p>
        <strong>10. Sözleşmesi'nin Bütünlüğü ve Bölünebilirliği;&nbsp;</strong>
        <p>
          İşbu Sözleşme, konuya ilişkin olarak Taraflar arasındaki anlaşmanın
          tamamını oluşturmaktadır. İşbu Sözleşme’nin herhangi bir hükmünün
          yetkili herhangi bir mahkeme, tahkim heyeti veya idari makam
          tarafından tümüyle veya kısmen geçersiz veya uygulanamaz olduğu veya
          makul olmadığına karar verilmesi halinde, söz konusu geçersizlik,
          uygulanamazlık veya makul olmama ölçüsünde işbu Sözleşme bölünebilir
          olarak kabul edilecek ve diğer hükümler tümüyle yürürlükte kalmaya
          devam edecektir.
        </p>
        <strong>11. Yürürlük</strong>
        <p>
          11 (onbir) maddeden ibaret işbu Sözleşme, Üye /Kullanıcı tarafından
          her bir hükmü okunarak ve bütünüyle anlaşılarak elektronik ortamda
          onaylanmak suretiyle, onaylandığı an itibariyle yürürlüğe girmiştir
          <strong>.</strong>
        </p>
      </div>
    );
  }
}
