import React from "react";
import "../../Components.css";
import { BaseContext } from "../../baseContext";
import { withRouter } from "react-router-dom";
import AddAlert from "@material-ui/icons/Error";
import Button from "../materialComponents/CustomButtons/Button.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "../materialComponents/Snackbar/Snackbar.jsx";
import TextField from "@material-ui/core/TextField";
import Contexts from "../../contexts";
import { activeUser } from "../../api";
import Invoice from "../../assets/img/invoice.png";
import { Countries } from "../../consts";

class BillingInfo extends React.Component {
  constructor(props) {
    super(props);

    const user = activeUser();

    const { name, address1, city, state, postCode, country, taxCode } =
      props.address || {};

    this.state = {
      loading: false,
      error: null,
      name: name || user ? user.displayName : "",
      address1: address1 || "",
      city: city || "",
      state: state || "",
      postCode: postCode || "",
      country: country || "TR",
      taxCode: taxCode || "",
      address1_Error: "",
    };
  }

  validateForm() {
    const newState = { ...this.state };
    const { name, address1, city, state, postCode, country } = newState;

    newState.name_Error = "";
    newState.address1_Error = "";
    newState.city_Error = "";
    newState.state_Error = "";
    newState.postCode_Error = "";
    newState.country_Error = "";
    newState.isValid = true;

    if (name.length < 3) {
      newState.name_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }
    if (address1.length < 3) {
      newState.address1_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }
    if (city.length < 3) {
      newState.city_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }
    if (state.length < 3) {
      newState.state_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }
    if (postCode.length < 3) {
      newState.postCode_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }
    if (country.length === 0) {
      newState.country_Error = Contexts.get(Contexts.Context.requiredFields);
      newState.isValid = false;
    }

    this.setState(newState);
    return newState.isValid;
  }
  handleSave() {
    const isValid = this.validateForm();
    const {
      name,
      address1,
      city,
      state,
      postCode,
      country,
      taxCode,
    } = this.state;
    const { id } = this.props;
    const _country = Countries.find((c) => c.code === country) || {};

    if (isValid) {
      const address = {
        id,
        name,
        address1,
        city,
        state,
        postCode,
        country,
        taxCode: taxCode && taxCode.length > 0 ? taxCode : null,
        vat: _country.vat || 0,
      };
      this.props.onContinue(address);
    }
  }

  handleBack() {
    const {
      name,
      address1,
      city,
      state,
      postCode,
      country,
      taxCode,
    } = this.state;
    const { id } = this.props;
    const address = {
      id,
      name,
      address1,
      city,
      state,
      postCode,
      country,
      taxCode,
    };
    this.props.onBack(address);
  }

  render() {
    const {
      name,
      address1,
      city,
      state,
      postCode,
      country,
      taxCode,
      name_Error,
      address1_Error,
      city_Error,
      state_Error,
      postCode_Error,
      country_Error,
    } = this.state;

    const _country = Countries.find((c) => c.code === country) || {};

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <div className={"card-item"}>
          <div className="card-item__side -front">
            <div style={{ backgroundColor: "#f6f6f7" }}>
              <img alt="" src={Invoice} className="card-item__bg" />
            </div>
          </div>
        </div>
        <div className="card-form__inner" style={{ paddingTop: 120 }}>
          <h3 style={{ textAlign: "center", marginBottom: 20 }}>
          {Contexts.get(Contexts.Context.billingInfo)}
          </h3>
          <div className="card-input">
            <label className="card-input__label" htmlFor="name">
              {Contexts.get(Contexts.Context.fullName)}
            </label>
            <input
              id="name"
              className="StripeElement"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
            <div className="input-error">
              {name_Error && name_Error.length > 0 && name_Error}
            </div>
          </div>
          <div className="card-input">
            <label className="card-input__label" htmlFor="address1">
              {Contexts.get(Contexts.Context.address1)}
            </label>
            <input
              id="address1"
              className="StripeElement"
              value={address1}
              onChange={(e) => this.setState({ address1: e.target.value })}
            />
            <div className="input-error">
              {address1_Error && address1_Error.length > 0 && address1_Error}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <div className="card-input">
                <label className="card-input__label" htmlFor="country">
                  {Contexts.get(Contexts.Context.country)}
                </label>
                <select
                  id="country"
                  style={{ paddingTop: 12, paddingRight: 10 }}
                  className="StripeElement"
                  value={country}
                  onChange={(e) => {
                    this.setState({ country: e.target.value });
                  }}
                >
                  {Countries.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className="input-error">
                  {country_Error && country_Error.length > 0 && country_Error}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <div className="card-input">
                <label className="card-input__label" htmlFor="city">
                  {Contexts.get(Contexts.Context.city)}
                </label>
                <input
                  id="city"
                  className="StripeElement"
                  value={city}
                  onChange={(e) => this.setState({ city: e.target.value })}
                />
                <div className="input-error">
                  {city_Error && city_Error.length > 0 && city_Error}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ width: "50%", display: "flex", flexDirection: "column" }}
            >
              <div className="card-input">
                <label className="card-input__label" htmlFor="state">
                  {Contexts.get(Contexts.Context.state)}
                </label>
                <input
                  id="state"
                  className="StripeElement"
                  value={state}
                  onChange={(e) => this.setState({ state: e.target.value })}
                />
                <div className="input-error">
                  {state_Error && state_Error.length > 0 && state_Error}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <div className="card-input">
                <label className="card-input__label" htmlFor="postCode">
                  {Contexts.get(Contexts.Context.postCode)}
                </label>
                <input
                  id="postCode"
                  className="StripeElement"
                  value={postCode}
                  onChange={(e) => this.setState({ postCode: e.target.value })}
                />
                <div className="input-error">
                  {postCode_Error &&
                    postCode_Error.length > 0 &&
                    postCode_Error}
                </div>
              </div>
            </div>
          </div>
          {_country && _country.vat && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginBottom: 20
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="card-input">
                  <label className="card-input__label" htmlFor="taxCode">
                    {Contexts.get(Contexts.Context.taxCode)}
                  </label>
                  <input
                    id="taxCode"
                    className="StripeElement"
                    value={taxCode}
                    onChange={(e) => this.setState({ taxCode: e.target.value })}
                  />
                  {/* <div className="input-error">
                  {state_Error && state_Error.length > 0 && state_Error}
                </div> */}
                </div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button color="default" onClick={() => this.handleBack()}>
              {Contexts.get(Contexts.Context.backArrow)}
            </Button>
            <Button onClick={() => this.handleSave()} color="info">
              {Contexts.get(Contexts.Context.contunie)}
            </Button>
          </div>
          {this.state.error && (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.state.error}
            </span>
          )}
          {this.state.loading && (
            <div className="SignIn_Header">Lütfen bekleyin</div>
          )}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <BillingInfo {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
