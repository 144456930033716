import React from "react";
import Contexts from "../contexts";
import "../Layouts.css";
import { BaseContext } from "../baseContext";
import AlbumList from "../components/AlbumList";

import { activeUser, isUserEmailValidated, getUserVideoWatches } from "../api";
import { withRouter } from "react-router-dom";

class WatchedVideoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      userWatchedVideos: [],
    };
  }
  componentDidMount() {
    getUserVideoWatches().then((videos) => {
      this.setState({ userWatchedVideos: videos });
    });
  }

  handleTumbnailClick(id) {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired("/watchLive?event=" + id);
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      this.props.baseContext.baseContext.data.currentVideoId = id;
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
      this.props.baseContext.setShowLoginRequired(
        this.props.location.pathname + this.props.location.search
      );
      this.props.history.push("/watchLive?event=" + id);
    }
  }

  renderBlank() {
    return (
      <div className="Main">
        <div className="FlexColumn">
          {"Lütfen bekleyin..."}
          <div style={{ width: "100%" }}>
            <AlbumList type={1} key={1} blank />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props.baseContext.baseContext;
    const { userWatchedVideos } = this.state;
    const album = {
      id: -1,
      name:Contexts.get(Contexts.Context.myHistory),
    };
    let videos = [];

    if (data.videos && userWatchedVideos) {
      Object.keys(userWatchedVideos).forEach((key) => {
        if (data.videos[key]) {
          const video = data.videos[key];
          if (userWatchedVideos[key].lastUpdate || userWatchedVideos[key].start) {
            video.release_time = userWatchedVideos[key].lastUpdate || userWatchedVideos[key].start;
            video.release_time = video.release_time * 1000;
            video.forceReleaseDate = true;
          }
          videos.push(video);
        }
      });
      videos = videos.sort((a,b)=> b.release_time - a.release_time);
    }
    if (!videos) {
      return this.renderBlank();
    }
    return (
      <div className="Main">
        <div className="FlexColumn">
          {videos && videos.length > 0 && (
            <AlbumList
              key={1}
              type={1}
              album={album}
              videos={videos}
              sorted={true}
              handleTumbnailClick={(id) => this.handleTumbnailClick(id)}
            />
          )}
          {videos && videos.length === 0 && <span>Hiç sonuç bulunamadı.</span>}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => (
      <WatchedVideoList {...props} baseContext={context} ref={ref} />
    )}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
