import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Table from "components/materialComponents/Table/Table.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import Button from "@material-ui/core/Button";
import forEach from "lodash/forEach";
import { getUsers } from "../api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null
    };
  }

  componentDidMount() {
    getUsers().then(users => {
      this.setState({ users: users });
    });
  }
  handleExport() {
    const header = "id;name;email;nationality;register;validationSent;user_aggrement;provider";
    const users = [];
    forEach(this.state.users, (user, i) => {
      if(user.id) {
        let userLine = user.id;
        userLine += ";";
        userLine += user.name;
        userLine += ";";
        userLine += user.email;
        userLine += ";";
        userLine += user.nationality;
        userLine += ";";
        userLine += user.registerDate;
        userLine += ";";
        userLine += user.validationMailSent;
        userLine += ";";
        userLine += user.user_aggrement;
        userLine += ";";
        userLine += user.provider;
        userLine += ";";
        users.push(userLine);
      }
    });
    const csv = [header, users.join("\n")].join("\n");

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "cerrahitv_users.csv";
    hiddenElement.click();
  }
  render() {
    const { classes, isMobile } = this.props;
    let columns = [
      { name: "id", type: "string", title: "ID" },
      { name: "name", type: "string", title: "Name" },
      { name: "email", type: "string", title: "Email" },
      { name: "registerDate", type: "string", title: "Register Date" },
      { name: "provider", type: "string", title: "Provider" }
    ];
    if (isMobile) {
      columns = [
        { name: "name", type: "string", title: "Name" },
        { name: "email", type: "string", title: "Email" },
        { name: "registerDate", type: "string", title: "Register Date" },
        { name: "provider", type: "string", title: "Provider" }
      ];
    }
    const data = [];
    if (this.state.users) {
      forEach(this.state.users, (user, i) => {
        if(user.id)
          data.push(user);
      });
    }
    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" style={{display: 'flex',     justifyContent: 'space-between'}}>
                <h4 className={classes.cardTitleWhite}>Users</h4>
                <Button
                  color="primary"
                  style={{ width: 120 }}
                  onClick={() => this.handleExport()}
                >
                  Export
                </Button>
              </CardHeader>
              <CardBody>
                {this.state.users && (
                  <Table
                    tableHeaderColor="info"
                    columns={columns}
                    tableData={data}
                  />
                )}
                {!this.state.users && <span>Loading...</span>}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Users);
