import React, { Component } from "react";
import { BaseContext, defaultBase } from "./baseContext";
import LoginRequiredDialog from "./components/LoginRequiredDialog";
import PaymentRequiredDialog from "./components/PaymentRequiredDialog";
import SessionIsOpenDialog from "./components/SessionIsOpenDialog";
import { Helmet } from "react-helmet";
import { toArray } from "lodash";
import NotificationDialog from "./components/NotificationDialog";
import UserTagsDialog from "./components/UserTagsDialog";
import Contexts from "./contexts";
import "./App.css";
import "./index.css";
import * as firebase from "firebase";
import {
  paths,
  getAllData,
  getAllUserData,
  logOut,
  init_messaging,
  activeUser,
  setActiveUser,
  getUserAggrementStatus,
  lastUserAgreementVersion,
  isUserEmailValidated,
  setUserAggrementStatusOnCookie,
  setLocalData,
  getLocalData,
  getFirebaseData,
  getLastUpdateDate,
} from "./api";
import queryString from "query-string";
import UserAgreementForm from "./components/UserAgreementForm";
import SpecialitySelectionDialog from "./components/SpecialitySelectionDialog";
import RealtimeMessageDialog from "./components/RealtimeMessageDialog";
import Header from "./components/Header";
import Main from "./layouts/main";
import SearchResult from "./layouts/SearchResult";
import WatchedVideoList from "./layouts/WatchedVideoList";
import Landing from "./layouts/Landing";
import WatchLiveVideo from "./layouts/watchLiveVideo";
import ManageLiveVideo from "./layouts/manageLiveVideo";
import VideoUpload from "./layouts/VideoUpload";
import UploadedVideos from "./layouts/UploadedVideos";
import SignIn from "./layouts/signIn";
import ValidateEmail from "./layouts/valıdateEmail";
import Unsubscribe from "./layouts/unsubscribe";
import AcceptPayment from "./layouts/AcceptPayment";
import TermsAndConditions_v3 from "./layouts/TermsAndConditions_v3";
import Users from "./layouts/users";
import WaitingApproveMessages from "./layouts/WaitingApproveMessages";
import Tags from "./layouts/Tags";
import Videos from "./layouts/Videos";
import Albums from "./layouts/Albums";
import MyPurchases from "./layouts/MyPurchases";
import Switch from "@material-ui/core/Switch";
import AddAlert from "@material-ui/icons/Error";
import Snackbar from "./components/materialComponents/Snackbar/Snackbar.jsx";
import { withRouter } from "react-router-dom";
import BANNER_DESKTOP from "./assets/img/banner_desktop.png";
import BANNER_MOBILE from "./assets/img/banner_mobile.png";

const config = {
  apiKey: "AIzaSyA1l52SE5kbG5TGWH5myWcq1GBwccN5kZU",
  authDomain: "cerrahitv-1a6dd.firebaseapp.com",
  databaseURL: "https://cerrahitv-1a6dd.firebaseio.com",
  projectId: "cerrahitv-1a6dd",
  storageBucket: "cerrahitv-1a6dd.appspot.com",
  messagingSenderId: "499833986163",
};
firebase.initializeApp(config);

firebase.auth().onIdTokenChanged(function (user) {
  if (user) {
    setActiveUser(user);
    init_messaging();
  } else {
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.setBase = (baseContext) => {
      this.state.baseContext = baseContext;
      this.setState(this.state);
    };
    this.setShowLoginRequired = (path) => {
      this.state.showLoginRequired = true;
      this.state.baseContext.requestedPath = path;
      this.setState(this.state);
    };
    this.setShowPaymentRequired = (paidEvent) => {
      this.state.showPaymentRequired = true;
      this.state.baseContext.paidEvent = paidEvent;
      this.setState(this.state);
    };
    this.setShowSessionIsOpen = (paidEvent, eventId) => {
      this.state.showSessionIsOpen = true;
      this.state.baseContext.paidEvent = paidEvent;
      this.state.baseContext.eventId = eventId;
      this.setState(this.state);
    };
    this.setShowRealtimeMessageDialog = (message, actionUrl, videoId) => {
      this.state.showRealtimeMessageDialog = true;
      this.state.baseContext.message = message;
      this.state.baseContext.actionUrl = actionUrl;
      this.state.baseContext.videoId = videoId;
      this.setState(this.state);
    };
    this.setShowMailVerifyRequired = (show) => {
      this.state.showVerifyRequired = show;
      this.setState(this.state);
    };
    this.setShowUserTagsDialog = (show) => {
      this.state.showUserTagsDialog = show;
      this.setState(this.state);
    };
    this.setShowInfoMessage = (message) => {
      this.state.showInfoMessage = true;
      this.setState(this.state);
    };
    this.setUserAgreementStatus = (status) => {
      this.state.baseContext.session.userAgreementDone = status;
      this.setState(this.state);
    };

    this.setUserData = (userData) => {
      this.state.baseContext.data.userData = userData;
      this.setState(this.state);
    };

    this.state = {
      baseContext: defaultBase,
      setBase: this.setBase,
      setShowLoginRequired: this.setShowLoginRequired,
      setShowPaymentRequired: this.setShowPaymentRequired,
      setShowSessionIsOpen: this.setShowSessionIsOpen,
      setShowRealtimeMessageDialog: this.setShowRealtimeMessageDialog,      
      setShowMailVerifyRequired: this.setShowMailVerifyRequired,
      setUserAgreementStatus: this.setUserAgreementStatus,
      setUserData: this.setUserData,
      setShowUserTagsDialog: this.setShowUserTagsDialog,
      showLoginRequired: false,
      showVerifyRequired: false,
      showInfoMessage: false,
      showUserTagsDialog: false,
      showPaymentRequired: false,
      showSessionIsOpen: false,
      showRealtimeMessageDialog: false,
      infoMessage: "",
      width: 0,
      showSearch: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    window.previousLocation = this.props.location;
  }
  componentDidMount() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "a62457a8-9ed5-424d-b636-f95fd74c2650";
    const hostname = window.location.hostname || window.location.host || "";
    if (hostname && hostname.includes("surgical")) {
      this.state.baseContext.data.domain = "surgical.tv";
      document.title = "Surgical Tv";
    } else {
      document.title = "Cerrahi Tv";
    }
    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);

    window.previousLocation = this.props.location;
    const that = this;
    this.state.baseContext.session.userAgreementDone = getUserAggrementStatus();
    try {
      firebase.messaging().onMessage(function (payload) {
        if (payload.notification) {
          that.setState({
            showNotificationDialog: true,
            notificationTitle: payload.notification.title,
            notificationMessage: payload.notification.body,
            notificationItemId: payload.data["gcm.notification.itemId"],
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
    that.data = getLocalData(paths.localData);
    getLastUpdateDate().then((data) => {
      const { config } = data;
      if (
        !that.data ||
        (that.data && that.data.config.lastUpdate < config.lastUpdate)
      ) {
        //this.state.baseContext = defaultBase;
        getAllData().then((data) => {
          setLocalData(paths.localData, data);
          this.state.baseContext.data.textContexts = data.textContexts;
          this.state.baseContext.data.albums = data.albums;
          this.state.baseContext.data.videos = data.videos;
          this.state.baseContext.data.albumVideos = data.albumVideos;
          this.state.baseContext.data.videoAlbums = data.videoAlbums;
          this.state.baseContext.data.config = data.config;
          this.state.baseContext.data.liveEvents = data.liveEvents;
          this.state.baseContext.data.tags = data.tags || {};
          this.state.baseContext.data.videoTags = data.videoTags || {};
          this.state.baseContext.data.tagVideos = data.tagVideos || {};
          this.state.baseContext.data.albumTags = data.albumTags || {};
          this.state.baseContext.data.tagAlbums = data.tagAlbums || {};
          this.state.baseContext.data.professions = data.professions || {};
          this.state.baseContext.data.specializations =
            data.specializations || {};
          this.state.baseContext.data.interestGroups =
            data.interestGroups || {};
          this.state.baseContext.data.interests = data.interests || {};
          this.state.baseContext.data.paidEvents = data.paidEvents || {};

          // this.state.baseContext.data.config.topVideoId= '398311288';
          this.setState(this.state);
        });
      } else if (that.data) {
        Object.keys(that.data).forEach((key) => {
          this.state.baseContext.data[key] = that.data[key];
        });
        this.setState(this.state);
      }
    });

    firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        var user = result.user;
        if (user) {
          setActiveUser(user);
          that.props.history.push("/");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        that.handleUserData(that, user);
      }
    });

    window.addEventListener("resize", this.updateDimensions);
    // if(this.AppRef) {
    //   this.AppRef.scrollTo(0,0);
    // }
  }

  handleUserData(that, user) {
    setActiveUser(user);
    if (
      !that.props.location.pathname.startsWith("/sign") &&
      that.props.location.pathname !== "/validateEmail" &&
      user &&
      !user.emailVerified &&
      user.providerData[0].providerId === "password"
    ) {
      that.setShowMailVerifyRequired(true);
      setInterval(function () {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.reload();

          if (firebase.auth().currentUser.emailVerified) {
            that.setShowMailVerifyRequired(false);
          }
      }
      }, 1000);
    }

    getAllUserData().then((data) => {
      that.state.baseContext.data.userFollows = data.userFollows || {};
      that.state.baseContext.data.userData = data.userData || {};
      if (data.userData) {
        if (data.userData.user_aggrement === lastUserAgreementVersion) {
          setUserAggrementStatusOnCookie();
          that.state.baseContext.session.userAgreementDone = true;
        }
      }
      that.setState(that.state);
    });
  }
  handleTumbnailClick(id) {
    const { baseContext } = this.state;
    if (activeUser() == null) {
      this.state.setShowLoginRequired("/watchLive?  vent=" + id);
    } else if (!isUserEmailValidated()) {
      this.state.setShowMailVerifyRequired(true);
    } else {
      baseContext.data.currentVideoId = id;
      this.props.history.push("/watchLive?event=" + id);
    }
  }
  handleSearch(search, type, id) {
    if (!type) this.props.history.push("/searchResult?search=" + search);
    else if (id && type === "video") this.handleTumbnailClick(id);
    else
      this.props.history.push(
        "/searchResult?search=" + search + "&type=" + type
      );
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  handleMenuAction(action) {
    if (action === "signOut") {
      logOut().then(() => {
        this.props.history.push("/");
      });
    } else if (action === "resetPassword") {
      // logOut();
      // this.props.history.push('/signIn');
    } else {
      this.props.history.push(action);
    }
  }
  renderHeader() {
    let isAdmin = false;
    const currentUser = activeUser();
    if (
      currentUser &&
      this.state.baseContext.data.admins.indexOf(currentUser.uid) > -1
    ) {
      isAdmin = true;
    }
    const isMobile = this.state.width < 720;

    return (
      <Header
        user={currentUser}
        isMobile={isMobile}
        isAdmin={isAdmin}
        history={this.props.history}
        signUp={this.props.signUp}
        onSearch={(search, type, id) => {
          this.handleSearch(search, type, id);
        }}
      />
    );
  }

  getBanner(isMobile) {
    return null;
    const {mainPage, history} = this.props;
    const url = "https://www.conmed.com/en/products/laparoscopic-robotic-and-open-surgery/access/airseal-system/airseal-ifs-intelligent-flow-system";
    const queryValues = queryString.parse(history.location.search);
    let videoId = queryValues.event;
    
    if (mainPage || videoId === "588739712") {
      if (!isMobile) {
        return (<a href={url} target="_blank" rel="noopener noreferrer"><img style={{maxWidth: 170}} src={BANNER_DESKTOP}/></a>);
      } else {
        return (<a href={url} target="_blank" rel="noopener noreferrer"><img style={{width: '100vw', marginTop: mainPage ? '70px' : '0px'}} src={BANNER_MOBILE}/></a>);
      }
    }
    return null;
  }
  renderContent() {
    let isAdmin = false;
    const currentUser = activeUser();
    if (
      currentUser &&
      this.state.baseContext.data.admins.indexOf(currentUser.uid) > -1
    ) {
      isAdmin = true;
    }
    const isMobile = this.state.width < 720;
    const sm = this.state.width < 1440;
    if (this.props.termsAndConditions) {
      return <TermsAndConditions_v3 />;
    }
    if (this.props.validateEmail) {
      return <ValidateEmail isMobile={isMobile} />;
    } else if (this.props.unsubscribe) {
      return <Unsubscribe isMobile={isMobile} />;
    } else if (this.props.acceptPayment) {
      return <AcceptPayment isMobile={isMobile} />;
    }
    const baseProps = {
      isAdmin,
      isMobile,
    };
    const banner = this.getBanner(isMobile);
    return (
      <div className="Content" style={{flexDirection: isMobile ? 'column' : 'row'}}>
        {isAdmin && (
          <div className={"admin-button"}>
            Admin Mode
            <Switch
              checked={this.state.baseContext.session.admin}
              onChange={(e) => {
                this.state.baseContext.session.admin = !this.state.baseContext
                  .session.admin;
                this.setState({ baseContext: this.state.baseContext });
              }}
              value={this.state.baseContext.session.admin}
              color="primary"
            />
          </div>
        )}
        {!isMobile && (!sm || banner) && <div className="Left" style={{maxWidth: banner ? '190px' : ''}}> {banner}</div>}
        {isMobile && banner}
        {!this.state.baseContext.session.userAgreementDone &&
          this.renderContentRestriction()}
        {this.renderSpecialitySelectionDialog()}
        {this.props.landing && <Landing {...baseProps} />}
        {this.props.searchResult && <SearchResult {...baseProps} />}
        {this.props.myHistory && <WatchedVideoList {...baseProps} />}
        {this.props.mainPage && <Main {...baseProps} />}
        {this.props.watchLiveVideo && this.state.baseContext.data.videos && (
          <WatchLiveVideo {...baseProps} hasBanner={banner} />
        )}
        {isAdmin && this.props.manageLive && <ManageLiveVideo {...baseProps} />}
        {this.props.videoUpload && <VideoUpload />}
        {this.props.signIn && <SignIn {...baseProps} />}
        {this.props.signUp && <SignIn signUp {...baseProps} />}
        {isAdmin && this.props.users && <Users {...baseProps} />}
        {isAdmin && this.props.newMessages && (
          <WaitingApproveMessages {...baseProps} />
        )}
        {isAdmin && this.props.tags && <Tags {...baseProps} />}
        {isAdmin && this.props.videos && <Videos {...baseProps} />}
        {isAdmin && this.props.albums && <Albums {...baseProps} />}
        
        {this.props.myPurchases && this.state.baseContext.data.paidEvents && <MyPurchases {...baseProps} />}
        {isAdmin && this.props.uploadedVideos && (
          <UploadedVideos {...baseProps} />
        )}

        {!sm && !banner && !this.props.watchLiveVideo && <div className="Right" />}
      </div>
    );
  }
  renderContentRestriction() {
    return <UserAgreementForm />;
  }

  renderSpecialitySelectionDialog() {
    const { userData, specializations } = this.state.baseContext.data;

    if (activeUser() && userData && userData.id) {
      if (specializations) {
        if (
          !userData.nationality ||
          !userData.preferedLanguage ||
          !userData.professionId ||
          (userData.professionId &&
            !userData.specialityId &&
            toArray(specializations).find(
              (s) => s.professionId === userData.professionId
            ))
        ) {
          return <SpecialitySelectionDialog />;
        }
      }
    }
    return null;
  }

  render() {
    const { data } = this.state.baseContext;
    let description = Contexts.get(Contexts.Context.siteDescription);
    let imageUrl = "";
    if (data && data.videos) {
      const queryValues = queryString.parse(this.props.history.location.search);
      if (queryValues.event) {
        const video = data.videos[queryValues.event];
        description = video.name;
        imageUrl = video.imageUrl;
      }
    }

    return (
      <BaseContext.Provider value={this.state}>
        <Helmet>
          <meta name="og:description" content={description} />
          <meta name="og:image" content={imageUrl} />
        </Helmet>
        <div className="App" ref={(r) => (this.AppRef = r)}>
          <Snackbar
            place="bc"
            color="info"
            icon={AddAlert}
            message={this.state.infoMessage}
            open={this.state.showInfoMessage}
            closeNotification={() => this.setState({ showInfoMessage: false })}
            close
          />
          {this.renderHeader()}
          {this.renderContent()}
        </div>
        <LoginRequiredDialog
          open={this.state.showLoginRequired}
          closeDialog={() => this.setState({ showLoginRequired: false })}
        />
        <PaymentRequiredDialog
          open={this.state.showPaymentRequired}
          paidEvent={this.state.baseContext.paidEvent}
          closeDialog={() => this.setState({ showPaymentRequired: false })}
        />
        <SessionIsOpenDialog
          open={this.state.showSessionIsOpen}
          paidEvent={this.state.baseContext.paidEvent}
          eventId={this.state.baseContext.eventId}
          closeDialog={() => this.setState({ showSessionIsOpen: false })}
        />
        <RealtimeMessageDialog
          open={this.state.showRealtimeMessageDialog}
          videoId={this.state.baseContext.videoId}
          message={this.state.baseContext.message}
          actionUrl={this.state.baseContext.actionUrl}
          closeDialog={() => this.setState({ showRealtimeMessageDialog: false })}
        />
        {/* <EmailVerifyRequiredDialog
          open={this.state.showVerifyRequired}
          closeDialog={() => this.setState({ showVerifyRequired: false })}
        /> */}
        <NotificationDialog
          open={this.state.showNotificationDialog}
          closeDialog={() => this.setState({ showNotificationDialog: false })}
          title={this.state.notificationTitle}
          message={this.state.notificationMessage}
          actionLabel={"Hemen İzle"}
          action={() => {
            this.props.history.push(
              "/watchLive?event=" + this.state.notificationItemId
            );
          }}
        />
        <UserTagsDialog
          open={this.state.showUserTagsDialog}
          closeDialog={() => {
            this.setState({ showUserTagsDialog: false });
          }}
        />
      </BaseContext.Provider>
    );
  }
}
App.contextType = BaseContext;

export default withRouter(App);
