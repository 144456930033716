import React from "react";

function TagItem({ ...props }) {
  const { history, name} = props;
  return  <div
  className={"tag"}
    onClick={() => {
      history.push(
        "/searchResult?search=" + name + "&type=tag"
      );
    }}
  >
    <span style={{fontSize:'1.3em', marginRight:2}}>#</span>{name}
  </div>
}

export default (TagItem);
