import React from "react";
import "../Components.css";
import ResponsiveEmbed from "./ResponsiveEmbed";
import LiveChat from "./LiveChat";
import VideoComments from "./VideoComments";
import VideoRecommendations from "./VideoRecommendations";
import { BaseContext } from "../baseContext";
import TagItem from "./TagItem";
import * as firebase from "firebase";
import {
  activeUser,
  isUserEmailValidated,
  addVideoWathcer,
  addRemoveItemToFollowList,
  paths,
  getEventVideo,
  getDeviceId,
  getLocalData,
  setLocalData,
} from "../api";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { toArray } from "lodash";
import LiveEventReminder from "./LiveEventReminder";
const moment = require("moment-timezone");

class MainLiveVideoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: null,
      newMessage: "",
      chatHeight: null,
      chatTop: 0,
      player: "vimeo",
      youtubeId: "",
      paidEvent: null,
      hasPermision: false,
      loading: false,
      deviceId: getDeviceId(),
    };
  }
  componentDidMount() {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired(
        this.props.location.pathname + this.props.location.search
      );
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      const queryValues = queryString.parse(this.props.history.location.search);
      let videoId = queryValues.event;

      if (this.props.baseContext.baseContext.session.admin) {
        this.props.history.push("manageLive?event=" + videoId);
      }

      this.loadVideoData(videoId);
      this.setState({ loading: true });
      getEventVideo(videoId).then((result) => {
        this.setState({ loading: false });
        if (!result.error && result.videoId) {
          this.setState({
            videoId: result.videoId,
            hasPermision: result.hasPermision,
            chatHeight: 0,
          });
        } else {
          this.props.baseContext.setShowPaymentRequired(this.state.paidEvent);
        }
      });
    }
  }

  loadVideoData(eventId) {
    const { data } = this.props.baseContext.baseContext;
    const queryValues = queryString.parse(this.props.history.location.search);
    const player = queryValues.player || "vimeo";
    if (data && data.videos && queryValues.event) {
      const video = data.videos[eventId];

      const user = activeUser();
      if (!this.state.video && video && user && user.uid) {
        const isOnline = video.duration === 0;
        // if (isOnline) 
        {
          addVideoWathcer(video.id, user.uid);
        }
      }
      if (video) {
        let { liveEvents } = data;
        if (!liveEvents) {
          liveEvents = [];
        }
        let liveEvent = liveEvents.find((liveEvent) => {
          return liveEvent.videoId === video.id;
        });
        if (liveEvent && activeUser()) {
          window.$crisp.push([
            "set",
            "session:data",
            [
              [
                ["liveEventId", liveEvent.videoId],
                ["user_id", activeUser().uid],
              ],
            ],
          ]);
        }
        let paidEvent = null;
        if (data.paidEvents) {
          paidEvent = toArray(data.paidEvents).find(
            (p) => p.videos && p.videos.indexOf(video.id) > -1
          );
        }

        this.state.video = video;
        this.state.liveEvent = liveEvent;
        this.state.player = player;
        this.state.paidEvent = paidEvent;
        this.state.eventId = eventId;
        var commentsRef = firebase
          .database()
          .ref(paths.videos + "/" + video.id + "/");
        const that = this;
        commentsRef.on("child_changed", function (data) {
          if (data.key === "videoId") {
            that.state.video[data.key] = data.val();
            console.log(that.state.video);
            that.setState({
              video: that.state.video,
              videoId: that.state.video.videoId,
            });
          }
        });
        const user = activeUser();
        if (user) {
          var sessionsRefClient = firebase
            .database()
            .ref(
              "/main/videoSessions/" +
                video.id +
                "/sessions/" +
                user.uid +
                "/activeSession/"
            );

          sessionsRefClient.on("child_changed", function (data) {
            const activeSession = data.val();
            console.log(activeSession);
            if (activeSession !== that.state.deviceId) {
              that.setState({ hasPermision: false, videoId: null });
              that.props.baseContext.setShowSessionIsOpen(
                that.state.paidEvent,
                that.state.eventId
              );
            }
          });

          var realtimeMessageRefClient = firebase
            .database()
            .ref(
              "/main/videoRealtimeMesssages/" + video.id + "/realtimeMessages/"
            );

          realtimeMessageRefClient.on("child_added", function (data) {
            const realtimeMessage = data.val();
            console.log(data.key);
            if (
              realtimeMessage &&
              realtimeMessage.date &&
              realtimeMessage.date >=
                moment().tz("Europe/Istanbul").unix() - 300
            ) {
              const realtimeMessages = getLocalData("realtimeMessages") || {};
              if (realtimeMessages[data.key]) {
                return;
              }
              console.log(realtimeMessage);
              realtimeMessages[data.key] = realtimeMessage;
              setLocalData("realtimeMessages", realtimeMessages);
              that.props.baseContext.setShowRealtimeMessageDialog(
                realtimeMessage.message,
                realtimeMessage.action,
                video.id
              );
            }
            // if (activeSession !== that.state.deviceId) {
            //   that.setState({ hasPermision: false, videoId: null });
            //   that.props.baseContext.setShowSessionIsOpen(
            //     that.state.paidEvent,
            //     that.state.eventId
            //   );
            // }
          });
        }
        this.setState(this.state);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const queryValues = queryString.parse(nextProps.history.location.search);
    let videoId = queryValues.event;
    if (queryValues.new) {
      this.forceUpdate();
    }

    if (nextProps.baseContext.baseContext.session.admin) {
      nextProps.history.push("manageLive?event=" + videoId);
    }
    // if (
    //   nextProps.baseContext.baseContext.data !==
    //   this.props.baseContext.baseContext.data
    // ) {
    //   const queryValues = queryString.parse(this.props.history.location.search);
    //   let videoId = queryValues.event;
    //   this.loadVideoData(videoId);
    //   getEventVideo(videoId).then((result) => {
    //     if (!result.error && result.videoId) {
    //       this.setState({
    //         videoId: result.videoId,
    //         hasPermision: result.hasPermision,
    //         chatHeight: 0,
    //       });
    //     } else {
    //       this.props.baseContext.setShowPaymentRequired(this.state.paidEvent);
    //     }
    //   });
    // }
  }

  handleFollow() {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired();
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      const { video } = this.state;
      const { data } = { ...this.props.baseContext.baseContext };

      let userFollow = false;
      if (video && video.id && data.userFollows) {
        userFollow =
          data.userFollows[video.id] && data.userFollows[video.id].follow;
      }
      addRemoveItemToFollowList(video.id, !userFollow);
      let { userFollows } = { ...this.props.baseContext.baseContext.data };
      if (!userFollows) userFollows = {};
      if (!userFollows[video.id]) userFollows[video.id] = {};
      userFollows[video.id] = { follow: !userFollow };
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
    }
  }
  componentWillUnmount() {
    if (this.videoFrameRef)
      this.videoFrameRef.removeEventListener("resize", this.videoSizeChanged);
    // if (!this.watcherUpdater) {
    //   clearInterval(this.watcherUpdater);
    // }
  }
  componentDidUpdate() {
    if (this.videoFrameRef && this.videoDetailsRef && !this.state.chatHeight) {
      this.videoFrameRef.addEventListener("resize", this.videoSizeChanged);
      this.setState({
        chatHeight:
          this.videoFrameRef.clientHeight - this.videoDetailsRef.clientHeight,
        chatTop: this.videoFrameRef.clientHeight,
      });
    }
  }
  videoSizeChanged() {
    if (this.videoFrameRef) {
      this.setState({
        chatHeight:
          this.videoFrameRef.clientHeight - this.videoDetailsRef.clientHeight,
        chatTop: this.videoFrameRef.clientHeight,
      });
    }
  }

  renderReactFrame(enable) {
    const { videoId, paidEvent } = this.state;
    const loginRequired = !activeUser() || !isUserEmailValidated();
    const imageProps = {
      className: "MainLiveVideoTumbnail_Image",
      style: { position: "inherit", cursor: "pointer" },
      onClick: () =>
        loginRequired
          ? this.props.baseContext.setShowLoginRequired(
              this.props.location.pathname + this.props.location.search
            )
          : this.props.baseContext.setShowPaymentRequired(paidEvent),
      src: this.state.video.imageUrl,
    };
    let url =
      "https://player.vimeo.com/video/" +
      videoId +
      "?autoplay=1&loop=1&autopause=0&muted=0";
    if (this.state.player === "youtube" && this.state.youtubeId) {
      url =
        "https://www.youtube.com/embed/" +
        this.state.youtubeId +
        "?autoplay=1&disablekb=1&modestbranding=1&color=white";
    }
    return (
      <ResponsiveEmbed
        src={url}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        imageProps={imageProps}
        enable={enable}
      />
    );
  }

  renderDetails() {
    const { video, videoId } = this.state;
    let { videoTags, tags } = this.props.baseContext.baseContext.data;
    let tagItems = null;
    if (video && videoTags && tags) {
      tagItems = toArray(tags)
        .filter((tag) => {
          return (
            videoTags[video.id] && videoTags[video.id].indexOf(tag.id) > -1
          );
        })
        .map((map) => <TagItem name={map.name} history={this.props.history} />);
    }

    if (video) {
      return (
        <div
          className="MainLiveView_Details"
          ref={(r) => (this.videoDetailsRef = r)}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "6px",
              }}
            >
              {tagItems}
            </div>
            <div className="h1">{video.name}</div>
            <div dangerouslySetInnerHTML={{ __html: video.description }} />
          </div>
        </div>
      );
    }
    return null;
  }

  getRecommendedVideos() {
    let { data } = this.props.baseContext.baseContext;
    const { videoId } = this.state;
    const videoAlbums = data.videoAlbums[videoId];
    const videos = [];
    toArray(data.albums).forEach((album) => {
      if (videoAlbums && videoAlbums.indexOf(album.id) > -1) {
        const albumVideoIds = data.albumVideos[album.id];
        if (albumVideoIds) {
          albumVideoIds.forEach((_videoId) => {
            if (videos.filter((v) => v && v.id !== _videoId) && _videoId !== videoId)
              videos.push(data.videos[_videoId]);
          });
        }
      }
    });

    return videos;
  }
  render() {
    let { data } = this.props.baseContext.baseContext;
    const { videoId, hasPermision, loading } = this.state;

    if (loading) {
      return null;
    }

    const recommendedVideos = this.getRecommendedVideos();
    const loginRequired = !activeUser() || !isUserEmailValidated();
    const videoEnable = videoId && hasPermision && !loginRequired;
    if (!videoEnable) {
      console.log(this.state);
    }

    let showBackButton =
      window.previousLocation &&
      !window.previousLocation.pathname.startsWith("sign");
    if (this.state.video) {
      const isOnline = this.state.video.duration === 0;
      let containerStyle = {};
      if (!isOnline || this.props.isMobile) {
        containerStyle = {
          flexDirection: "column",
          maxWidth: "calc( (100vh - 80px) * 1.6)",
        };
      }
      if (!videoEnable || !videoId) {
        containerStyle = {
          maxWidth: "calc( (100vh - 80px) * 1.5)",
        };
      }
      let userFollow = false;
      if (data.userFollows) {
        userFollow =
          data.userFollows[this.state.video.id] &&
          data.userFollows[this.state.video.id].follow;
      }

      return (
        <div className="MainLiveView">
          {showBackButton && !this.props.isMobile && (
            <img
              style={{ marginRight: "8px", cursor: "pointer" }}
              height={32}
              width={32}
              src="/assets/images/arrow-back-button.svg"
              onClick={() => this.props.history.goBack()}
              alt=""
            />
          )}
          {!loading && (
            <div className="MainLiveVideoViewContainer" style={containerStyle}>
              {showBackButton && this.props.isMobile && (
                <div
                  className="MainLiveViewVideo_return"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    style={{ marginRight: "8px", cursor: "pointer" }}
                    height={20}
                    width={20}
                    src="/assets/images/arrow-back-button.svg"
                    alt=""
                  />
                  Return to main page
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row",width: "100%" }}>
                <div
                  className="MainLiveViewVideo"
                  ref={(r) => (this.videoFrameRef = r)}
                  style={{top: this.props.isMobile ? (this.props.hasBanner ? 136 : 76) : 0}}
                >
                  {this.renderReactFrame(videoEnable)}
                  {!loginRequired &&
                    isOnline &&
                    videoEnable &&
                    this.state.liveEvent &&
                    this.state.liveEvent.time &&
                    this.state.liveEvent.time > moment().unix() && (
                      <LiveEventReminder
                        time={this.state.liveEvent.time}
                        userFollow={userFollow}
                        onClick={() => this.handleFollow()}
                      />
                    )}
                  {this.renderDetails()}
                </div>

                {!loginRequired &&
                  !this.props.isMobile &&
                  isOnline &&
                  videoEnable && (
                    <LiveChat
                      video={this.state.video}
                      maxHeight={this.state.chatHeight}
                      Top={this.state.chatTop}
                    />
                  )}
                {
                 !this.props.isMobile &&
                  !loginRequired &&
                  videoEnable &&
                  !isOnline &&
                  recommendedVideos.length > 0 && (
                    <VideoRecommendations
                      videos={recommendedVideos}
                      maxHeight={this.state.chatHeight}
                      Top={this.state.chatTop}
                    />
                  )}
              </div>
              {!loginRequired &&
                videoEnable &&
                (!isOnline || this.props.isMobile) &&
                this.state.video && (
                  <VideoComments
                    isOnline={isOnline}
                    video={this.state.video}
                    isMobile={this.props.isMobile}
                    maxHeight={this.state.chatHeight}
                    Top={this.state.chatTop}
                  />
                )}

              {/* {this.state.video.surveyUrl && (
                <SurveyDialog surveyUrl={this.state.video.surveyUrl}  isMobile={this.props.isMobile} />
              )} */}
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <MainLiveVideoView {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
