import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { BaseContext } from "../baseContext";
import DialogContent from "@material-ui/core/DialogContent";
import { toMap } from "../utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlbumList from "./AlbumList";
import TextField from "@material-ui/core/TextField";

class VideoSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }
  handleTumbnailClick(value) {
    this.props.onChange(value);
    this.props.closeDialog();
  }
  renderVideos() {
    const { data } = this.props.baseContext.baseContext;
    let videos = [];
    toMap(data.videos).forEach(video => {
      if (
        video.name.toLowerCase().includes(this.state.search.toLowerCase()) > 0
      )
        videos.push(video);
    });
    return (
      <AlbumList
        type={1}
        album={{ name: "" }}
        videos={videos}
        handleTumbnailClick={id => this.handleTumbnailClick(id)}
      />
    );
  }
  render() {
    return (
      <div>
        <Dialog
          maxWidth="md"
          open={this.props.open}
          onClose={() => this.props.closeDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title || 'Select Video'}</DialogTitle>
          <TextField
            id="name"
            style={{ margin: "10px 30px", width: "300px" }}
            variant="outlined"
            label={"Search"}
            type="text"
            onChange={e => this.setState({ search: e.target.value })}
          />
          <DialogContent style={{ width: 900 }}>
            {this.renderVideos()}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => (
      <VideoSelectDialog {...props} baseContext={context} ref={ref} />
    )}
  </BaseContext.Consumer>
));

export default withContext;
