import React from "react";
import Contexts from "../contexts";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import { setUserExtData } from "../api";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";
import { toArray } from "lodash";
import { countries } from "countries-list";
import { validateNationalID } from "../utils";

class SpecialitySelectionDialog extends React.Component {
  constructor(props) {
    super(props);
    const { userData } = this.props.baseContext.baseContext.data;
    this.state = {
      specialityId: userData.specialityId || "",
      professionId: userData.professionId || "",
      preferedLanguage: userData.preferedLanguage || "",
      nationality: userData.nationality || "",
      nationalID: userData.nationalID || "",
    };
  }
  nationalIDChange(value) {
    if (value.length <= 11 && !isNaN(value)) {
      this.setState({
        nationalID: value,
      });
    }
  }
  handleSave() {
    const {
      professionId,
      specialityId,
      preferedLanguage,
      nationality,
      nationalID,
    } = this.state;
    setUserExtData(
      professionId,
      specialityId,
      preferedLanguage,
      nationality,
      nationalID
    );
    const { userData } = this.props.baseContext.baseContext.data;
    this.props.baseContext.setUserData({
      ...userData,
      professionId: professionId,
      specialityId: specialityId,
      preferedLanguage: preferedLanguage,
      nationality: nationality,
      nationalID: nationalID,
    });
  }

  validateSubmit() {
    if (this.props.baseContext.baseContext) {
      const { specializations } = this.props.baseContext.baseContext.data;
      let isValid = true;

      const {
        nationalID,
        nationality,
        professionId,
        specialityId,
      } = this.state;
      if (
        !nationality ||
        (nationality && nationality === "TR" && !validateNationalID(nationalID))
      ) {
        isValid = false;
      }

      if (!professionId) {
        isValid = false;
      }
      if (
        professionId &&
        !specialityId &&
        toArray(specializations).find((s) => s.professionId === professionId)
      ) {
        isValid = false;
      }
      return isValid;
    }
  }

  render() {
    const { baseContext } = this.props.baseContext;
    const { userData } = this.props.baseContext.baseContext.data;
    const { professionId } = this.state;

    let specializations = [];
    let professions = [];
    if (
      baseContext &&
      baseContext.data &&
      baseContext.data.specializations &&
      professionId
    ) {
      specializations = toArray(
        Contexts.getList(baseContext.data, "specializations")
      )
        .filter((s) => s.professionId === professionId)
        .map((s) => {
          return {
            value: s.id,
            label: s.name,
          };
        });
    }
    if (baseContext && baseContext.data && baseContext.data.professions) {
      professions = toArray(
        Contexts.getList(baseContext.data, "professions")
      ).map((s) => {
        return {
          value: s.id,
          label: s.name,
        };
      });
    }

    const languages = [
      { value: "tr", label: Contexts.get(Contexts.Context.turkish) },
      { value: "en", label: Contexts.get(Contexts.Context.english) },
      { value: "all", label: Contexts.get(Contexts.Context.allLanguages) },
    ];

    let nationalities = [];
    Object.keys(countries).forEach((key) => {
      nationalities.push({
        value: key,
        label: countries[key].native,
      });
    });

    return (
      <div className="UserAgreementForm backdrop-blur">
        <div className="UserAgreementForm_inner">
          <div className="UserAgreementForm_title">
            {Contexts.get(Contexts.Context.requiredInfo)}
          </div>
          <div className="UserAgreementForm_content">
            {Contexts.get(Contexts.Context.requiredFieldsDialogContent)}
          </div>
          {!userData.nationality && (
            <TextField
              id="nationality"
              select
              style={{
                width: "100%",
                textAlign: "left",
                marginTop: "20px",
                zIndex: 10000000001,
              }}
              label={Contexts.get(Contexts.Context.nationality)}
              value={this.state.nationality || ""}
              onChange={(e) =>
                this.setState({
                  nationality: e.target.value,
                })
              }
              variant="outlined"
            >
              {nationalities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {this.state.nationality === "TR" && !userData.nationalID && (
            <div>
              <TextField
                id="nationalID"
                style={{
                  width: "100%",
                  textAlign: "left",
                  marginTop: "20px",
                  zIndex: 10000000001,
                }}
                label={Contexts.get(Contexts.Context.nationalID)}
                value={this.state.nationalID || ""}
                onChange={(e) => this.nationalIDChange(e.target.value)}
                error={this.state.errorNationalId}
                helperText={this.state.errorMessageNationalId}
                variant="outlined"
              />
            </div>
          )}
          {!userData.preferedLanguage && (
            <TextField
              id="preferedLanguage"
              select
              style={{
                width: "100%",
                textAlign: "left",
                marginTop: "20px",
                zIndex: 10000000001,
              }}
              label={Contexts.get(Contexts.Context.preferedLanguage)}
              value={this.state.preferedLanguage || ""}
              onChange={(e) =>
                this.setState({
                  preferedLanguage: e.target.value,
                })
              }
              variant="outlined"
            >
              {languages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {!userData.professionId && (
            <TextField
              id="professions"
              select
              style={{
                width: "100%",
                textAlign: "left",
                marginTop: "20px",
                zIndex: 10000000001,
              }}
              label={Contexts.get(Contexts.Context.profession)}
              value={this.state.professionId || ""}
              onChange={(e) =>
                this.setState({
                  professionId: e.target.value,
                  specialityId: null,
                })
              }
              variant="outlined"
            >
              {professions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {specializations.length > 0 && !userData.specialityId && (
            <TextField
              id="speciality"
              select
              style={{
                width: "100%",
                textAlign: "left",
                marginTop: "20px",
                zIndex: 10000000001,
              }}
              label={Contexts.get(Contexts.Context.speciality)}
              value={this.state.specialityId || ""}
              onChange={(e) => this.setState({ specialityId: e.target.value })}
              variant="outlined"
            >
              {specializations.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Button
            onClick={() => this.handleSave()}
            disabled={!this.validateSubmit()}
            style={{
              width: "100px",
              height: "50px",
              fontSize: "1em",
              marginTop: "20px",
            }}
            color="info"
          >
            {Contexts.get(Contexts.Context.save)}
          </Button>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => (
      <SpecialitySelectionDialog {...props} baseContext={context} />
    )}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
