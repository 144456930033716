import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import "../react-tags.css";
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";
import { toArray } from "lodash";
import * as firebase from "firebase";
import VideoThumbnail from "../components/VideoThumbnail";
import { BaseContext } from "../baseContext";
import {
  addTag,
  addTagToVideo,
  removeTagToVideo,
  getFirebaseData,
  paths
} from "../api";
import ReactTags from "react-tag-autocomplete";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: {},
      search: "",
      tags: [],
      videoTags: []
    };
  }

  componentDidMount() {
    getFirebaseData(paths.tags).then(tags => {
      this.setState({ tags: toArray(tags) });
    });
    const commentsRef = firebase.database().ref(paths.videoTags);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.videoTags[data.key]) {
        that.state.videoTags[data.key] = data.val();
        that.setState(that.state.videoTags);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.videoTags[data.key] = data.val();
      that.setState(that.state.videoTags);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.videoTags[data.key];
      that.setState(that.state.videoTags);
    });
  }
  handleDeleteTag(tagId, videoId) {
    removeTagToVideo(tagId, videoId);
  }
  handleAddTag(tag, videoId) {
    if (tag.id) {
      addTagToVideo(tag.id, videoId);
    } else {
      addTag(tag.name).then(newTag => {
        this.state.tags.push(newTag);
        this.setState(this.state);
        addTagToVideo(newTag.id, videoId);
      });
    }
  }
  handleChange(key, value) {
    this.setState({
      ...this.state,
      [key]: value
    });
  }

  renderVideoItem(video) {
    const { data } = { ...this.props.baseContext.baseContext };
    const videoAlbums = data.videoAlbums[video.id];
    let videoAlbumNames = "";
    if (videoAlbums) {
      toArray(data.albums).forEach(album => {
        if (videoAlbums.indexOf(album.id) > -1) {
          videoAlbumNames += album.name;
        }
      });
    }
    const tags = this.state.tags.filter(tag => {
      return (
        this.state.videoTags[video.id] &&
        this.state.videoTags[video.id].indexOf(tag.id) > -1
      );
    });

    const suggestions = this.state.tags.filter(tag => {
      return !(
        this.state.videoTags[video.id] &&
        this.state.videoTags[video.id].indexOf(tag.id) > -1
      );
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #d4d4d4",
          marginBottom: "10px"
        }}
      >
        <VideoThumbnail video={video} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <div>
            <strong>Video Id: </strong>
            {video.id}
          </div>
          <div>
            <strong>Albums: </strong>
            {videoAlbumNames}
          </div>
          <div style={{ display: "flex" }}>
            <ReactTags
              tags={tags}
              suggestions={suggestions}
              onAddition={tag => {
                this.handleAddTag(tag, video.id);
              }}
              onDelete={tag => {
                this.handleDeleteTag(tags[tag].id, video.id);
              }}
              allowNew
            />
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { classes } = this.props;
    const { data } = { ...this.props.baseContext.baseContext };
    let items = [];
    if (data.videos) {
      items = toArray(data.videos).filter(video => {
        return (
          this.state.search.length === 0 ||
          (this.state.search.length > 0 &&
            video.name.toLowerCase().includes(this.state.search.toLowerCase()) >
              0)
        );
      });
    }

    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className={classes.cardTitleWhite}>All Videos</h4>
                <TextField
                  id="name"
                  label={"Search"}
                  type="text"
                  value={this.state.search}
                  onChange={e => this.setState({ search: e.target.value })}
                />
              </CardHeader>
              <CardBody>
                {items.map(item => {
                  return this.renderVideoItem(item);
                })}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <Videos {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withStyles(styles)(withRouter(withContext));
