import lodash from 'lodash';

export function toMap(data) {
    return lodash(data)
    .keys()
    .map(key => {
        let cloned = lodash.clone(data[key]);
        if(cloned)
        {
            cloned.key = key;
            return cloned;
        }
    })
    .value();
}

export function validateNationalID(ID) {
    var tek = 0,
      cift = 0,
      sonuc = 0,
      TCToplam = 0,
      i = 0,
      hatali = [
        11111111110,
        22222222220,
        33333333330,
        44444444440,
        55555555550,
        66666666660,
        7777777770,
        88888888880,
        99999999990,
      ];
    if (!ID) return false;
    if (ID.length != 11) return false;
    if (isNaN(ID)) return false;
    if (ID[0] == 0) return false;

    tek =
      parseInt(ID[0]) +
      parseInt(ID[2]) +
      parseInt(ID[4]) +
      parseInt(ID[6]) +
      parseInt(ID[8]);
    cift =
      parseInt(ID[1]) + parseInt(ID[3]) + parseInt(ID[5]) + parseInt(ID[7]);

    tek = tek * 7;
    sonuc = Math.abs(tek - cift);
    if (sonuc % 10 != ID[9]) return false;

    for (i = 0; i < 10; i++) {
      TCToplam += parseInt(ID[i]);
    }

    if (TCToplam % 10 != ID[10]) return false;

    if (hatali.toString().indexOf(ID) != -1) return false;

    return true;
  }