import React from "react";
import "../Components.css";
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";
import { getPaidEvent, finishPayment, activeUser } from "../api";
import queryString from "query-string";
import BillingInfo from "./Payment/BillingInfo";
import OrderInfo from "./Payment/OrderInfo";
import PaymentSuccess from "./Payment/PaymentSuccess";
import CheckoutForm from "./Payment/CheckoutForm";
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from "react-transition-group";

import reactCardStyle from "./Payment/ReactCard.scss";

class AcceptPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      paidEvent: null,
      address: null,
      step: 0,
    };
  }

  componentDidMount() {
    const queryValues = queryString.parse(this.props.history.location.search);
    if (queryValues) {
      getPaidEvent(queryValues.paidEventId).then((result) => {
        console.log(result);
        if (result.error) {
          this.setState({
            loading: false,
            error: result.error,
          });
        } else {
          this.setState({
            loading: false,
            paidEventId: queryValues.paidEventId,
            paidEvent: result.paidEvent,
            amount: result.amount,
            error: null,
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {}

  handleAddress(address) {
    this.setState({ address });
  }

  handleSetOrderInfo(promotionCodeId, discountedPrice) {
    this.setState({
      promotionCodeId,
      discountedPrice,
    });
    if (discountedPrice > 0) {
      this.handleNextStep();
    }
  }

  handleNextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  handlePreviusStep() {
    this.setState({ step: this.state.step - 1 });
  }

  handleFinishPayment(clientSecret, paymentIntent) {
    const { paidEventId, promotionCodeId, address } = this.state;
    finishPayment(
      paidEventId,
      promotionCodeId,
      activeUser().uid,
      clientSecret,
      paymentIntent,
      address
    ).then((result) => {
      this.setState({ step: 3 });
    });
  }

  render() {
    const {
      paidEvent,
      address,
      step,
      paidEventId,
      promotionCodeId,
      discountedPrice,
    } = this.state;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <TransitionGroup className="slide-fade-up" compent="div">
          {paidEvent && step === 0 && (
            <CSSTransition classNames="slide-fade-up" timeout={250}>
              <OrderInfo
                paidEvent={paidEvent}
                onContinue={(promotionCodeId, discountedPrice) => {
                  this.handleSetOrderInfo(promotionCodeId, discountedPrice);
                }}
                onSetPromotion={(promotionCodeId, discountedPrice) => {
                  this.setState({
                    promotionCodeId,
                    discountedPrice,
                  });
                }}
                onPaymentSucceeded={() => {
                  this.handleFinishPayment("free", {free: true, status: "succeeded"});
                }}
              ></OrderInfo>
            </CSSTransition>
          )}
          {((!address && step === 1) || step === 1) && (
            <CSSTransition classNames="slide-fade-up" timeout={250}>
              <BillingInfo
                onBack={(address) => {
                  this.handleAddress(address);
                  this.handlePreviusStep();
                }}
                address={address}
                onContinue={(address) => {
                  this.handleAddress(address);
                  this.handleNextStep();
                }}
              ></BillingInfo>
            </CSSTransition>
          )}
          {address && step === 2 && (
            <CSSTransition classNames="slide-fade-up" timeout={250}>
              <CheckoutForm
                paidEventId={paidEventId}
                paidEvent={paidEvent}
                promotionCodeId={promotionCodeId}
                onBack={(e) => this.handlePreviusStep()}
                address={address}
                discountedPrice={discountedPrice}
                finishPayment={(clientSecret, paymentIntent) => {
                  this.handleFinishPayment(clientSecret, paymentIntent);
                }}
              />
            </CSSTransition>
          )}
          {step === 3 && (
            <CSSTransition classNames="slide-fade-up" timeout={250}>
              <PaymentSuccess paidEvent={paidEvent}></PaymentSuccess>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <AcceptPayment {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
