import React from "react";
import "../Components.css";
import {
  registerUserByEmail,
  loginByEmail,
  loginByFacebook,
  loginByGoogle,
  resetPassword,
} from "../api";
import { BaseContext } from "../baseContext";
import AddAlert from "@material-ui/icons/Error";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import Snackbar from "./materialComponents/Snackbar/Snackbar.jsx";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Contexts from "../contexts";
import { withRouter } from "react-router-dom";
import { toArray } from "lodash";
import { countries } from "countries-list";
import { validateNationalID } from "../utils";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      error: "",
      showMessage: false,
      message: "",
      data: {
        name: "",
        email: "",
        password: "",
        nameError: "",
        emailError: "",
        passwordError: "",
        sprcialityError: "",
        professionError: "",
        professionId: "",
        specialityId: "",
        preferedLanguage: "",
        nationality: "",
        nationalID: "",
        preferedLanguageError: "",
        natinalityError: "",
        natinoalIDError: "",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data });
    }
  }
  validate() {
    let isValid = true;
    const { data } = this.state;
    data.nameError = "";
    data.passwordError = "";
    data.emailError = "";
    data.sprcialityError = "";
    data.professionError = "";
    data.preferedLanguageError = "";
    data.natinalityError = "";
    data.natinoalIDError = "";

    if (this.props.signUp) {
      if (data.name.length === 0) {
        data.nameError = Contexts.get(Contexts.Context.nameIsRequired);
        isValid = false;
      }
      if (data.name.length < 3) {
        data.nameError = Contexts.get(Contexts.Context.nameIsTooShort);
        isValid = false;
      }
      if (data.preferedLanguage === "") {
        data.preferedLanguageError = Contexts.get(
          Contexts.Context.preferedLanguageRequired
        );
        isValid = false;
      }
      if (data.professionId === "") {
        data.professionError = Contexts.get(
          Contexts.Context.professionRequired
        );
        isValid = false;
      }
      if (data.specialityId === "") {
        if (data.professionId !== "") {
          const { baseContext } = this.props.baseContext;
          if (
            toArray(baseContext.data.specializations).find(
              (s) => s.professionId === data.professionId
            )
          ) {
            data.sprcialityError = Contexts.get(
              Contexts.Context.sprcialityRequired
            );
            isValid = false;
          }
        }
      }
      if (data.nationality === "") {
        data.natinalityError = Contexts.get(
          Contexts.Context.nationalityRequired
        );
        isValid = false;
      }
      if (
        data.nationality &&
        data.nationality === "TR" &&
        !validateNationalID(data.nationalID)
      ) {
        data.natinoalIDError = Contexts.get(
          Contexts.Context.nationalIDNotValid
        );
        isValid = false;
      }
    }
    if (data.password.length < 6) {
      data.passwordError = Contexts.get(Contexts.Context.passwordShort);
      isValid = false;
    }
    if (
      data.email.length < 5 ||
      data.email.indexOf("@") < 0 ||
      data.email.indexOf(".") < 0
    ) {
      data.emailError = Contexts.get(Contexts.Context.emailNotValid);
      isValid = false;
    }

    this.setState({ data: data });
    return isValid;
  }
  handleChange(key, value) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSave();
    }
  }
  handleResetPassword() {
    const { data } = this.state;
    if (
      data.email.length < 5 ||
      data.email.indexOf("@") < 0 ||
      data.email.indexOf(".") < 0
    ) {
      data.emailError = Contexts.get(Contexts.Context.enterValidEmail);
      this.setState({ data: data });
      return false;
    }
    resetPassword(data.email, (result) => {
      if (!result.Error) {
        this.setState({
          message: Contexts.get(Contexts.Context.weSentEmailForResetPassword),
          showMessage: true,
        });
      } else {
        this.setState({
          error: Contexts.get(Contexts.Context.weCouldntFindAccount),
          showError: true,
        });
      }
    });
  }
  handleSave() {
    if (this.validate()) {
      const { data } = this.state;
      if (this.props.signUp) {
        registerUserByEmail(
          data.email,
          data.password,
          data.name,
          data.professionId,
          data.specialityId,
          data.preferedLanguage,
          data.nationality,
          data.nationalID,
          (result) => {
            if (!result.Error) {
              this.props.baseContext.baseContext.session.uid =
                result.User.user.uid;
              this.props.baseContext.baseContext.session.displayName =
                result.User.user.displayName;
              this.props.baseContext.setBase(
                this.props.baseContext.baseContext
              );
              if (this.props.baseContext.baseContext.requestedPath)
                this.props.history.push(
                  this.props.baseContext.baseContext.requestedPath
                );
              else this.props.history.push("/");
            } else {
              this.setState({ error: result.Message, showError: true });
            }
          }
        );
      } else {
        loginByEmail(data.email, data.password, (result) => {
          if (!result.Error) {
            this.props.baseContext.baseContext.session.uid =
              result.User.user.uid;
            this.props.baseContext.baseContext.session.displayName =
              result.User.user.displayName;
            this.props.baseContext.setBase(this.props.baseContext.baseContext);
            if (this.props.baseContext.baseContext.requestedPath)
              this.props.history.push(
                this.props.baseContext.baseContext.requestedPath
              );
            else this.props.history.push("/");
          } else {
            this.setState({ error: result.Message, showError: true });
          }
        });
      }
    }
  }
  loginByFacebook() {
    loginByFacebook((result) => {
      if (!result.Error) {
        this.props.baseContext.baseContext.session.uid = result.User.uid;
        this.props.baseContext.baseContext.session.displayName =
          result.User.displayName;
        this.props.baseContext.setBase(this.props.baseContext.baseContext);
        if (this.props.baseContext.baseContext.requestedPath)
          this.props.history.push(
            this.props.baseContext.baseContext.requestedPath
          );
        else this.props.history.push("/");
      } else {
        this.setState({ error: result.Message, showError: true });
      }
    });
  }

  loginByGoogle() {
    // if (this.props.isMobile) {
    //   loginByGoogle(true);
    // } else {
    loginByGoogle(false, (result) => {
      if (!result.Error) {
        this.props.baseContext.baseContext.session.uid = result.User.uid;
        this.props.baseContext.baseContext.session.displayName =
          result.User.displayName;
        this.props.baseContext.setBase(this.props.baseContext.baseContext);
        if (this.props.baseContext.baseContext.requestedPath)
          this.props.history.push(
            this.props.baseContext.baseContext.requestedPath
          );
        else this.props.history.push("/");
      } else {
        this.setState({ error: result.Message, showError: true });
      }
    });
    // }
  }
  nationalIDChange(value) {
    if (value.length <= 11 && !isNaN(value)) {
      this.handleChange("nationalID", value);
    }
  }
  handleClose() {
    this.props.closeDialog();
  }
  render() {
    const { baseContext } = this.props.baseContext;
    const { data } = this.state;
    let specializations = [];
    let professions = [];
    if (
      baseContext &&
      baseContext.data &&
      baseContext.data.specializations &&
      data.professionId !== ""
    ) {
      specializations = toArray(
        Contexts.getList(baseContext.data, "specializations")
      )
        .filter((s) => s.professionId === data.professionId)
        .map((s) => {
          return {
            value: s.id,
            label: s.name,
          };
        });
    }
    if (baseContext && baseContext.data && baseContext.data.professions) {
      professions = toArray(
        Contexts.getList(baseContext.data, "professions")
      ).map((s) => {
        return {
          value: s.id,
          label: s.name,
        };
      });
    }

    const languages = [
      { value: "tr", label: Contexts.get(Contexts.Context.turkish) },
      { value: "en", label: Contexts.get(Contexts.Context.english) },
      { value: "all", label: Contexts.get(Contexts.Context.allLanguages) },
    ];

    let nationalities = [];
    Object.keys(countries).forEach((key) => {
      nationalities.push({
        value: key,
        label: countries[key].native,
      });
    });
    return (
      <div className="SignIn">
        <div className="SignIn_Header">
          {this.props.signUp
            ? Contexts.get(Contexts.Context.createAccount)
            : Contexts.get(Contexts.Context.welcomeBack)}
        </div>
        <div className="SignIn_Warning">
          {this.props.signUp && (
            <span>{Contexts.get(Contexts.Context.signupWarning)}</span>
          )}
        </div>
        <div className="SignIn_Inner">
          <div className="SignIn_Inner_Form">
            <Snackbar
              place="bc"
              color="danger"
              icon={AddAlert}
              message={this.state.error}
              open={this.state.showError}
              closeNotification={() => this.setState({ showError: false })}
              close
            />
            <Snackbar
              place="bc"
              color="info"
              icon={AddAlert}
              message={this.state.message}
              open={this.state.showMessage}
              closeNotification={() => this.setState({ showMessage: false })}
              close
            />
            {this.props.signUp && (
              <TextField
                id="name"
                style={{ marginTop: "20px" }}
                variant="outlined"
                label={Contexts.get(Contexts.Context.fullName)}
                type="text"
                onChange={(e) => this.handleChange("name", e.target.value)}
              />
            )}
            {this.props.signUp && data.nameError.length > 0 && (
              <div className="input-error">{data.nameError}</div>
            )}
            <TextField
              id="email"
              variant="outlined"
              style={{ marginTop: "20px" }}
              label={Contexts.get(Contexts.Context.email)}
              type="email"
              onChange={(e) => this.handleChange("email", e.target.value)}
            />
            {data.emailError.length > 0 && (
              <div className="input-error">{data.emailError}</div>
            )}
            <TextField
              id="password"
              variant="outlined"
              style={{ marginTop: "20px" }}
              label={Contexts.get(Contexts.Context.password)}
              type="password"
              onKeyPress={(e) => this.handleKeyPress(e)}
              onChange={(e) => this.handleChange("password", e.target.value)}
            />
            {data.passwordError.length > 0 && (
              <div className="input-error">{data.passwordError}</div>
            )}
            {this.props.signUp && (
            <TextField
              id="nationality"
              select
              style={{
                width: "100%",
                textAlign: "left",
                marginTop: "20px",
              }}
              label={Contexts.get(Contexts.Context.nationality)}
              value={this.state.data.nationality || ""}
              onChange={(e) => this.handleChange("nationality", e.target.value)}
              variant="outlined"
            >
              {nationalities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            )}
            {this.props.signUp && data.natinalityError.length > 0 && (
              <div className="input-error">{data.natinalityError}</div>
            )}
            {this.state.data.nationality === "TR" && this.props.signUp && (
              <div>
                <TextField
                  id="nationalID"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                  label={Contexts.get(Contexts.Context.nationalID)}
                  value={this.state.data.nationalID || ""}
                  onChange={(e) => this.nationalIDChange(e.target.value)}
                  error={this.state.errorNationalId}
                  helperText={this.state.errorMessageNationalId}
                  variant="outlined"
                />
              </div>
            )}
            {this.props.signUp && data.natinoalIDError.length > 0 && (
              <div className="input-error">{data.natinoalIDError}</div>
            )}
            {this.props.signUp && (
              <TextField
                id="preferedLanguage"
                select
                style={{ textAlign: "left", marginTop: "20px" }}
                label={Contexts.get(Contexts.Context.preferedLanguage)}
                value={data.preferedLanguage || ""}
                onChange={(e) => {
                  this.handleChange("preferedLanguage", e.target.value);
                }}
                variant="outlined"
              >
                {languages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {this.props.signUp && data.preferedLanguageError.length > 0 && (
              <div className="input-error">{data.preferedLanguageError}</div>
            )}
            {this.props.signUp && (
              <TextField
                id="professionId"
                select
                style={{ textAlign: "left", marginTop: "20px" }}
                label={Contexts.get(Contexts.Context.profession)}
                value={data.professionId || ""}
                onChange={(e) => {
                  this.handleChange("professionId", e.target.value);
                }}
                variant="outlined"
              >
                {professions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {this.props.signUp && data.professionError.length > 0 && (
              <div className="input-error">{data.professionError}</div>
            )}
            {this.props.signUp && specializations.length > 0 && (
              <TextField
                id="speciality"
                select
                style={{ textAlign: "left", marginTop: "20px" }}
                label={Contexts.get(Contexts.Context.speciality)}
                value={data.specialityId || ""}
                onChange={(e) =>
                  this.handleChange("specialityId", e.target.value)
                }
                variant="outlined"
              >
                {specializations.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {this.props.signUp &&
              specializations.length > 0 &&
              data.sprcialityError.length > 0 && (
                <div className="input-error">{data.sprcialityError}</div>
              )}
            {!this.props.signUp && (
              <div
                className="forgotPassword"
                onClick={() => this.handleResetPassword()}
              >
                {Contexts.get(Contexts.Context.forgotPassword)}
              </div>
            )}
            {this.props.signUp && (
              <div className="termsWarning">
                {Contexts.get(Contexts.Context.termsWarning)}

                <a href="/termsandconditions" target="_blank">
                  {Contexts.get(Contexts.Context.termsLabel)}
                </a>
              </div>
            )}
            <Button
              onClick={() => this.handleSave()}
              style={{
                width: "100%",
                height: "50px",
                fontSize: "1em",
                marginTop: "20px",
              }}
              color="info"
            >
              {this.props.signUp
                ? Contexts.get(Contexts.Context.createAccount)
                : Contexts.get(Contexts.Context.login)}
            </Button>
          </div>
          <div className="SignIn_SocialMedia">
            <span style={{ backgroundColor: "#FFF", padding: "8px" }}>OR</span>
            <Button
              onClick={() => this.loginByGoogle()}
              style={{
                border: "1px solid ece9e9",
                width: "100%",
                height: "50px",
                fontSize: "1em",
                marginTop: "20px",
              }}
              color="white"
            >
              {Contexts.get(Contexts.Context.signGoogle)}
            </Button>
            <Button
              onClick={() => this.loginByFacebook()}
              style={{
                width: "100%",
                height: "50px",
                fontSize: "1em",
                marginTop: "10px",
              }}
              color="white"
            >
              {Contexts.get(Contexts.Context.signFacebook)}
            </Button>
          </div>
        </div>
        {!this.props.signUp && (
          <div
            className="signInUpSwitch"
            onClick={() => {
              this.props.history.push("/signUp");
            }}
          >
            {Contexts.get(Contexts.Context.createAccount)}
          </div>
        )}
        {this.props.signUp && (
          <div
            className="signInUpSwitch"
            onClick={() => {
              this.props.history.push("/signIn");
            }}
          >
            {Contexts.get(Contexts.Context.haveAccount)}
          </div>
        )}
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <SignIn {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
