import React from "react";
import lodash from "lodash";
import { toMap } from "../utils";
import "../Layouts.css";
import { BaseContext } from "../baseContext";
import Contexts from "../contexts";
import MainLiveVideoTumbnail from "../components/MainLiveVideoTumbnail";
import MainLiveVideoTumbnailMobile from "../components/MainLiveVideoTumbnailMobile";
import AlbumList from "../components/AlbumList";
import AlbumSelectDialog from "../components/AlbumSelectDialog";
import Button from "../components/materialComponents/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import VideoSelectDialog from "../components/VideoSelectDialog";
import {
  setFirebaseData,
  paths,
  activeUser,
  isUserEmailValidated
} from "../api";
import { withRouter } from "react-router-dom";

const moment = require("moment-timezone");

class FeaturedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openItemSelectDialog: false,
      selectionItems: [],
      selectionTitle: "",
      handleSelectedItem: null,
      openVideoSelectDialog: false
    };
  }
  handleTumbnailClick(id) {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired("/watchLive?event=" + id);
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      this.props.baseContext.baseContext.data.currentVideoId = id;
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
      this.props.baseContext.setShowLoginRequired(
        this.props.location.pathname + this.props.location.search
      );
      this.props.history.push("/watchLive?event=" + id);
    }
  }
  handleItemsSelectDialogClose() {
    this.setState({ openItemSelectDialog: false });
  }
  selectAlbumItem(index) {
    const { data } = { ...this.props.baseContext.baseContext };
    let albumsMap = toMap(data.albums);
    const handleSelectedItem = albumId => {
      if (!data.config.albums) {
        data.config.albums = [];
      }
      const tempAlbums = data.config.albums.filter(album => {
        return album.order >= index;
      });
      tempAlbums.forEach(album => {
        album.order = album.order + 1;
      });
      data.config.albums.push({
        albumId: albumId,
        order: index,
        type: 1
      });
      setFirebaseData(paths.config, data.config);
      setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    };

    this.setState({
      openItemSelectDialog: true,
      selectionTitle: "Select Album",
      selectionItems: albumsMap,
      handleSelectedItem: handleSelectedItem
    });
  }
  handleDeleteUpcomingEvents() {
    const { data } = this.props.baseContext.baseContext;
    data.config.upcomingLiveEvents = [];
    setFirebaseData(paths.config, data.config);
    setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
  }
  handleDeleteAlbumFromConfig(id) {
    const { data } = this.props.baseContext.baseContext;
    var albumConfig = data.config.albums[id];
    const tempAlbums = data.config.albums.filter(album => {
      return album.order >= albumConfig.order;
    });
    tempAlbums.forEach(album => {
      album.order = album.order - 1;
    });
    data.config.albums.splice(id, 1);
    setFirebaseData(paths.config, data.config);
    setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
  }
  handleSetAlbumType(id, type) {
    const { data } = this.props.baseContext.baseContext;
    var albumConfig = data.config.albums[id];
    albumConfig.type = type;
    setFirebaseData(paths.config, data.config);
    setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
  }

  renderBlank() {
    let albums = [];
    for (let i = 0; i < 10; i++) {
      albums.push(
        <div key={i} style={{ width: "100%" }}>
          <AlbumList type={2} key={i} blank />
        </div>
      );
    }
    return (
      <div className="Featured">
        {!this.props.isMobile && <MainLiveVideoTumbnail key={1} />}
        {this.props.isMobile && <MainLiveVideoTumbnailMobile key={1} />}
        <div className="FlexColumn">{albums}</div>
      </div>
    );
  }
  handleSelectedVideo(id) {
    const { data } = { ...this.props.baseContext.baseContext };
    if (!data.config.upcomingLiveEvents) data.config.upcomingLiveEvents = [];
    data.config.upcomingLiveEvents.push(id);

    let liveEvent = data.liveEvents.find(liveEvent => {
      return liveEvent.videoId === id;
    });
    if (!liveEvent) {
      liveEvent = {
        videoId: id
      };
      data.liveEvents.push(liveEvent);
      setFirebaseData(paths.liveEvents, data.liveEvents);
      setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    }
  
    setFirebaseData(paths.config, data.config);
    setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
  }

  render() {
    const { data, session } = this.props.baseContext.baseContext;
    if (!data.albums || !data.config || !data.config.albums) {
      return this.renderBlank();
    }
    let upcomingVideos = [];
    if (data.videos && data.config.upcomingLiveEvents) {
      data.config.upcomingLiveEvents.forEach(videoId =>
        {
        const video = data.videos[videoId];
        if (video) {
          let liveEvent = data.liveEvents.find(liveEvent => {
            return liveEvent.videoId === video.id;
          });
          if (liveEvent && liveEvent.time >= moment().tz("Europe/Istanbul").unix())
            upcomingVideos.push(data.videos[videoId]);
          }
        }
      );
    }
    var albums = null;
    if (data.albums && data.config && data.config.albums) {
      let albumsConfigMap = toMap(data.config.albums);
      albumsConfigMap = lodash.orderBy(albumsConfigMap, ["order"], ["asc"]);
      const albumsData = Contexts.getList(data, "albums");
      albums = albumsConfigMap.map((albumConfig, albumConfigId) => {
        if (albumConfig) {
          const album = albumsData[albumConfig.albumId];
          if (album) {
            const videos = [];
            const albumVideoIds = data.albumVideos[albumConfig.albumId];
            if (albumVideoIds) {
              albumVideoIds.forEach(videoId => {
                if (data.videos[videoId])
                videos.push(data.videos[videoId]);
              });
            }
            return (
              <div key={albumConfig.albumId} style={{ width: "100%" }}>
                <AlbumList
                  key={albumConfig.albumId}
                  albumConfigId={albumConfig.key}
                  admin={session.admin}
                  type={albumConfig.type}
                  album={album}
                  videos={videos}
                  onDelete={id => this.handleDeleteAlbumFromConfig(id)}
                  setType={(id, type) => this.handleSetAlbumType(id, type)}
                  handleTumbnailClick={id => this.handleTumbnailClick(id)}
                />
                {session.admin && (
                  <Button
                    style={{ marginTop: -50 }}
                    justIcon
                    round
                    color="success"
                    onClick={() => {
                      this.selectAlbumItem(albumConfig.order + 1);
                    }}
                  >
                    <AddIcon />
                  </Button>
                )}
              </div>
            );
          }
        }
      });
    }
    return (
      <div className="Featured">
        <AlbumSelectDialog
          open={this.state.openItemSelectDialog}
          items={this.state.selectionItems}
          title={this.state.selectionTitle}
          onChange={v => {
            this.state.handleSelectedItem(v);
          }}
          closeDialog={() => {
            this.handleItemsSelectDialogClose();
          }}
        />
        {!this.props.isMobile && (
          <MainLiveVideoTumbnail
            key={data.config.topVideoId}
            admin={session.admin}
            {...this.props}
            mainLiveVideo={data.videos[data.config.topVideoId]}
            onClick={id => this.handleTumbnailClick(id)}
          />
        )}
        {this.props.isMobile && (
          <MainLiveVideoTumbnailMobile
            key={data.config.topVideoId}
            admin={session.admin}
            {...this.props}
            mainLiveVideo={data.videos[data.config.topVideoId]}
            onClick={id => this.handleTumbnailClick(id)}
          />
        )}
        {(session.admin ||
          (data.config &&
            data.config.upcomingLiveEvents &&
            data.config.upcomingLiveEvents.length > 0)) && (
          <AlbumList
            key={"upcomingEvents"}
            upcomingEvents
            albumConfigId={"upcomingEvents"}
            admin={session.admin}
            type={2}
            album={{ name: Contexts.get(Contexts.Context.upcomingEvents) }}
            videos={upcomingVideos}
            handleTumbnailClick={id => this.handleTumbnailClick(id)}
            onDelete={id => this.handleDeleteUpcomingEvents(id)}
            handleShowVideoSelect={id =>
              this.setState({ openVideoSelectDialog: true })
            }
          />
        )}
        {session.admin && (
          <Button
            justIcon
            round
            color="success"
            onClick={() => {
              this.selectAlbumItem(0);
            }}
          >
            <AddIcon />
          </Button>
        )}
        <div className="FlexColumn">{albums}</div>
        <VideoSelectDialog
          open={this.state.openVideoSelectDialog}
          onChange={v => {
            this.handleSelectedVideo(v);
          }}
          closeDialog={() => {
            this.setState({ openVideoSelectDialog: false });
          }}
        />
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <FeaturedList {...props} baseContext={context} ref={ref} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
