import React from "react";
import "../Layouts.css";
import AcceptPayment from "../components/AcceptPayment";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main" style={{height: '100vh'}}>
        <AcceptPayment {...this.props} />
      </div>
    );
  }
}
