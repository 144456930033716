import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { activeUser, createPaymentIntent } from "../../api";
import AddAlert from "@material-ui/icons/Error";
import Done from "@material-ui/icons/Done";
import Snackbar from "../materialComponents/Snackbar/Snackbar.jsx";
import ReactCard from "./ReactCard.js";
import Button from "./../materialComponents/CustomButtons/Button.jsx";
import { CircularProgress } from "@material-ui/core";
import Contexts from "../../contexts";
import { priceUnits } from "../../consts";
import "./CardSectionStyles.css";
import "./style.scss";

// const stripePromise = loadStripe("pk_test_y7mcKxYtxaBKIjAdR8jEtl2f00gANQzIId");
const stripePromise = loadStripe("pk_live_JQHVupXSc8fTvgJ5EpPXmVc500c3JnNHAF");

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    const user = activeUser();
    this.state = {
      name: user ? user.displayName : "",
      postal: "",
      errorMessage: null,
      successMessage: null,
      paymentMethod: null,
      cardNumber: "",
      clientSecret: null,
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.paidEventId) {
      createPaymentIntent(
        this.props.paidEventId,
        this.props.promotionCodeId,
        activeUser().uid,
        this.props.address,
      ).then((result) => {
        if (result.error) {
          this.setState({
            loading: false,
            error: result.error,
          });
        } else {
          this.setState({
            loading: false,
            clientSecret: result.clientSecret,
            error: null,
          });
        }
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements, address } = this.props;
    const { name, postal, clientSecret } = this.state;

    this.setState({loading: true});

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    // const payload = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    //   billing_details: {
    //     name,
    //     address: {
    //       postal_code: postal,
    //     },
    //   },
    // });

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          address: {
            city: address.city,
            country: address.country,
            line1: address.address1,
            line2: null,
            postal_code: address.postCode,
            state: address.state,
          },
          email: address.email,
          name: address.name,
        },
      },
    });

    console.log(payload);

    if (payload.error) {
      console.log("[error]", payload.error);
      this.setState({
        errorMessage: payload.error.message,
        paymentMethod: null,
        successMessage: null,
        loading: false,
      });
    } else {
      console.log("[PaymentMethod]", payload.paymentIntent);
      this.setState({
        paymentMethod: payload.paymentIntent,
        errorMessage: null,
        // successMessage: Contexts.get(Contexts.Context.paymentSucceed),
        // loading: false,
      });

      this.props.finishPayment(clientSecret, payload.paymentIntent);
    }
  };

  getPrice() {
    const { discountedPrice, address } = this.props;
    const vat = !address.taxCode && address.vat ? ((discountedPrice / 100) * address.vat).toFixed(2) : 0;

    return {
      price: discountedPrice,
      vat,
      totalPrice: (+discountedPrice + +vat).toFixed(2),
    };
  }
  render() {
    const { stripe, paidEvent } = this.props;
    console.log("render", this.props);
    const { name, loading, errorMessage, currentFocusedElm } = this.state;
    const price = this.getPrice();

    return (
      <form onSubmit={this.handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Snackbar
            place="bc"
            color="danger"
            icon={AddAlert}
            message={this.state.errorMessage}
            open={this.state.errorMessage && this.state.errorMessage.length > 0}
            closeNotification={() => this.setState({ errorMessage: "" })}
            close
          />
          <Snackbar
            place="bc"
            color="success"
            icon={Done}
            message={this.state.successMessage}
            open={
              this.state.successMessage && this.state.successMessage.length > 0
            }
            closeNotification={() => this.setState({ successMessage: "" })}
            close
          />
          <ReactCard
            cardHolder={name || ""}
            cardMonth="05"
            cardYear="23"
            cardCvv={"###"}
            cardNumber={"#### #### #### ####"}
            currentFocusedElm={currentFocusedElm}
          ></ReactCard>
          <div className="card-form__inner" style={{ paddingTop: 120 }}>
            <h3 style={{ textAlign: "center", marginBottom: 20 }}>Payment</h3>
            <div className="card-input" style={{ marginTop: 20 }}>
              <label className="card-input__label" htmlFor="cardHolder">
                {Contexts.get(Contexts.Context.cardHolder)}
              </label>
              <input
                id="cardHolder"
                className="StripeElement"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                onFocus={() =>
                  this.setState({
                    currentFocusedElm: { current: "card-item__content" },
                  })
                }
              />
            </div>
            <div className="card-input" style={{ marginTop: 20 }}>
              <label className="card-input__label" htmlFor="cardNumber">
                {Contexts.get(Contexts.Context.cardNumber)}
              </label>
              <CardNumberElement
                id="cardNumber"
                onFocus={() =>
                  this.setState({
                    currentFocusedElm: { current: "card-item__number" },
                  })
                }
                options={ELEMENT_OPTIONS}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
            >
              <div className="card-input" style={{ width: "50%" }}>
                <label className="card-input__label" htmlFor="expiry">
                  {Contexts.get(Contexts.Context.expires)}
                </label>
                <CardExpiryElement
                  id="expiry"
                  onChange={(e) => {
                    this.setState({ cardNumber: e.value });
                  }}
                  onFocus={() =>
                    this.setState({
                      currentFocusedElm: { current: "card-item__date" },
                    })
                  }
                  options={ELEMENT_OPTIONS}
                />
              </div>
              <div
                className="card-input"
                style={{ width: "50%", marginLeft: 10 }}
              >
                <label className="card-input__label" htmlFor="cvc">
                  CVC
                </label>
                <CardCvcElement
                  id="cvc"
                  onChange={(e) => {
                    this.setState({ cardNumber: e.value });
                  }}
                  onFocus={() =>
                    this.setState({
                      currentFocusedElm: { current: "card-item__cvv" },
                    })
                  }
                  options={ELEMENT_OPTIONS}
                />
              </div>
            </div>
            <div className="card-input" style={{ marginTop: 30 }}>
              <label className="card-input__label" htmlFor="name">
                {Contexts.get(Contexts.Context.price)}
              </label>
              {!!price.vat && (
                <h3 style={{ marginTop: 0, fontWeight: 400 }}>
                  {price.price + "" + priceUnits[paidEvent.priceUnit] + " + " + price.vat + "" + priceUnits[paidEvent.priceUnit] + " VAT = "+ price.totalPrice + " " + priceUnits[paidEvent.priceUnit]}
                </h3>
              )}
              {!price.vat && (
                <h3 style={{ marginTop: 0, fontWeight: 400 }}>
                {price.totalPrice + " " + priceUnits[paidEvent.priceUnit]}
              </h3>
              )}
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button color="default" onClick={() => this.props.onBack()}>
              {Contexts.get(Contexts.Context.back)}
              </Button>
              <Button disabled={!stripe || loading} type={"submit"} color="info">
                {loading && <CircularProgress size={14} />}
                {!loading && Contexts.get(Contexts.Context.pay)}
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

const App = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm {...props} />
    </Elements>
  );
};

export default App;
