import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { activeUser, getFirebaseData, paths, createNotificationForLiveEvent, addRealtimeNotification } from "../api";
import { toArray, pull } from "lodash";
import { withRouter } from "react-router-dom";
import Contexts from "../contexts";
import * as firebase from "firebase";

class SendLiveMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newMessage: "Canlı yayın birazdan başlıyor.",
    };
  }

  handleSendNotification() {
    if(this.state.newMessage.length > 0) {
      addRealtimeNotification(
        this.props.video.id,
        this.state.newMessage.substring(0,700),
        this.state.actionUrl,        
      );
    }
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSendNotification();
    }
  }

  render() {
  
    return (
      <div
        className="ManageLiveView_Followers"
        style={{ height: this.props.maxHeight }}
      >
        <div className="MainLiveView_Chat_MessageBox">
          <TextField
            placeholder={Contexts.get(Contexts.Context.messagePlaceHolder)}
            value={this.state.newMessage}
            multiline
            style={{maxHeight: 80}}
            inputProps={{
              "aria-label": "message"
            }}
            onKeyPress={e => this.handleKeyPress(e)}
            onChange={e => this.setState({ newMessage: e.target.value })}
          />
          <TextField
            placeholder={Contexts.get(Contexts.Context.actionPlaceHolder)}
            value={this.state.actionUrl}
            style={{maxHeight: 80}}
            inputProps={{
              "aria-label": "action url"
            }}
            onKeyPress={e => this.handleKeyPress(e)}
            onChange={e => this.setState({ actionUrl: e.target.value })}
          />
          <div style={{ textAlign: "right", marginTop: "6px" }}>
            <Button
              color="primary"
              disabled={this.state.newMessage.length < 1 || !activeUser()}
              onClick={() => this.handleSendNotification()}
            >
              {Contexts.get(Contexts.Context.send)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <SendLiveMessage {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
