import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { activeUser, getFirebaseData, paths, createNotificationForLiveEvent } from "../api";
import { toArray, pull } from "lodash";
import { withRouter } from "react-router-dom";
import Contexts from "../contexts";
import * as firebase from "firebase";

class VideoFollowers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      followers: [],
      newMessage: "Canlı yayın başlıyor. Hemen izlemeye başlayabilirsiniz.",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.video !== nextProps.video) {
      this.loadFollowers();
    }
  }

  componentDidMount() {
    this.loadFollowers();
  }

  loadFollowers() {
    if (this.props.video)
    var commentsRef = firebase
      .database()
      .ref(paths.videoFollowers + "/" + this.props.video.id);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (that.state.followers.indexOf(data.key) < 0) {
        if (data.val().follow) {
          that.state.followers.push(data.key);
          that.setState(that.state.followers);
        }
      }
    });
    commentsRef.on("child_changed", function(data) {
      if (that.state.followers.indexOf(data.key) < 0) {
        if (data.val().follow) {
          that.state.followers.push(data.key);
          that.setState(that.state.followers);
        }
      } else if (!data.val().follow) {
        pull(that.state.followers, data.key);
        that.setState(that.state.followers);
      }
    });
    commentsRef.on("child_removed", function(data) {
      pull(that.state.followers, data.key);
      that.setState(that.state.followers);
    });
  }
  handleSendNotification() {
    if(this.state.newMessage.length > 0) {
      createNotificationForLiveEvent(
        this.props.video.id,
        this.state.newMessage.substring(0,500)
        
      );
    }
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleSendNotification();
    }
  }
  renderFollower(user) {
      return (<span className="MainLiveView_Chat_MessageItem">
        {user.name}
      </span>)
  }
  render() {
    const { followers } = this.state;
    const {users} = this.props;
    const followerItems = [];
    if (followers.length > 0 && users) {
      followers.forEach(follower => {
        const user = users[follower];
        if(user) {
          followerItems.push(this.renderFollower(user));
        }
      });
    }
    return (
      <div
        className="ManageLiveView_Followers"
        style={{ height: this.props.maxHeight }}
      >
        <div className="h2" style={{ padding: "6px" }}>
          {Contexts.get(Contexts.Context.videoFollowers)} ({followers.length})
        </div>
        <div
          className="ManageLiveView_Followers_Flow"
          ref={r => (this.messageFlow = r)}
        >
          {followerItems}
          {followerItems.length === 0 && (
            <span>{Contexts.get(Contexts.Context.noMessage)}</span>
          )}
        </div>
        <div className="MainLiveView_Chat_MessageBox">
          <TextField
            placeholder={Contexts.get(Contexts.Context.messagePlaceHolder)}
            value={this.state.newMessage}
            multiline
            style={{maxHeight: 80}}
            inputProps={{
              "aria-label": "description"
            }}
            onKeyPress={e => this.handleKeyPress(e)}
            onChange={e => this.setState({ newMessage: e.target.value })}
          />
          <div style={{ textAlign: "right", marginTop: "6px" }}>
            <Button
              color="primary"
              disabled={this.state.newMessage.length < 1 || !activeUser()}
              onClick={() => this.handleSendNotification()}
            >
              {Contexts.get(Contexts.Context.sendNotification)}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <VideoFollowers {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
