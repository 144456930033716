import * as firebase from "firebase";
import cookie from "react-cookies";
import ReactGA from "react-ga";
import { pull } from "lodash";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";

const moment = require("moment-timezone");
// const apiURL = "http://localhost:8081/";
const apiURL = "https://cerrahi-tv.herokuapp.com/";

export const paths = {
  localData: "main",
  albums: "main/albums/",
  videos: "main/videos/",
  albumVideos: "main/albumVideos/",
  videoAlbums: "main/videoAlbums/",
  config: "main/config/",
  liveEvents: "main/liveEvents/",
  userFollows: "main/userFollows/",
  videoFollowers: "main/videoFollowers/",
  notifications: "main/notifications/",
  user: "main/users/",
  users: "main/users/",
  videoUploads: "main/videoUploads/",
  userChatMessages: "main/userChatMessages/",
  videoChatMessages: "main/videoChatMessages/",
  videoTags: "main/videoTags/",
  tagVideos: "main/tagVideos/",
  albumTags: "main/albumTags/",
  tagAlbums: "main/tagAlbums/",
  tags: "main/tags/",
  tagUsers: "main/tagUsers",
  videoWatchers: "main/videoWatchers/",
  specializations: "main/specializations",
  professions: "main/professions",
  interestGroups: "main/interestGroups",
  interests: "main/interests",
  paidEvents: "main/paidEvents",
  userVideoWatches: "main/userVideoWatches/",
};

export const lastUserAgreementVersion = "userAgrrement_v2";

export function activeUser() {
  const userCookie = cookie.load("user");
  if (userCookie) return userCookie;
  return firebase.auth().currentUser;
}

export function setActiveUser(user) {
  const expires = moment().add(2, "year").toDate();
  cookie.save("loggin", true, { path: "/", expires });
  cookie.save("user", user, { path: "/", expires });
  if (user && user.uid) {
    ReactGA.set({ userId: user.uid });
  }
  if (window.$crisp) {
    window.$crisp.push(["set", "user:email", user.email]);
    window.$crisp.push(["set", "user:nickname", user.displayName]);
  }
  Sentry.configureScope((scope) => {
    scope.setUser({ uid: user.uid, name: user.displayName, email: user.email });
  });
}


export function getLanguage() {
  const userCookie = cookie.load("language");
  var userLanguage = window.navigator.userLanguage || window.navigator.language;
  const language = userLanguage === "tr-TR" ? "tr" : "en";
  return userCookie || language;
}

export function setLanguage(language) {
  const expires = moment().add(2, "year").toDate();
  cookie.save("language", language, { path: "/", expires });
}

export function getUserAggrementStatus() {
  const userCookie = cookie.load("user_aggrement");
  if (userCookie && userCookie === lastUserAgreementVersion) {
    return true;
  }
  return false;
}

export function setUserAggrementStatus() {
  const expires = moment().add(2, "year").toDate();
  cookie.save("user_aggrement", lastUserAgreementVersion, {
    path: "/",
    expires,
  });
  const user = activeUser();
  if (user) {
    setFirebaseData(
      paths.user + activeUser().uid + "/user_aggrement/",
      lastUserAgreementVersion
    );
  }
  return false;
}

export function setUserExtData(
  professionId,
  specialityId,
  preferedLanguage,
  nationality,
  nationalID
) {
  const user = activeUser();
  if (user) {
    if (specialityId) {
      setFirebaseData(
        paths.user + activeUser().uid + "/specialityId/",
        specialityId
      );
    }
    if (professionId) {
      setFirebaseData(
        paths.user + activeUser().uid + "/professionId/",
        professionId
      );
    }
    if (preferedLanguage) {
      setFirebaseData(
        paths.user + activeUser().uid + "/preferedLanguage/",
        preferedLanguage
      );
    }
    if (nationality) {
      setFirebaseData(
        paths.user + activeUser().uid + "/nationality/",
        nationality
      );
    }
    if (nationalID) {
      new Promise(async () => {
        const encryptedNationalId = await getEncrypt(nationalID);
        setFirebaseData(
          paths.user + activeUser().uid + "/nationalID/",
          encryptedNationalId
        );
      });
    }
  }
  return false;
}

export function setUserAggrementStatusOnCookie() {
  const expires = moment().add(2, "year").toDate();
  cookie.save("user_aggrement", lastUserAgreementVersion, {
    path: "/",
    expires,
  });
}

export function isUserEmailValidated() {
  return true;
  const currentUser = activeUser();
  if (!currentUser) return false;
  return true;
  return (
    (currentUser &&
      currentUser.emailVerified &&
      currentUser.providerData &&
      currentUser.providerData[0].providerId === "password") ||
    currentUser.providerData[0].providerId !== "password"
  );
}

export function init_messaging() {
  requestNotificationPermission();
}
export function requestNotificationPermission() {
  firebase
    .messaging()
    .requestPermission()
    .then(function () {
      firebase
        .messaging()
        .getToken()
        .then(function (currentToken) {
          if (currentToken) {
            setFirebaseData(
              paths.user + activeUser().uid + "/gcmToken/",
              currentToken
            );
          } else {
            console.log(
              "No Instance ID token available. Request permission to generate one."
            );
          }
        })
        .catch(function (err) {
          console.log("An error occurred while retrieving token. ", err);
        });
    })
    .catch(function (err) {
      console.log("Unable to get permission to notify.", err);
    });
}
export function getFeaturedList() {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("main/featuredList")
      .on("value", (snapshot) => {
        const featuredList = snapshot.val();
        resolve(featuredList);
      });
  });
}

export function getAllData() {
  return new Promise((resolve) => {
    fetch(apiURL + "getMainData", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then((result) => {
          if (result) {
            if (result && result.data) {
              resolve(result.data);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function getLastUpdateDate() {
  return new Promise((resolve) => {
    fetch(apiURL + "getLastUpdateDate", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        response.json().then((result) => {
          if (result) {
            if (result && result.data) {
              resolve(result.data);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function copyVideoMessages(orgVideoId, targetVideoId) {
  return new Promise((resolve) => {
    fetch(
      apiURL +
        "copyVideoMessages?fromVideoId=" +
        orgVideoId +
        "&toVideoId=" +
        targetVideoId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        response.json().then((result) => {
          if (result) {
            resolve(result);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

// export function getAllData() {
//   var promises = [];
//   promises.push(getFirebaseData(paths.albums));
//   promises.push(getFirebaseData(paths.videos));
//   promises.push(getFirebaseData(paths.albumVideos));
//   promises.push(getFirebaseData(paths.config));
//   promises.push(getFirebaseData(paths.liveEvents));
//   promises.push(getFirebaseData(paths.videoAlbums));
//   promises.push(getFirebaseData(paths.videoTags));
//   promises.push(getFirebaseData(paths.tagVideos));
//   promises.push(getFirebaseData(paths.albumTags));
//   promises.push(getFirebaseData(paths.tagAlbums));
//   promises.push(getFirebaseData(paths.tags));

//   return new Promise(resolve => {
//     Promise.all(promises).then(results => {
//       const data = {
//         albums: results[0],
//         videos: results[1],
//         albumVideos: results[2],
//         config: results[3],
//         liveEvents: results[4],
//         videoAlbums: results[5],
//         videoTags: results[6],
//         tagVideos: results[7],
//         albumTags: results[8],
//         tagAlbums: results[9],
//         tags: results[10]
//       };
//       resolve(data);
//     });
//   });
// }
export function getUsers() {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.users).then((users) => {
      resolve(users);
    });
  });
}

export function getUploadedVideos() {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.videoUploads).then((videoUploads) => {
      resolve(videoUploads);
    });
  });
}

export function getAllUserData() {
  return new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          setLocalData("session_token", { tokenId: idToken });
          fetch(apiURL + "getAllUserData?uid=" + activeUser().uid, {
            method: "GET",
            headers: {
              Accept: "application/json",
              AuthToken: idToken,
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              response.json().then((result) => {
                if (result) {
                  if (result && result.data) {
                    resolve(result.data);
                  }
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });
    } else {
      reject();
    }
  });
}

export function getUserPurchases() {
  return new Promise((resolve, reject) => {
    const token = getLocalData("session_token");
    if (token) {
      fetch(apiURL + "getUserPurchases?uid=" + activeUser().uid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          AuthToken: token.tokenId,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response.json().then((result) => {
            if (result) {
              if (result && result.data) {
                resolve(result.data);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      reject();
    }
  });
}

export function getUserVideoWatches() {
  return new Promise((resolve, reject) => {
    const token = getLocalData("session_token");
    if (token) {
      fetch(apiURL + "getUserVideoWatches?uid=" + activeUser().uid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          AuthToken: token.tokenId,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response.json().then((result) => {
            if (result) {
              if (result && result.data) {
                resolve(result.data);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      reject();
    }
  });
}

export function getEncrypt(value) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "encrypt", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        value: value,
      }),
    })
      .then((response) => {
        response.json().then((result) => {
          if (result) {
            if (result && result.value) {
              resolve(result.value);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function getDescrypt(values) {
  return new Promise((resolve, reject) => {
    const token = getLocalData("session_token");
    if (token) {
      fetch(apiURL + "descrypt", {
        method: "POST",
        headers: {
          Accept: "application/json",
          AuthToken: token.tokenId,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          values,
        }),
      })
        .then((response) => {
          response.json().then((result) => {
            if (result) {
              if (result && result.data) {
                resolve(result.data);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      reject();
    }
  });
}

export function getEventVideo(videoId) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiURL + "getEventVideo", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            deviceId: getDeviceId(),
            eventId: videoId,
            uid: activeUser().uid,
          },
        }),
      })
        .then((response) => {
          if (response) {
            response.json().then((data) => {
              if (data) {
                resolve(data);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  });
}

// export function getAllUserData() {
//   var promises = [];
//   if (activeUser()) {
//     promises.push(getFirebaseData(paths.userFollows + activeUser().uid));
//     promises.push(getFirebaseData(paths.user + activeUser().uid));
//   }

//   return new Promise(resolve => {
//     Promise.all(promises).then(results => {
//       const data = {
//         userFollows: results[0],
//         userData: results[1]
//       };
//       resolve(data);
//     });
//   });
// }

export function getDeviceId() {
  let localDeviceId = getLocalData("deviceId");
  if (localDeviceId) return localDeviceId;

  localDeviceId = uuidv4();
  setLocalData("deviceId", localDeviceId);
  return localDeviceId;
}

export function getLocalData(path) {
  return JSON.parse(localStorage.getItem(path));
}

export function setLocalData(path, data) {
  return localStorage.setItem(path, JSON.stringify(data));
}

export function getFirebaseData(path) {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(path)
      .on("value", (snapshot) => {
        const broadcast = snapshot.val();
        resolve(broadcast);
      });
  });
}

export function setFirebaseData(path, data) {
  return new Promise((resolve, reject) => {
    var updates = {};
    updates[path] = data;
    resolve(firebase.database().ref().update(updates));
  });
}
export function addNewBroadcast(data) {
  return new Promise((resolve, reject) => {
    var newPostKey = firebase.database().ref().child("main/broadcasts").push()
      .key;

    var updates = {};
    data.id = newPostKey;
    updates["/main/broadcasts/" + newPostKey] = data;

    resolve(firebase.database().ref().update(updates));
  });
}
export function addRemoveItemToFollowList(videoId, follow) {
  return new Promise((resolve, reject) => {
    var updates = {};

    updates[paths.userFollows + activeUser().uid + "/" + videoId] = {
      follow: follow,
    };
    updates[paths.videoFollowers + videoId + "/" + activeUser().uid] = {
      follow: follow,
    };

    resolve(firebase.database().ref().update(updates));
  });
}

export function addTagToVideo(tagId, videoId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    getFirebaseData(paths.videoTags + videoId).then((videoTags) => {
      if (!videoTags) videoTags = [];
      if (!videoTags[tagId]) {
        videoTags.push(tagId);
        updates[paths.videoTags + videoId + "/"] = videoTags;
        firebase.database().ref().update(updates);
      }
    });
    getFirebaseData(paths.tagVideos + tagId).then((tagVideos) => {
      if (!tagVideos) tagVideos = [];
      if (!tagVideos[videoId]) {
        tagVideos.push(videoId);
        updates[paths.tagVideos + tagId + "/"] = tagVideos;
        firebase.database().ref().update(updates);
      }
    });
  });
}

export function removeTagToVideo(tagId, videoId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    getFirebaseData(paths.videoTags + videoId).then((videoTags) => {
      if (!videoTags) videoTags = [];
      if (videoTags.indexOf(tagId) > -1) {
        pull(videoTags, tagId);
        updates[paths.videoTags + videoId + "/"] = videoTags;
        firebase.database().ref().update(updates);
      }
    });
    getFirebaseData(paths.tagVideos + tagId).then((tagVideos) => {
      if (!tagVideos) tagVideos = [];
      if (tagVideos.indexOf(videoId) > -1) {
        pull(tagVideos, videoId);
        updates[paths.tagVideos + tagId + "/"] = tagVideos;
        firebase.database().ref().update(updates);
      }
    });
  });
}

export function addTagToAlbum(tagId, albumId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    getFirebaseData(paths.albumTags + albumId).then((albumTags) => {
      if (!albumTags) albumTags = [];
      if (!albumTags[tagId]) {
        albumTags.push(tagId);
        updates[paths.albumTags + albumId + "/"] = albumTags;
        firebase.database().ref().update(updates);
      }
    });
    getFirebaseData(paths.tagAlbums + tagId).then((tagAlbums) => {
      if (!tagAlbums) tagAlbums = [];
      if (!tagAlbums[albumId]) {
        tagAlbums.push(albumId);
        updates[paths.tagAlbums + tagId + "/"] = tagAlbums;
        firebase.database().ref().update(updates);
      }
    });
  });
}

export function removeTagToAlbum(tagId, albumId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    getFirebaseData(paths.albumTags + albumId).then((albumTags) => {
      if (!albumTags) albumTags = [];
      if (albumTags.indexOf(tagId) > -1) {
        pull(albumTags, tagId);
        updates[paths.albumTags + albumId + "/"] = albumTags;
        firebase.database().ref().update(updates);
      }
    });
    getFirebaseData(paths.tagAlbums + tagId).then((tagAlbums) => {
      if (!tagAlbums) tagAlbums = [];
      if (tagAlbums.indexOf(albumId) > -1) {
        pull(tagAlbums, albumId);
        updates[paths.tagAlbums + tagId + "/"] = tagAlbums;
        firebase.database().ref().update(updates);
      }
    });
  });
}

export function addTag(tag) {
  return new Promise((resolve, reject) => {
    var newKey = firebase.database().ref().child(paths.tags).push().key;
    var updates = {};
    const data = {
      id: newKey,
      name: tag,
      date: moment().tz("Europe/Istanbul").unix(),
    };
    updates[paths.tags + "/" + newKey] = data;
    firebase.database().ref().update(updates);
    resolve(data);
  });
}

export function deleteTag(tagId) {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.tags).then((tags) => {
      delete tags[tagId];
      var updates = {};
      updates[paths.tags] = tags;
      firebase.database().ref().update(updates);
      resolve();
    });
  });
}

export function getChatMessages(videoId) {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.videoChatMessages + "/" + videoId).then(
      (messages) => {
        resolve(messages);
      }
    );
  });
}
export function addChatMessage(message, videoId, parentId, ownderUserId) {
  return new Promise((resolve, reject) => {
    const user = activeUser();
    var newKey = firebase
      .database()
      .ref()
      .child(paths.videoChatMessages + "/" + videoId)
      .push().key;
    var updates = {};
    const data = {
      id: newKey,
      videoId: videoId,
      userId: user.uid,
      userName: user.displayName,
      message: message,
      parentId: parentId,
      ownerUserId: ownderUserId,
      date: moment().tz("Europe/Istanbul").unix(),
    };
    updates[paths.videoChatMessages + "/" + videoId + "/" + newKey] = data;
    firebase.database().ref().update(updates);
    resolve(data);
  });
}
export function approveChatMessage(user, videoId, messageId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    updates[
      paths.videoChatMessages +
        "/" +
        videoId +
        "/" +
        messageId +
        "/" +
        "approvedBy"
    ] = user.uid;
    updates[
      paths.videoChatMessages +
        "/" +
        videoId +
        "/" +
        messageId +
        "/" +
        "approveDate"
    ] = moment().tz("Europe/Istanbul").unix();

    resolve(firebase.database().ref().update(updates));
  });
}

export function rejectChatMessage(user, videoId, messageId) {
  return new Promise((resolve, reject) => {
    var updates = {};
    updates[
      paths.videoChatMessages +
        "/" +
        videoId +
        "/" +
        messageId +
        "/" +
        "rejecteddBy"
    ] = user.uid;
    updates[
      paths.videoChatMessages +
        "/" +
        videoId +
        "/" +
        messageId +
        "/" +
        "rejectDate"
    ] = moment().tz("Europe/Istanbul").unix();

    resolve(firebase.database().ref().update(updates));
  });
}

export function editBroadcast(data) {
  return new Promise((resolve, reject) => {
    var updates = {};
    updates["/main/broadcasts/" + data.id] = data;

    resolve(firebase.database().ref().update(updates));
  });
}
export function addNewFile(data) {
  return new Promise((resolve, reject) => {
    var newFileKey = firebase.database().ref().child("main/videoUploads").push()
      .key;

    var updates = {};
    data.id = newFileKey;
    updates["/main/videoUploads/" + newFileKey] = data;
    updates[
      "/main/userVideoUploads/" +
        activeUser().uid +
        "/uploadedVideos/" +
        newFileKey
    ] = data;
    firebase.database().ref().update(updates);
    resolve(newFileKey);
  });
}

export function addVideoWathcer(videoId, uid) {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.videoWatchers + "/" + videoId + "/" + uid).then(
      (videoWatcher) => {
        if (videoWatcher) {
          videoWatcher.lastUpdate = moment().unix();
        } else {
          videoWatcher = { start: moment().unix() };
        }
        var updates = {};
        updates[paths.videoWatchers + "/" + videoId + "/" + uid] = videoWatcher;
        firebase.database().ref().update(updates);
        getFirebaseData(paths.userVideoWatches + "/" + uid + "/" + videoId).then(
          (userVideoWatches) => {
            if (userVideoWatches) {
              userVideoWatches.lastUpdate = moment().unix();
            } else {
              userVideoWatches = { start: moment().unix() };
            }
            var updates = {};
            updates[paths.userVideoWatches + "/" + uid + "/" + videoId] = userVideoWatches;
            firebase.database().ref().update(updates);
            resolve();
          }
        );
      }
    );
  });
}

export function createNotificationForLiveEvent(videoId, message) {
  new Promise((resolve, reject) => {
    getFirebaseData(paths.videos + videoId).then((video) => {
      getFirebaseData(paths.videoFollowers + videoId).then((followers) => {
        const title = video.name;
        const users = [];
        for (var key in followers) {
          users.push(key);
        }
        resolve(createUserNotification(title, message, videoId, users));
      });
    });
  });
}

export function createUserNotification(title, message, itemId, users) {
  return new Promise((resolve, reject) => {
    var newKey = firebase.database().ref().child(paths.notifications).push()
      .key;
    var updates = {};
    const data = {
      id: newKey,
      itemId: itemId,
      title: title,
      message: message,
      action: "http://cerrahi.tv/watchLive?event=" + itemId,
      users: users,
    };
    updates[paths.notifications + newKey] = data;
    users.forEach((user) => {
      updates[paths.user + user + "/notifications/" + newKey] = {
        type: "liveEvent",
        itemId: itemId,
        title: title,
        message: message,
      };
    });
    firebase.database().ref().update(updates);
    resolve(newKey);
  });
}

export function loginByEmail(email, password, CB) {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function (error) {
      CB &&
        CB({
          Error: true,
          Message: "Email or password is incorrect.",
        });
    })
    .then((User) => {
      if (User) {
        CB &&
          CB({
            Error: false,
            User: User,
          });
      }
    });
}
export function logOut() {
  return new Promise((resolve, reject) => {
    cookie.remove("user", { path: "/" });
    firebase
      .auth()
      .signOut()
      .then(() => resolve());
  });
}

export function loginByFacebook(CB) {
  return new Promise((resolve, reject) => {
    var provider = new firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({
      display: "popup",
    });
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // [START_EXCLUDE]
        var updates = {};

        getFirebaseData("/main/users/" + user.uid).then((userDb) => {
          var updates = {};
          if (!userDb) {
            updates["/main/users/" + user.uid + "/id"] = user.uid;
            updates["/main/users/" + user.uid + "/provider"] = "facebook";
            updates["/main/users/" + user.uid + "/email"] = user.email;
            updates["/main/users/" + user.uid + "/name"] = user.displayName;
            updates["/main/users/" + user.uid + "/token"] = token;
            updates["/main/users/" + user.uid + "/creationTime"] = moment()
              .tz("Europe/Istanbul")
              .unix();
            updates[
              "/main/users/" + user.uid + "/user_aggrement"
            ] = lastUserAgreementVersion;

            firebase.database().ref().update(updates);
          }
        });

        firebase
          .auth()
          .currentUser.updateProfile({
            displayName: user,
          })
          .then(
            function () {},
            function (error) {}
          );

        resolve(user.uid);
        CB &&
          CB({
            Error: false,
            User: user,
          });
        // [END_EXCLUDE]
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        // [START_EXCLUDE]
        if (errorCode === "auth/account-exists-with-different-credential") {
          alert(
            "You have already signed up with a different auth provider for that email."
          );
          // If you are using multiple auth providers on your app you should handle linking
          // the user's accounts here.
        } else {
          console.error(error);
        }
        // [END_EXCLUDE]
      });
  });
}
export function resetPassword(email, CB) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        resolve();
        CB &&
          CB({
            Error: false,
          });
      })
      .catch(function (error) {
        var errorMessage = error.message;

        resolve(error.code);
        CB &&
          CB({
            Error: true,
            ErrorMessage: errorMessage,
          });
        return error;
      });
  });
}
export function loginByGoogle(isMobile, CB) {
  return new Promise((resolve, reject) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    // if (isMobile) {
    //   firebase.auth().signInWithRedirect(provider);
    // } else {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // [START_EXCLUDE]

        getFirebaseData("/main/users/" + user.uid).then((userDb) => {
          var updates = {};
          if (!userDb) {
            updates["/main/users/" + user.uid + "/id"] = user.uid;
            updates["/main/users/" + user.uid + "/provider"] = "google";
            updates["/main/users/" + user.uid + "/email"] = user.email;
            updates["/main/users/" + user.uid + "/name"] = user.displayName;
            updates["/main/users/" + user.uid + "/token"] = token;
            updates["/main/users/" + user.uid + "/creationTime"] = moment()
              .tz("Europe/Istanbul")
              .unix();
            updates[
              "/main/users/" + user.uid + "/user_aggrement"
            ] = lastUserAgreementVersion;

            firebase.database().ref().update(updates);
          }
        });

        firebase
          .auth()
          .currentUser.updateProfile({
            displayName: user,
          })
          .then(
            function () {},
            function (error) {}
          );

        resolve(user.uid);
        CB &&
          CB({
            Error: false,
            User: user,
          });
        // [END_EXCLUDE]
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        if (errorCode === "auth/account-exists-with-different-credential") {
          alert(
            "You have already signed up with a different auth provider for that email."
          );
          // If you are using multiple auth providers on your app you should handle linking
          // the user's accounts here.
        } else {
          console.error(error);
        }
        // [END_EXCLUDE]
      });
    //}
  });
}

export function registerUserByEmail(
  email,
  password,
  user,
  professionId,
  specialityId,
  preferedLanguage,
  nationality,
  nationalID,
  CB
) {
  return new Promise(async (resolve, reject) => {
    const encryptedNationalId = nationalID ? await getEncrypt(nationalID) : "";
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .catch(function (error) {
        CB &&
          CB({
            Error: true,
            Message: error.message,
          });
        reject(error);
      })
      .then((User) => {
        if (User) {
          var updates = {};
          let data = {
            id: User.user.uid,
            provider: "mail",
            email: email,
            name: user,
            professionId,
            specialityId,
            preferedLanguage,
            creationTime: moment().tz("Europe/Istanbul").unix(),
            user_aggrement: lastUserAgreementVersion,
          };
          if (nationality) {
            data.nationality = nationality;
          }
          if (encryptedNationalId) {
            data.nationalID = encryptedNationalId;
          }
          updates[
            "/main/users/" + User.user.uid
          ] = data;
          firebase.database().ref().update(updates);

          firebase
            .auth()
            .currentUser.updateProfile({
              displayName: user,
            })
            .then(
              function () {},
              function (error) {}
            );
          sendEmailValidation(email);

          resolve(User.user.uid);
          CB &&
            CB({
              Error: false,
              User: User,
            });
        }
      });
  });
}

export function unsubscribe(uid, email) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "unsubscribe?uid=" + uid + "&email=" + email, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function getPaidEvent(paidEventId) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "getPaidEvent?paidEventId=" + paidEventId, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function applyPromotionCode(paidEventId, promotionCode, uid) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiURL + "applyPromotionCode", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            paidEventId,
            promotionCode,
            uid,
          },
        }),
      })
        .then((response) => {
          if (response) {
            response.json().then((data) => {
              if (data) {
                resolve(data);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  });
}

export function createPaymentIntent(
  paidEventId,
  promotionCodeId,
  uid,
  address
) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "createPaymentIntent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          paidEventId,
          promotionCodeId,
          uid,
          address,
        },
      }),
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function finishPayment(
  paidEventId,
  promotionCodeId,
  uid,
  clientSecret,
  paymentIntent,
  address
) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "finishPayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          paidEventId,
          promotionCodeId,
          uid,
          clientSecret,
          paymentIntent,
          address,
        },
      }),
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function verifyEmail(email, uid, requestId) {
  return new Promise((resolve, reject) => {
    fetch(
      apiURL +
        "veriyEmail?email=" +
        email +
        "&uid=" +
        uid +
        "&requestId=" +
        requestId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function sendEmailValidation(email) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "sendEmailValidationLink?email=" + email, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function setUserTags(uid, userTags) {
  return new Promise((resolve, reject) => {
    getFirebaseData(paths.tagUsers + "/").then((result) => {
      const tagUsers = result || {};
      Object.keys(tagUsers).forEach((tagId) => {
        const users = tagUsers[tagId] || [];
        if (users.indexOf(uid) > -1 && userTags.indexOf(tagId) < 0) {
          pull(users, uid);
          const updates = {};
          updates[paths.tagUsers + "/" + tagId] = users;

          firebase.database().ref().update(updates);
        }
      });

      getFirebaseData(paths.users + "/" + uid + "/userTags").then(
        (resultUserTags) => {
          const userTagsDb = resultUserTags || [];
          userTagsDb.forEach((userTagDb) => {
            if (userTags.indexOf(userTagDb) < 0) {
              pull(userTags, userTagDb);
            }
          });

          userTags.forEach((userTag) => {
            if (userTagsDb.indexOf(userTag) < 0) {
              userTagsDb.push(userTag);
            }
            getFirebaseData(paths.tagUsers + "/" + userTag).then(
              (resultUsers) => {
                const users = resultUsers || [];
                if (users.indexOf(uid) < 0) {
                  users.push(uid);
                  const updates = {};
                  updates[paths.tagUsers + "/" + userTag] = users;

                  firebase.database().ref().update(updates);
                }
              }
            );
          });
          const updateUser = {};
          updateUser[paths.users + "/" + uid + "/userTags"] = userTagsDb;

          firebase.database().ref().update(updateUser);
        }
      );
    });

    resolve(userTags);
  });
}

export function addRealtimeNotification(videoId, message, action) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "addRealtimeNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          videoId,
          message,
          action,
        },
      }),
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}

export function setSeenRealtimeNotification(videoId, notificationId, uid) {
  return new Promise((resolve, reject) => {
    fetch(apiURL + "setSeenRealtimeNotification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          videoId,
          notificationId,
          uid,
        },
      }),
    })
      .then((response) => {
        if (response) {
          response.json().then((data) => {
            if (data) {
              resolve(data);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
}
