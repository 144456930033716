import React from "react";
import "../Components.css";
import "../react-autosuggest.css";
import { toArray } from "lodash";
import queryString from "query-string";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import BackIcon from "@material-ui/icons/ArrowBack";
import Contexts from "../contexts";

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      showSearch: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  componentDidMount() {
    const queryValues = queryString.parse(this.props.history.location.search);
    if (queryValues.search) {
      this.setState({ value: queryValues.search });
    }
  }
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const { data } = this.props.baseContext.baseContext;

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp(escapedValue, "i");
    const suggestions = [];
    toArray(data.videos)
      .filter(video => {
        if (data.videoAlbums[video.id]) {
          return !data.videoAlbums[video.id].find(
            albumId => data.hiddenAlbums.indexOf(albumId) > -1
          );
        }
        return true;
      })
      .forEach(video => {
        const suggestionValue = video.name;
        if (regex.test(suggestionValue)) {
          suggestions.push({ type: "video", ...video });
        }
      });
    toArray(data.albums).forEach(album => {
      if (data.hiddenAlbums.indexOf(album.id) < 0) {
        const suggestionValue = album.name;
        if (regex.test(suggestionValue)) {
          suggestions.push({ type: "album", ...album });
        }
      }
    });
    toArray(data.tags).forEach(tags => {
      const suggestionValue = tags.name;
      if (regex.test(suggestionValue)) {
        suggestions.push({ type: "tag", ...tags });
      }
    });
    return suggestions;
  }

  getSuggestionValue(suggestion) {
    return suggestion ? `${suggestion.name}` : "";
  }

  renderSuggestion(suggestion, { query }) {
    const suggestionText = `${suggestion.name}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return (
      <span className={"suggestion-content " + suggestion.twitter}>
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? "highlight" : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
      </span>
    );
  }

  handleChange(event, { newValue, method }) {
    if (method !== "click") {
      if (typeof newValue === "function") this.setState({value : newValue()});
      else this.setState({value : newValue});
    } else {
      console.log(newValue());
    }
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.onSearch(this.state.value);
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({suggestions: this.getSuggestions(value)});
  };

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []});
  };
  handleSuggestionClick(e, data) {
    this.props.onSearch(
      data.suggestion.name,
      data.suggestion.type,
      data.suggestion.id
    );
  }
  handleClickSearchIcon() {
    const { showSearch, value } = this.state;
    if (!showSearch) {
      this.setState({ showSearch: true });
    } else if (value) {
      this.props.onSearch(value);
    }
  }
  render() {
    const { value, showSearch } = this.state;
    const { isMobile, user } = this.props;
    const inputProps = {
      placeholder:  Contexts.get(Contexts.Context.search),
      value,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyPress
    };
    let paddingRight = isMobile ? 120 : 140;
    if (!user) {
      paddingRight = isMobile ? 100 : 340;
    }
    return (
      <div
        className={showSearch ? "SearchBar SearchBar__active" : "SearchBar"}
        style={{ paddingRight }}
      >
        {isMobile && showSearch && (
          <IconButton
            onClick={() => {
              this.setState({ showSearch: !this.state.showSearch });
            }}
          >
            <BackIcon />
          </IconButton>
        )}
        {(!isMobile || (isMobile && showSearch)) && (
          <div className="SearchInput">
            <Autosuggest
              suggestions={this.state.suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.handleSuggestionClick}
              getSuggestionValue={() => this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
            />
          </div>
        )}
        <IconButton
          onClick={() => {
            this.handleClickSearchIcon();
          }}
        >
          <SearchIcon />
        </IconButton>
      </div>
    );
  }
}

export default SearchInput;
