import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Contexts from "../contexts";
import { withRouter } from "react-router-dom";

class PaymentRequiredDialog extends React.Component {
  handleClose(value) {
    if (this.props.closeDialog) {
      this.props.closeDialog();
    }
  }

  render() {
    const {paidEvent} = this.props;
    if (!paidEvent) return null;
    return (
      <div>
        <Dialog
          open={this.props.open}
          maxWidth={"xs"}
          fullWidth={false}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {Contexts.get(Contexts.Context.paymentRequiredTitle)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span>
              <strong>{paidEvent.name}</strong>
              {Contexts.get(Contexts.Context.paymentRequiredDetail)}
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              {Contexts.get(Contexts.Context.cancel)}
            </Button>
            <Button
              onClick={() => {
                this.props.history.push("/acceptPayment?paidEventId="+paidEvent.id);
              }}
              color="primary"
              autoFocus
            >
              {Contexts.get(Contexts.Context.buyIt)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(PaymentRequiredDialog);
