import React, { Component } from "react";
import Button from "components/materialComponents/CustomButtons/Button.jsx";
import { BaseContext } from "../baseContext";
import Contexts from "../contexts";
import Menu from "./Menu";
import LanguageMenu from './LanguageMenu';
import SearchInput from "./SearchInput";

const registerNavLink = {
  color: "#fff",
  top: "3px",
  position: "relative",
  padding: "8px 24px",
  fontWeight: "400",
  fontSize: "16px",
  textTransform: "uppercase",
  lineHeight: "18px",
  textDecoration: "none",
  margin: "0px",
  display: "inline-flex",
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isMobile, user, history, baseContext, isAdmin } = this.props;
    const { data } = this.props.baseContext.baseContext;
    return (
      <header className="App-header">
        <a href="/" className="App-header-logo">
          <img
            height={!isMobile ? (data.domain === "surgical.tv" ? 48 : 36) : (data.domain === "surgical.tv" ? 30 : 24)}
            src={
              `/assets/images/${data.domain === "surgical.tv"
                ? "surgicalTvLogo.png"
                : "cerrahiTvLogo.png"}`
            }
            alt=""
          />
        </a>

        <SearchInput
          onSearch={(search, type, id) => {
            this.props.onSearch(search, type, id);
          }}
          user={user}
          history={history}
          isMobile={isMobile}
          baseContext={baseContext}
        />
        <div className="App-header-right">
          {!user && (
            <Button
              size={isMobile ? "sm" : ""}
              style={{ marginRight: isMobile ? 6 : 10}}
              className={registerNavLink}
              onClick={() => {
                this.props.history.push("/signIn");
              }}
              color="info"
              round
            >
              {Contexts.get(Contexts.Context.login)}
            </Button>
          )}
          {!user && (
            <Button
              size={isMobile ? "sm" : ""}
              style={{ marginRight: isMobile ? 0 : 10}}
              className={registerNavLink}
              onClick={() => {
                this.props.history.push("/signUp");
              }}
              color="info"
              round
            >
              {Contexts.get(Contexts.Context.register)}
            </Button>
          )}
          <LanguageMenu
              user={user}
              history={history}
              isAdmin={isAdmin}
              baseContext={baseContext}
              isMobile={isMobile}
            />
          {user && (
            <Menu
              user={user}
              history={history}
              isAdmin={isAdmin}
              baseContext={baseContext}
            />
          )}
        </div>
      </header>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <Header {...props} baseContext={context} ref={ref} />}
  </BaseContext.Consumer>
));

export default withContext;
