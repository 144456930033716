import React from "react";
import { toArray } from "lodash";
import { toMap } from "../utils";
import "../Layouts.css";
import { BaseContext } from "../baseContext";
import queryString from "query-string";
import AlbumList from "../components/AlbumList";

import {
  setFirebaseData,
  paths,
  activeUser,
  isUserEmailValidated
} from "../api";
import { withRouter } from "react-router-dom";

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openItemSelectDialog: false,
      selectionItems: [],
      selectionTitle: "",
      handleSelectedItem: null,
      search: null,
      type: null
    };
  }
  componentDidMount() {
    const { data } = this.props.baseContext.baseContext;
    const queryValues = queryString.parse(this.props.history.location.search);
    if (data && queryValues.search) {
      this.setState({search: queryValues.search, type: queryValues.type});
    }
  }
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps.baseContext.baseContext;
    const queryValues = queryString.parse(this.props.history.location.search);
    if (data && queryValues.search) {
      this.setState({search: queryValues.search, type: queryValues.type});
    }
  }
  handleTumbnailClick(id) {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired("/watchLive?event=" + id);
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    } else {
      this.props.baseContext.baseContext.data.currentVideoId = id;
      this.props.baseContext.setBase(this.props.baseContext.baseContext);
      this.props.baseContext.setShowLoginRequired(
        this.props.location.pathname + this.props.location.search
      );
      this.props.history.push("/watchLive?event=" + id);
    }
  }
  handleItemsSelectDialogClose() {
    this.setState({ openItemSelectDialog: false });
  }
  selectAlbumItem(index) {
    const { data } = { ...this.props.baseContext.baseContext };
    let albumsMap = toMap(data.albums);
    const handleSelectedItem = albumId => {
      if (!data.config.albums) {
        data.config.albums = [];
      }
      const tempAlbums = data.config.albums.filter(album => {
        return album.order >= index;
      });
      tempAlbums.forEach(album => {
        album.order = album.order + 1;
      });
      data.config.albums.push({
        albumId: albumId,
        order: index,
        type: 1
      });
      setFirebaseData(paths.config, data.config);
    };

    this.setState({
      openItemSelectDialog: true,
      selectionTitle: "Select Album",
      selectionItems: albumsMap,
      handleSelectedItem: handleSelectedItem
    });
  }

  renderBlank() {
    return (
      <div className="Main">
        <div className="FlexColumn">
          {"Lütfen bekleyin..."}
          <div style={{ width: "100%" }}>
            <AlbumList type={1} key={1} blank />
          </div>
        </div>
      </div>
    );
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  getSearchValue(video) {
    const { data } = { ...this.props.baseContext.baseContext };
    const { type } = this.state;
    const videoAlbums = data.videoAlbums[video.id];
    const videoTags = data.videoTags[video.id];
    let keys = [];
    if (!type || type === "video") keys.push(video.name.toLowerCase());
    if (!type || type === "album")
      if (videoAlbums) {
        toArray(data.albums).forEach(album => {
          if (videoAlbums.indexOf(album.id) > -1) {
            keys.push(album.name.toLowerCase());
          }
        });
      }
    if (!type || type === "tag")
      if (videoTags && data.tags)
        toArray(data.tags).forEach(tag => {
          if (videoTags && videoTags.indexOf(tag.id) > -1) {
            keys.push(tag.name.toLowerCase());
          }
        });
    return keys.join(" ");
  }

  getResults(value) {
    const { data } = this.props.baseContext.baseContext;
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp(escapedValue, "i");

    return toArray(data.videos)
      .filter(video => {
        if (data.videoAlbums[video.id]) {
          return !data.videoAlbums[video.id].find(
            albumId => data.hiddenAlbums.indexOf(albumId) > -1
          );
        }
        return true;
      })
      .filter(video => regex.test(this.getSearchValue(video)));
  }
  render() {
    const { data } = this.props.baseContext.baseContext;
    const { search } = this.state;
    const album = {
      id: -1,
      name: "'" + this.state.search + "' için arama sonuçları"
    };
    let videos = null;
    console.log("dataVideos", data.videos);
    if (data.videos && search) {
      videos = this.getResults(search.toLowerCase());
    }
    if (!videos) {
      return this.renderBlank();
    }
    return (
      <div className="Main">
        <div className="FlexColumn">
          {videos && videos.length > 0 && (
            <AlbumList
              key={1}
              type={1}
              album={album}
              videos={videos}
              handleTumbnailClick={id => this.handleTumbnailClick(id)}
            />
          )}
          {videos && videos.length === 0 && <span>Hiç sonuç bulunamadı.</span>}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <SearchResult {...props} baseContext={context} ref={ref} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
