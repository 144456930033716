import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import { verifyEmail, sendEmailValidation } from "../api";
import queryString from "query-string";
import Contexts from "../contexts";

class ValidateEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }
  componentDidMount() {
    const queryValues = queryString.parse(this.props.history.location.search);
    if (queryValues) {
      verifyEmail(
        queryValues.email,
        queryValues.uid,
        queryValues.requestId
      ).then(result => {
        console.log(result);
        if (result.error) {
          this.setState({
            loading: false,
            error: Contexts.get(Contexts.Context.emailVerificationError)
          });
        } else {
          this.setState({
            loading: false,
            error: null
          });
        }
      });
    }
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="SignIn">
        {!this.state.loading && (
          <div className="SignIn_Header">
            {!this.state.error && (
              <span style={{with: '240px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
               {Contexts.get(Contexts.Context.emailVerificationDone)}
                <Button
                  onClick={() => this.props.history.push("/")}
                  style={{
                    width: "120px",
                    height: "50px",
                    fontSize: ".8em",
                    marginTop: "20px"
                  }}
                  color="info"
                >
                  {Contexts.get(Contexts.Context.contunie)}
                </Button>
              </span>
            )}
            {this.state.error && (
              <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {this.state.error}
                <Button
                  onClick={() => {
                    const queryValues = queryString.parse(
                      this.props.history.location.search
                    );
                    sendEmailValidation(queryValues.email);
                    this.props.history.push("/");
                  }}
                  style={{
                    width: "170px",
                    height: "50px",
                    fontSize: ".8em",
                    marginTop: "20px"
                  }}
                  color="info"
                >
                  {Contexts.get(Contexts.Context.sendAgain)}
                </Button>
              </span>
            )}
          </div>
        )}
        {this.state.loading && (
          <div className="SignIn_Header">Lütfen bekleyin</div>
        )}
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <ValidateEmail {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
