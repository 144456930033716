import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import "../react-tags.css";
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";
import { toArray } from "lodash";
import * as firebase from "firebase";
import AlbumList from "../components/AlbumList";
import { BaseContext } from "../baseContext";
import { getFirebaseData, paths, getUserPurchases } from "../api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

class MyPurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchases: null,
    };
  }

  loadUserPurchases(that) {
    getUserPurchases().then((result) => {
      that.setState({ purchases: toArray(result) });
    });
  }
  componentDidMount() {
    this.loadUserPurchases(this);
  }

  renderItem(purchase) {
    const { data } = { ...this.props.baseContext.baseContext };

    const videos = [];
    if (!data.paidEvents) return null;
    const paidEvent = data.paidEvents[purchase.paidEventId];
    if (!paidEvent) return null;

    if (paidEvent.videos) {
      paidEvent.videos.forEach((videoId) => {
        videos.push(data.videos[videoId]);
      });
    }
    const album = {
      id: -1,
      name: "Videos",
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "1px solid #d4d4d4",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div>
            <strong>Event: </strong>
            {paidEvent.name}
          </div>
          <div style={{ display: "flex" }}></div>
        </div>
        <div style={{ width: "50%", marginRight: 20 }}>
          <AlbumList album={album} videos={videos} key={1} type={1} />
        </div>
      </div>
    );
  }
  render() {
    const { classes } = this.props;
    const { purchases } = this.state;

    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className={classes.cardTitleWhite}>My purchases</h4>
              </CardHeader>
              <CardBody>
                {purchases &&
                  purchases.map((item) => {
                    return this.renderItem(item);
                  })}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <MyPurchases {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withStyles(styles)(withRouter(withContext));
