import React from "react";
import "../Layouts.css";
import ManageLiveVideoView from "../components/ManageLiveVideoView";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main_Video">
        <ManageLiveVideoView {...this.props} />
      </div>
    );
  }
}
