import {getLanguage} from './api';

const Contexts = {
  Context: {
    siteDescription: {
      tr: "Cerrahi Tv",
      en: "Surgical Tv",
    },
    save: {
      tr: "Kaydet",
      en: "Save",
    },
    cancel: {
      tr: "İptal",
      en: "Cancel",
    },
    sendAgain: {
      tr: "Tekran Gönder",
      en: "Send again",
    },
    contunie: {
      tr: "Devam et",
      en: "Continue",
    },
    backArrow: {
      tr: "Geri",
      en: "Back",
    },
    login: {
      tr: "GİRİŞ YAP",
      en: "Sign In",
    },
    register: {
      tr: "Kaydol",
      en: "Sign Up",
    },
    follow: {
      tr: "Haberdar Et",
      en: "Follow",
    },
    signOut: {
      tr: "Çıkış yap",
      en: "Logout",
    },
    resetPassword: {
      tr: "Şifremi sıfırla",
      en: "Reset my Password",
    },
    nameIsRequired: {
      tr: "İsim gerekli",
      en: "Name is required",
    },
    nameIsTooShort: {
      tr: "İsim çok kısa",
      en: "Name is too short",
    },
    passwordShort: {
      tr: "Şifre en az 6 hane olmalı",
      en: "Password should be longer than 6",
    },
    emailNotValid: {
      tr: "E-posta adresi geçerli değil",
      en: "Email is invalid",
    },
    sprcialityRequired: {
      tr: "Uzmanlık seçimi gerekli",
      en: "Speciality is requried",
    },
    professionRequired: {
      tr: "Meslek seçimi gerekli",
      en: "Profession is requried",
    },
    enterValidEmail: {
      tr: "Lütfen geçerli bir e-posta adresi girin.",
      en: "Please enter a valid email address.",
    },
    weSentEmailForResetPassword: {
      tr: "Size şifrenizi sıfırlamanın için bir e-posta gönderdik.",
      en: "We sent an email to reset your password.",
    },
    weCouldntFindAccount: {
      tr: "Bu e-posta adresi için kullanıcı kaydı bulunamadı!",
      en: "We couldn't find an account for that email address!",
    },
    createAccount: {
      tr: "Hesap Oluştur",
      en: "Create Account",
    },
    welcomeBack: {
      tr: "Tekrar hoş geldiniz",
      en: "Welcome back",
    },
    email: {
      tr: "E-Posta Adresi",
      en: "Email Address",
    },
    speciality: {
      tr: "Uzmanlık",
      en: "Speciality",
    },
    profession: {
      tr: "Meslek",
      en: "Profession",
    },
    fullName: {
      tr: "Ad ve soyad",
      en: "Full Name",
    },
    password: {
      tr: "Şifre",
      en: "Password",
    },
    forgotPassword: {
      tr: "Şifremi unuttum",
      en: "Forgot your password?",
    },
    termsWarning: {
      tr: "kaydolarak kullanıcı sözleşmemizi kabul etmiş olursunuz.",
      en: "by creating an account via any of the options, you agree to our",
    },
    termsLabel: {
      tr: "Kullanım Sözleşmesi",
      en: "Terms of Service",
    },
    haveAccount: {
      tr: "Zaten üyeyim",
      en: "I have already an account",
    },
    signGoogle: {
      tr: "Google'la devam et",
      en: "Continue with Google",
    },
    signFacebook: {
      tr: "Facebook'la devam et",
      en: "Continue with Facebook",
    },
    pleaseSelectOnFile: {
      tr: "Lütfen sadece bir dosya seçin.",
      en: "Please select only one file at once",
    },
    maximumFileSize: {
      tr: "5 GB'dan büyük dosya yüklenemez",
      en: "You can't upload bigger than 5GB video file!",
    },
    somethingWentWrong: {
      tr: "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
      en: "Something went wrong. Please try again",
    },
    fileIsUploading: {
      tr: "Dosya yükleniyor. Lütfen bekleyin.",
      en: "File is uploading. Please wait.",
    },
    fileUploaded: {
      tr:
        "Video başarıyla aktarıldı. İncelendikten sonra yayına alındığında size bildireceğiz.",
      en: "File uploaded. We will notify you when we confirm the video.",
    },
    videoTile: {
      tr: "Video Başlığı *",
      en: "Video Title *",
    },
    videoDescription: {
      tr: "Video Açıklaması",
      en: "Description",
    },
    upload: {
      tr: "Yükle",
      en: "Upload",
    },
    videoUpload: {
      tr: "Video Yükle",
      en: "Video Upload",
    },
    back: {
      tr: "Geri Dön",
      en: "Go back",
    },
    loginRequired: {
      tr: "Lütfen giriş yapın",
      en: "Please sign in.",
    },
    loginRequiredDescription: {
      tr:
        "Bu site sadece sağlık profesyonellerine özeldir. Eğer sağlık profesyoneli iseniz bu sayfaya erişmek için kayıt olun veya giriş yapın.",
      en:
        "This website is for only healthcare professionals. If you are a healthcare professional you can sign up or sign in.",
    },
    emailVerification: {
      tr: "E-Posta doğrulama",
      en: "Verify e-mail",
    },
    emailVerificationRequiredDescription: {
      tr:
        "Site içeriklerine erişebilmek için e-posta adresine gönderdiğimiz e-posta doğrulama işlemini tamamlamanız gerekmektedir.",
      en:
        "This website is for only healthcare professionals. If you are a healthcare professional you can sign up or sign in.",
    },
    cookieWarning: {
      tr: "Kullanıcı deneyimini artıkmak için çerez kullanmaktayız.",
      en: "We use cookies to improve user experiences.",
    },
    liveChat: {
      tr: "Canlı sohbet",
      en: "Live chat",
    },
    messagePlaceHolder: {
      tr: "mesajınızı buraya yazabilirsiniz",
      en: "write your message here",
    },
    commentPlaceHolder: {
      tr: "Yorumunuzu buraya yazabilirsiniz",
      en: "write your comment here",
    },
    send: {
      tr: "Gönder",
      en: "Send",
    },
    noMessage: {
      tr: "Henüz hiç mesaj yok.",
      en: "There is no message yet.",
    },
    noComment: {
      tr: "Henüz hiç yorum yok.",
      en: "There is no comment yet.",
    },
    signupWarning: {
      tr:
        "Bu site sadece sağlık profesyonellerine özeldir. Eğer sağlık profesyoneli iseniz kayıt olabilirsiniz.",
      en:
        "This website is for only healthcare professionals. If you are a healthcare professional you can sign up.",
    },
    emailVerificationDone: {
      tr: "E-Posta doğrulama işleminiz tamamlandı.",
      en:
        "E-mail verification is done. You can contunie to access the content.",
    },
    unsubscribeDone: {
      tr: "Bildirim listesinden başarı ile çıktınız.",
      en: "Bildirim listesinden başarı ile çıktınız.",
    },
    unsubscribeError: {
      tr: "Lütfen bildirim almak istemediğiniz e-posta adresini yazın.",
      en: "E-posta adresi hatalı veya sistemde beklenmeyen bir hata oluştu!",
    },
    emailVerificationError: {
      tr: "E-posta doğrulama linki hatalı veya süresi dolmuş!",
      en: "E-mail verification link is broken or expired!",
    },
    userAgreementFormTitle: {
      tr: "Sadece sağlık profesyonellerine özeldir.",
      en: "This site is exclusively for healthcare professionals.",
    },
    userAgreementFormContent: {
      tr:
        "Eğer sağlık profesyoneli değilseniz site içeriği sizin için uygun değildir. Site içeriğine erişmeye devam ederek kullanım koşullarını kabul ettiğinizi ve sağlık profesyoneli olduğunuzu beyan ve taahhüt etmiş olursunuz.",
      en:
        " If you are not a healthcare professional, the site content is not suitable for you.  By continuing to access the site content, you declare and undertake that you accept the terms of use and that you are a healthcare professional.",
    },
    yesIam: {
      tr: "EVET, SAĞLIK PROFESYONELİYİM",
      en: "I AGREE, CONTINUE",
    },
    userTags: {
      tr: "İlgi alanlarım",
      en: "Preferences",
    },
    userTagsDescription: {
      tr:
        "Yeni eklenen videolar veya canlı yayınlardan haberdar olmak için ilgi alanlarınızı belirtebilirsiniz.",
      en: "Choose your preferences to get notification",
    },
    getNotification: {
      tr: "Bildirim kapalı",
      en: "Reminder off",
    },
    dontGetNotification: {
      tr: "Bildirim açık",
      en: "Reminder on",
    },
    videoFollowers: {
      tr: "Takipciler",
      en: "Followers",
    },
    sendNotification: {
      tr: "Bildirim Gönder",
      en: "Send Notification",
    },
    sendEmail: {
      tr: "E-Posta Gönder",
      en: "Send Email",
    },
    wathchers: {
      tr: "Izleyiciler",
      en: "Watchers",
    },
    noWathchers: {
      tr: "Henüz hiç izleyici yok.",
      en: "No watcher",
    },
    search: {
      tr: "Ara",
      en: "Search",
    },
    liveEvent: {
      tr: "Canlı Yayın",
      en: "Live",
    },
    upcomingEvents: {
      tr: "Yaklaşan Canlı Yayınlar",
      en: "Upcoming Live Events",
    },
    ago: {
      tr: " önce",
      en: " ago",
    },
    returnMainPage: {
      tr: "Ana sayfaya dön",
      en: "Return to main page",
    },
    requiredFields: {
      tr: "Zorunlu alan",
      en: "Required field",
    },
    requiredInfo: {
      tr: "Zorunlu bilgi",
      en: "Required information",
    },
    requiredFieldsDialogContent: {
      tr: "Lütfen aşağıdaki alanları doldurun.",
      en: "Please fill folowing informations.",
    },
    surveyTitle: {
      tr: "Lütfen anketimize katılarak bize destek olun.",
      en: "Please join our survey to support us.",
    },
    preferedLanguage: {
      tr: "Tercih edilen dil",
      en: "Preferred languages",
    },
    turkish: {
      tr: "Türkçe",
      en: "Turkish",
    },
    english: {
      tr: "İngilizce",
      en: "English",
    },
    allLanguages: {
      tr: "Hepsi",
      en: "All",
    },
    preferedLanguageRequired: {
      tr: "Dil seçimi gerekli",
      en: "Preferred language is requried",
    },
    address1: {
      tr: "Adres 1",
      en: "Address 1",
    },
    address2: {
      tr: "Adres 2",
      en: "Address 2",
    },
    city: {
      tr: "Şehir",
      en: "City",
    },
    state: {
      tr: "İlçe",
      en: "State",
    },
    country: {
      tr: "Ülke",
      en: "Country",
    },
    postCode: {
      tr: "Posta Kodu",
      en: "Post Code",
    },
    billingInfo: {
      tr: "Fatura Bilgileri",
      en: "Billing Info",
    },
    cardHolder: {
      tr: "Kart Sahibi",
      en: "Card Holder",
    },
    expires: {
      tr: "SKT",
      en: "Expires",
    },
    cardNumber: {
      tr: "Kart Numarası",
      en: "Card Number",
    },
    eventName: {
      tr: "Etkinlik",
      en: "Event",
    },
    description: {
      tr: "Açıklama",
      en: "Details",
    },
    promotionCode: {
      tr: "İndirim Kodu",
      en: "Promotion Code",
    },
    apply: {
      tr: "Uygula",
      en: "Apply",
    },
    price: {
      tr: "Toplam Tutar",
      en: "Total Price",
    },
    paymentSucceed: {
      tr: "Ödeme Başarılı!",
      en: "Payment Succeed!",
    },
    taxCode: {
      tr: "Vergi Kodu",
      en: "VAT Code",
    },
    buyIt: {
      tr: "Satın Al",
      en: "Buy It",
    },
    paymentRequiredTitle: {
      tr: "Ücretli İçerik",
      en: "Paid Content",
    },
    paymentRequiredDetail: {
      tr: " ücretli bir içeriktir. İçeriğe erişebilmek için lütfen satın alma işlemini gerçekleştiriniz.",
      en: " is a paid content. Please prooced to payment to access the content.",
    },
    orderInfo: {
      tr: "Sipariş Bilgileri",
      en: "Order Info",
    },
    pay: {
      tr: "Öde",
      en: "Pay",
    },
    paymentSuccessful: {
      tr: "Ödeme başarılı",
      en: "Payment Successful",
    },
    paymentSuccessfulDetails: {
      tr: "Surgical.tv olarak teşekkür ederiz. Üyelik bilgilerinizle web sitesine giriş yaparak ‘title’ izleyebilirsiniz. ",
      en: "We thank you as surgical.tv. You can watch 'title' by logging in to the website with your account information.",
    },
    promotionSuccess: {
      tr: "Promosyon kodunuz onaylanmıştır. %_discount_ İndirim uygulanmıştır.",
      en: "_discount_% discount has been applied. You can proceed to payment.",
    },
    usedPromoCode: {
      tr: "Promosyon kodunuz daha önce kullanılmıştır.",
      en: "Your promotional code has been used before.",
    },
    invalidPromoCode: {
      tr: "Promosyon kodunuz geçersizdir. Lütfen geçerli bir promosyon kodu giriniz veya bizimle ( info@cerrahi.tv) iletişime geçebilirsiniz.",
      en: "Your promotion code is invalid. Please enter a valid promotion code or you can contact us (info@cerrahi.tv).",
    },
    sessionIsOpenTitle: {
      tr: "Oturum açık",
      en: "Session is open",
    },
    sessionIsOpenDetail: {
      tr: "Bu video oturumu başka bir cihaz veya tarayıcı üzerinde zaten açık. 'Burada İzle' ye tıklayarak bu ekranda izlemeye devam edebilirsiniz.",
      en: "This video session is opened on another computer or browser. Click to 'Watch Here' to watch it in this window.",
    },
    watchHere: {
      tr: "Burada İzle",
      en: "Watch Here",
    },
    actionPlaceHolder: {
      tr: "Link (opsiyonel)/ refresh (to refresh browser)",
      en: "Link (opsiyonel)/ refresh (to refresh browser)",
    },
    ok: {
      tr: "Tamam",
      en: "Okay",
    },
    recommendations: {
      tr: "Benzer Videolar",
      en: "Related Videos",
    },
    nationality: {
      tr: "Uyruk",
      en: "Nationality",
    },
    nationalID: {
      tr: "TC Kimlik No",
      en: "TC Kimlik No",
    },
    birthYear: {
      tr: "Dogum Yili",
      en: "Year of Birth",
    },
    name: {
      tr: "Ad",
      en: "First name",
    },
    familyName: {
      tr: "Soyad",
      en: "Family name",
    },
    nationalityRequired: {
      tr: "Uyruk bilgisi gerekli",
      en: "Nationality field is required",
    },
    nationalIDNotValid: {
      tr: "Geçersiz Kimlik No",
      en: "Geçersiz Kimlik No",
    },
    myHistory: {
      tr: "İzleme geçmişim",
      en: "My history",
    }
  },
  get: (Context) => {
    return Context ? Context[getLanguage()] : "";
  },
  getList: (data, path) => {
    const result = { ...data };
    const language = getLanguage();
    if (language === "tr") return result[path];
    if (language === "en" && result.textContexts && result && result[path]) {
      Object.keys(result[path]).forEach((key) => {
        if (result.textContexts[key] && result.textContexts[key].name_en) {
          result[path][key].name = result.textContexts[key].name_en;
        }
      });
    }
    return result[path];
  },
};

export default Contexts;
