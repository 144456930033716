import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { BaseContext } from "../baseContext";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlbumList from "./AlbumList";
import TextField from '@material-ui/core/TextField';

class AlbumSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }
  handleTumbnailClick(value) {
    this.props.onChange(value);
    this.props.closeDialog();
  }
  renderAlbums() {
    const { data } = this.props.baseContext.baseContext;
    var items = [];
    if (this.props.items) {
      this.props.items.forEach(album => {
        if (
          album.name.toLowerCase().includes(this.state.search.toLowerCase()) > 0
        ) {
          const videos = [];
          const albumVideoIds = data.albumVideos[album.id];
          if (albumVideoIds) {
            albumVideoIds.forEach(videoId => {
              videos.push(data.videos[videoId]);
            });
          }
          items.push(
            <div
              style={{ cursor: "pointer" }}
              onClick={() => this.handleTumbnailClick(album.id)}
            >
              <AlbumList
                type={2}
                key={album.id}
                album={album}
                videos={videos}
              />
            </div>
          );
        }
      });
    }
    return items;
  }
  render() {
    return (
      <div>
        <Dialog
          maxWidth="md"
          open={this.props.open}
          onClose={() => this.props.closeDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <TextField
            id="name"
            style={{ margin: "10px 30px", width: "300px" }}
            variant="outlined"
            label={"Search"}
            type="text"
            onChange={e => this.setState({ search: e.target.value })}
          />
          <DialogContent width= '900px'>{this.renderAlbums()}</DialogContent>
        </Dialog>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => (
      <AlbumSelectDialog {...props} baseContext={context} ref={ref} />
    )}
  </BaseContext.Consumer>
));

export default withContext;
