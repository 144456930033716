import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";
import { toArray } from "lodash";
import AlbumList from "./AlbumList";
import Contexts from "../contexts";

class VideoRecommendations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: {},
    };
  }

  handleTumbnailClick(id) {
    this.props.baseContext.baseContext.data.currentVideoId = id;
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
    this.props.history.push("/watchLive?event=" + id);
    setTimeout(()=>{
      window.location.reload(false);
    }, 100);
  }

  render() {
    const { videos } = this.props;

    return (
      <div
        className="VideoRecommendations"
        style={{ height: this.props.maxHeight }}
      >
        <AlbumList
          key={"recommendations"}
          type={3}
          album={{ name: Contexts.get(Contexts.Context.recommendations) }}
          videos={videos}
          handleTumbnailClick={(id) => this.handleTumbnailClick(id)}
        />
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <VideoRecommendations {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
