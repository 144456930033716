import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { unsubscribe, sendEmailValidation } from "../api";
import queryString from "query-string";
import Contexts from "../contexts";

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null
    };
  }
  componentDidMount() {
    const queryValues = queryString.parse(this.props.history.location.search);
    if (queryValues) {
      unsubscribe(queryValues.uid).then(result => {
        console.log(result);
        if (result.error) {
          this.setState({
            loading: false,
            error: Contexts.get(Contexts.Context.unsubscribeError)
          });
        } else {
          this.setState({
            loading: false,
            error: null
          });
        }
      });
    }
  }

  handleUnsubscribeByEmail() {
    this.setState({
      loading: true,
      error: null
    });
    unsubscribe(null, this.state.email).then(result => {
      console.log(result);
      if (result.error) {
        this.setState({
          loading: false,
          error: Contexts.get(Contexts.Context.unsubscribeError)
        });
      } else {
        this.setState({
          loading: false,
          error: null
        });
      }
    });
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="SignIn">
        {!this.state.loading && (
          <div className="SignIn_Header">
            {!this.state.error && (
              <span
                style={{
                  with: "240px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                {Contexts.get(Contexts.Context.unsubscribeDone)}
                <Button
                  onClick={() => this.props.history.push("/")}
                  style={{
                    width: "120px",
                    height: "50px",
                    fontSize: ".8em",
                    marginTop: "20px"
                  }}
                  color="info"
                >
                  {Contexts.get(Contexts.Context.contunie)}
                </Button>
              </span>
            )}
            {this.state.error && (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                {this.state.error}
                <TextField
                  id="email"
                  variant="outlined"
                  label={Contexts.get(Contexts.Context.email)}
                  type="text"
                  value={this.state.email}
                  style={{
                    width: "300px",
                    marginTop: "20px"
                  }}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Button
                  onClick={() => this.handleUnsubscribeByEmail()}
                  style={{
                    width: "120px",
                    height: "50px",
                    fontSize: ".8em",
                    marginTop: "20px"
                  }}
                  color="info"
                >
                  {Contexts.get(Contexts.Context.contunie)}
                </Button>
              </span>
            )}
          </div>
        )}
        {this.state.loading && (
          <div className="SignIn_Header">Lütfen bekleyin</div>
        )}
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <Unsubscribe {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
