import React from "react";
import "../Layouts.css";
import SignIn from "../components/SignIn";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main">
        <SignIn {...this.props} />
      </div>
    );
  }
}
