import React from "react";
import "../Components.css";
import ResponsiveEmbed from "./ResponsiveEmbed";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import LiveChat from "./LiveChat";
import VideoFollowers from "./VideoFollowers";
import SendLiveMessage from "./SendLiveMessage";
import forEach from "lodash/forEach";
import { Editor, convertToRaw } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BaseContext } from "../baseContext";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import TagItem from "./TagItem";
import {
  activeUser,
  isUserEmailValidated,
  setFirebaseData,
  paths,
  getFirebaseData,
  getDescrypt
} from "../api";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { toArray } from "lodash";
import moment from "moment";

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ paddingTop: 10 }}>{children}</div>}
    </div>
  );
}

class ManageLiveVideoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video: null,
      newMessage: "",
      chatHeight: null,
      chatTop: 0,
      users: null,
      description: "",
      newVideoId: null,
      watchers: {},
      surveyUrl: "",
      tab: 0,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { data } = nextProps.baseContext.baseContext;
    const queryValues = queryString.parse(this.props.history.location.search);
    let { liveEvents } = this.props.baseContext.baseContext.data;
    if (!liveEvents) {
      liveEvents = [];
    }

    if (data && data.videos && queryValues.event) {
      let liveEvent = liveEvents.find((liveEvent) => {
        return liveEvent.videoId == queryValues.event;
      });
      this.setState({ liveEvent, video: data.videos[queryValues.event] });
      getFirebaseData(paths.videoWatchers + "/" + queryValues.event + "/").then(
        (watchers) => {
          if (watchers) {
            that.setState({ watchers });
            var watchersRef = firebase
              .database()
              .ref(paths.videoWatchers + "/" + queryValues.event + "/");
            watchersRef
              .endAt()
              .limitToLast(1)
              .on("child_added", function (data) {
                that.state.watchers[data.key] = data.val();
                that.setState({ watchers: that.state.watchers });
              });
            watchersRef.on("child_changed", function (data) {
              that.state.watchers[data.key] = data.val();
              that.setState({ watchers: that.state.watchers });
            });
          }
        }
      );
    }
    var commentsRef = firebase
      .database()
      .ref(paths.videos + "/" + queryValues.event + "/");
    const that = this;
    commentsRef.on("child_changed", function (data) {
      if (data.key === "videoId") {
        that.state.video[data.key] = data.val();
        console.log(that.state.video);
        that.setState({ video: that.state.video });
      }
    });

    if (!this.props.baseContext.baseContext.session.admin) {
      this.props.history.push("watchLive?event=" + queryValues.event);
    }
  }

  componentDidMount() {
    if (activeUser() == null) {
      this.props.baseContext.setShowLoginRequired(
        this.props.location.pathname + this.props.location.search
      );
    } else if (!isUserEmailValidated()) {
      this.props.baseContext.setShowMailVerifyRequired(true);
    }
    const that = this;
    getFirebaseData(paths.users).then((users) => {
      if (users) {
        that.setState({ users });
      }
    });
  }
  componentWillUnmount() {
    if (this.videoFrameRef)
      this.videoFrameRef.removeEventListener("resize", this.videoSizeChanged);
  }
  componentDidUpdate() {
    if (this.videoFrameRef && this.videoDetailsRef && !this.state.chatHeight) {
      this.videoFrameRef.addEventListener("resize", this.videoSizeChanged);
      this.setState({
        chatHeight:
          this.videoFrameRef.clientHeight - this.videoDetailsRef.clientHeight,
        chatTop: this.videoFrameRef.clientHeight,
      });
    }
  }
  videoSizeChanged() {
    if (this.videoFrameRef) {
      this.setState({
        chatHeight:
          this.videoFrameRef.clientHeight - this.videoDetailsRef.clientHeight,
        chatTop: this.videoFrameRef.clientHeight,
      });
    }
  }

  handleChangeReleaseTime(time) {
    if (moment(time).isValid()) {
      const { liveEvents } = { ...this.props.baseContext.baseContext.data };
      const liveEvent = liveEvents.find((liveEvent) => {
        return liveEvent && liveEvent.videoId && liveEvent.videoId == this.state.liveEvent.videoId;
      });
      if (liveEvent) {
        liveEvent.time = moment(time).unix();
        setFirebaseData(paths.liveEvents, liveEvents);
        setFirebaseData(paths.config + "/lastUpdate", moment().unix());
        this.props.baseContext.setBase(this.props.baseContext.baseContext);
      }
    }
  }
  saveDatails() {
    setFirebaseData(
      paths.videos + "/" + this.state.video.id + "/description/",
      this.state.description
    );
    if (this.state.newVideoId) {
      setFirebaseData(
        paths.videos + "/" + this.state.video.id + "/videoId/",
        this.state.newVideoId
      );
    }
    setFirebaseData(
      paths.videos + "/" + this.state.video.id + "/surveyUrl/",
      this.state.surveyUrl ? this.state.surveyUrl : ""
    );
    setFirebaseData(paths.config + "/lastUpdate", moment().unix());
    this.props.baseContext.setBase(this.props.baseContext.baseContext);
  }
  handleExportWatchers() {
    const header = "id;nationality;name;email;start;end;";
    const that = this;
    getFirebaseData(paths.videoWatchers + that.state.video.id).then(
      async (watchers) => {
        const watchersList = [];
        
        const _users = toArray(that.state.users).filter(u=>watchers[u.id]);
        debugger;
        // const nationalIDs = await getDescrypt(_users.filter(u=>u.nationalID && u.nationalID.length > 0).map(u=>u.nationalID));
        forEach(watchers, (watcher, i) => {
          if (watcher) {
            const user = that.state.users[i];
            if (user) {
              console.log(i, ":", user);
              let userLine = user.id;
              userLine += ";";
              userLine += user.nationality || "";
              userLine += ";";
              userLine += user.name;
              userLine += ";";
              userLine += user.email;
              userLine += ";";
              userLine += moment(watcher.start * 1000).format(
                "DD/MM/YYYY HH:mm"
              );
              userLine += ";";
              userLine += moment(watcher.lastUpdate * 1000).format(
                "DD/MM/YYYY HH:mm"
              );
              userLine += ";";
              watchersList.push(userLine);
            }
          }
        });
        const csv = [header, watchersList.join("\n")].join("\n");

        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = that.state.video.id + "_watchers.csv";
        hiddenElement.click();
      }
    );
  }
  renderReactFrame() {
    const videoId = this.state.video.videoId || this.state.video.id;
    if (!activeUser() || !isUserEmailValidated())
      return (
        <img
          className="MainLiveVideoTumbnail_Image"
          style={{ position: "inherit", cursor: "pointer" }}
          alt=""
          onClick={() =>
            this.props.baseContext.setShowLoginRequired(
              this.props.location.pathname + this.props.location.search
            )
          }
          src={this.state.video.imageUrl}
        />
      );
    const url =
      "https://player.vimeo.com/video/" +
      videoId +
      "?autoplay=1&loop=1&autopause=0&muted=0";
    return (
      <ResponsiveEmbed
        src={url}
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        enable={true}
      />
    );
  }

  renderDetails() {
    const { liveEvent, video } = this.state;

    let { videoTags, tags } = this.props.baseContext.baseContext.data;
    let tagItems = null;
    if (video && videoTags && tags) {
      tagItems = toArray(tags)
        .filter((tag) => {
          return (
            videoTags[video.id] && videoTags[video.id].indexOf(tag.id) > -1
          );
        })
        .map((map) => <TagItem name={map.name} history={this.props.history} />);
    }
    const isOnline = video.duration === 0;
    if (video) {
      return (
        <div
          className="MainLiveView_Details"
          style={{ alignItems: "flex-end", flexDirection: "column" }}
          ref={(r) => (this.videoDetailsRef = r)}
        >
          <div style={{ display: "flex", marginBottom: "10px" }}>
            {tagItems}
          </div>
          <div className="h1">{video.name}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 10,
            }}
          >
            {isOnline && (
              <TextField
                id="datetime-local"
                label="Canlı Yayın Saati"
                type="datetime-local"
                defaultValue={moment(
                  (liveEvent ? liveEvent.time : video.created_time) * 1000
                ).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => this.handleChangeReleaseTime(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            {!isOnline && (
              <div className="MainLiveVideoTumbnail_BottomDate">
                <span>
                  {!liveEvent &&
                    !isOnline &&
                    video.created_time &&
                    moment(video.created_time).format("DD/MM/YYYY HH:mm")}
                </span>
              </div>
            )}
            <TextField
              id="videoId"
              variant="outlined"
              label="Video Id"
              type="text"
              defaultValue={video.videoId || video.id}
              onChange={(e) => this.setState({ newVideoId: e.target.value })}
            />
            {/* <TextField
              id="surveyUrl"
              variant="outlined"
              label="Survey Url"
              type="text"
              defaultValue={video.surveyUrl}
              onChange={(e) => this.setState({ surveyUrl: e.target.value })}
            /> */}
          </div>

          <div className="MainLiveVideoTumbnail_Description">
            <Editor
              onChange={(editorState) =>
                this.setState({ description: draftToHtml(editorState) })
              }
            />
          </div>
          <Button
            color="primary"
            style={{ width: 70 }}
            onClick={() => this.saveDatails()}
          >
            Kaydet
          </Button>
          <Button color="primary" onClick={() => this.handleExportWatchers()}>
            Izleyici Listesi ({this.getWatchersTotal()})
          </Button>
        </div>
      );
    }
    return null;
  }
  getWatchersTotal() {
    const watchers = toArray(this.state.watchers).filter((watcher) => {
      return (
        this.state.liveEvent &&
        (watcher.start > this.state.liveEvent.time - 900 ||
          watcher.lastUpdate > this.state.liveEvent.time - 900)
      );
    });
    return watchers.length;
  }

  render() {
    let isAdmin = false;
    const { users, video, tab } = this.state;
    const currentUser = activeUser();
    if (
      currentUser &&
      this.props.baseContext.baseContext.data.admins.indexOf(currentUser.uid) >
        -1
    ) {
      isAdmin = true;
    }
    let showBackButton =
      !this.props.isMobile &&
      window.previousLocation &&
      !window.previousLocation.pathname.startsWith("sign");

    if (video && users) {
      let { liveEvents } = this.props.baseContext.baseContext.data;
      if (!liveEvents) {
        liveEvents = [];
      }
      return (
        <div className="MainLiveView">
          {showBackButton && (
            <img
              style={{ marginRight: "8px", cursor: "pointer" }}
              height={32}
              width={32}
              src="/assets/images/arrow-back-button.svg"
              onClick={() => this.props.history.goBack()}
              alt=""
            />
          )}
          <div className="MainLiveVideoViewContainer">
            <div
              className="MainLiveViewVideo"
              ref={(r) => (this.videoFrameRef = r)}
            >
              {this.renderReactFrame()}
              {this.renderDetails()}
            </div>
            <div className="MainLiveView_Tabs">
              <Tabs
                value={tab}
                onChange={(event, newValue) => this.setState({ tab: newValue })}
              >
                <Tab label="Live Chat" {...tabProps(0)} />
                <Tab label="Followers" {...tabProps(1)} />
                <Tab label="Live Message" {...tabProps(2)} />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <LiveChat
                  video={video}
                  maxHeight={this.state.chatHeight}
                  Top={this.state.chatTop}
                  admin={isAdmin}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <VideoFollowers
                  video={video}
                  maxHeight={this.state.chatHeight}
                  Top={this.state.chatTop}
                  users={users}
                />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <SendLiveMessage
                  video={video}
                  maxHeight={this.state.chatHeight}
                  Top={this.state.chatTop}
                />
              </TabPanel>
            </div>
            {/* <VideoWatchers
              video={video}
              maxHeight={this.state.chatHeight}
              Top={this.state.chatTop}
              users={users}
            /> */}
          </div>
        </div>
      );
    }
    return null;
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <ManageLiveVideoView {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
