import React from "react";
import Contexts from "../contexts";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import { setUserAggrementStatus } from "../api";
import { BaseContext } from "../baseContext";
import { withRouter } from "react-router-dom";

class UserAgreementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAgree() {
    setUserAggrementStatus();
    this.props.baseContext.setUserAgreementStatus(true);
  }
  render() {
    const { data } = this.props.baseContext.baseContext;
    return (
      <div className="UserAgreementForm backdrop-blur">
        <div className="UserAgreementForm_inner">
          <img
            height={40}
            src={`/assets/images/${
              data.domain === "surgical.tv"
                ? "surgicalTvLogo.png"
                : "cerrahiTvLogo.png"
            }`}
            alt="Cerrahi Tv"
          />
          <div className="UserAgreementForm_title">
            {Contexts.get(Contexts.Context.userAgreementFormTitle)}
          </div>
          <div className="UserAgreementForm_content">
            {Contexts.get(Contexts.Context.userAgreementFormContent)}
          </div>
          <a
            href="/termsandconditions"
            target="_blank"
            style={{ fontWeight: 600, fontSize: "1.1em" }}
          >
            {Contexts.get(Contexts.Context.termsLabel)}
          </a>
          <Button
            onClick={() => this.handleAgree()}
            style={{
              width: "100%",
              height: "50px",
              fontSize: "1em",
              marginTop: "20px",
            }}
            color="info"
          >
            {Contexts.get(Contexts.Context.yesIam)}
          </Button>
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <UserAgreementForm {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
