import React from "react";
import "../Layouts.css";
import ValidateEmail from "../components/ValidateEmail";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main">
        <ValidateEmail {...this.props} />
      </div>
    );
  }
}
