import React from "react";
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from "react-transition-group";
import "./ReactCard.scss";

import Contexts from "../../contexts";

const CARDS = {
  visa: "^4",
  amex: "^(34|37)",
  mastercard: "^5[1-5]",
  discover: "^6011",
  unionpay: "^62",
  troy: "^9792",
  diners: "^(30[0-5]|36)",
};

const cardBackgroundName = () => {
  let random = Math.floor(Math.random() * 25 + 1);
  return `${random}.jpeg`;
};

const BACKGROUND_IMG = cardBackgroundName();

const outlineElementStyle = (element) => {
  return element
    ? {
        width: `${element.offsetWidth}px`,
        height: `${element.offsetHeight}px`,
        transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`,
      }
    : null;
};

export default class ReactCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentFocusedElm !== this.props.currentFocusedElm) {
      const style = outlineElementStyle(this.refs[nextProps.currentFocusedElm.current]);
      this.setState({ style });
    }
  }
  cardType(cardNumber) {
    const number = cardNumber;
    let re;
    if (number) {
      for (const [card, pattern] of Object.entries(CARDS)) {
        re = new RegExp(pattern);
        if (number.match(re) != null) {
          return card;
        }
      }
    }
    return "visa"; // default type
  }

  maskCardNumber(cardNumber) {
    let cardNumberArr = cardNumber.split("");
    cardNumberArr.forEach((val, index) => {
      if (index > 4 && index < 14) {
        if (cardNumberArr[index] !== " ") {
          cardNumberArr[index] = "*";
        }
      }
    });

    return cardNumberArr;
  }

  useCardType() {
    return this.cardType(this.state.cardNumber);
  }

  render() {
    const {
      cardHolder,
      cardNumber,
      cardMonth,
      cardYear,
      cardCvv,
      currentFocusedElm,
      onCardElementClick,
      cardNumberRef,
      cardHolderRef,
      cardDateRef,
    } = this.props;

    const isCardFlipped = currentFocusedElm && currentFocusedElm.current === "card-item__cvv";
    const { style } = this.state;
    console.log(currentFocusedElm);
    console.log('style', style);
    return (
      <div className={"card-item " + (isCardFlipped ? "-active" : "")}>
        <div className="card-item__side -front">
          <div
            className={`card-item__focus ${currentFocusedElm ? `-active` : ``}`}
            style={style}
          />
          <div className="card-item__cover">
            <img
              alt=""
              src={`https://4gbou.csb.app/card-background/${BACKGROUND_IMG}`}
              className="card-item__bg"
            />
          </div>

          <div className="card-item__wrapper">
            <div className="card-item__top">
              <img src={"/chip.png"} alt="" className="card-item__chip" />
              <div className="card-item__type">
                <img
                  alt={this.useCardType()}
                  src={`https://4gbou.csb.app/card-type/${this.useCardType()}.png`}
                  className="card-item__typeImg"
                />
              </div>
            </div>

            <label
              className="card-item__number"
              ref={"card-item__number"}
            >
              <TransitionGroup className="slide-fade-up" compent="div">
                {cardNumber ? (
                  this.maskCardNumber(cardNumber).map((val, index) => (
                    <CSSTransition
                      classNames="slide-fade-up"
                      timeout={250}
                      key={index}
                    >
                      <div className="card-item__numberItem">{val}</div>
                    </CSSTransition>
                  ))
                ) : (
                  <CSSTransition classNames="slide-fade-up" timeout={250}>
                    <div className="card-item__numberItem">#</div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </label>
            <div className="card-item__content" ref={"card-item__content"}>
              <label
                className="card-item__info"
              >
                <div className="card-item__holder">{Contexts.get(Contexts.Context.cardHolder)}</div>
                <div className="card-item__name">
                  <TransitionGroup component="div" className="slide-fade-up">
                    {cardHolder === "FULL NAME" ? (
                      <CSSTransition classNames="slide-fade-up" timeout={250}>
                        <div>FULL NAME</div>
                      </CSSTransition>
                    ) : (
                      cardHolder.split("").map((val, index) => (
                        <CSSTransition
                          timeout={250}
                          classNames="slide-fade-right"
                          key={index}
                        >
                          <span className="card-item__nameItem">{val}</span>
                        </CSSTransition>
                      ))
                    )}
                  </TransitionGroup>
                </div>
              </label>
              <div
                className="card-item__date"
                ref={"card-item__date"}
              >
                <label className="card-item__dateTitle">{Contexts.get(Contexts.Context.expires)}</label>
                <label className="card-item__dateItem">
                <span>{!cardMonth ? "MM" : cardMonth} </span>
                  {/* <SwitchTransition in-out>
                    <CSSTransition
                      classNames="slide-fade-up"
                      timeout={200}
                      key={cardMonth}
                    >
                      <span>{!cardMonth ? "MM" : cardMonth} </span>
                    </CSSTransition>
                  </SwitchTransition> */}
                </label>
                /
                <label htmlFor="cardYear" className="card-item__dateItem">
                {!cardYear ? "YY" : cardYear.toString().substr(-2)}
                  {/* <SwitchTransition out-in>
                    <CSSTransition
                      classNames="slide-fade-up"
                      timeout={250}
                      key={cardYear}
                    >
                      <span>
                        {!cardYear ? "YY" : cardYear.toString().substr(-2)}
                      </span>
                    </CSSTransition>
                  </SwitchTransition> */}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="card-item__side -back">
          <div className="card-item__cover">
            <img
              alt=""
              src={`https://4gbou.csb.app/card-background/${BACKGROUND_IMG}`}
              className="card-item__bg"
            />
          </div>
          <div className="card-item__band" />
          <div className="card-item__cvv">
            <div className="card-item__cvvTitle" ref={"card-item__cvv"}>CVV</div>
            <div className="card-item__cvvBand">
              <TransitionGroup>
                {cardCvv.split("").map((val, index) => (
                  <CSSTransition
                    classNames="zoom-in-out"
                    key={index}
                    timeout={250}
                  >
                    <span>*</span>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
            <div className="card-item__type">
              <img
                alt="card-type"
                src={"https://4gbou.csb.app//card-type/visa.png"}
                className="card-item__typeImg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
