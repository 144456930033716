import React from "react";
import "../Layouts.css";
import MainLiveVideoView from "../components/MainLiveVideoView";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main_Video">
        <MainLiveVideoView {...this.props} />
      </div>
    );
  }
}
