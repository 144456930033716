import React from "react";
import moment from "moment-timezone";
import "../Components.css";
import PlayIcon from "../assets/img/play.svg";
import { withRouter } from "react-router-dom";
import { BaseContext } from "../baseContext";

class VideoThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }
  renderDetails() {
    const { video } = this.props;
    let { liveEvents } = this.props.baseContext.baseContext.data;
    if (!liveEvents) {
      liveEvents = [];
    }
    let liveEvent = liveEvents.find(liveEvent => {
      return liveEvent.videoId === video.id;
    });
    if (video) {
      return (
        <div className="VideoThumbnail_Details">
          {video.name}
          <div className="VideoThumbnail_Details_Date">
            <div>
              {liveEvent && !video.forceReleaseDate
                ? moment
                    .tz(new Date(liveEvent.time) * 1000, "Europe/Istanbul")
                    .tz(moment.tz.guess())
                    .format("DD/MM/YYYY HH:mm z")
                : moment(video.release_time).fromNow()
              }
            </div>
            {/* {video.plays > 0 &&
              <div style={{ display: 'flex', alignItems: 'center' }}> <img width={'16px'} height={'16'} style={{ opacity: 0.5, marginRight: '4px' }}
                src={EyeIcon} />
                {video.plays}
              </div>
            } */}
          </div>
        </div>
      );
    }
    return null;
  }

  renderBlank() {
    return (
      <div className="VideoThumbnail">
        <div className="VideoThumbnail_inner">
          <img
            className="VideoThumbnail_Image"
            style={{ backgroundColor: "#cccccc" }}
          />
        </div>
      </div>
    );
  }
  render() {
    const { video, blank } = this.props;
    if (blank) {
      return this.renderBlank();
    }
    return (
      <div className="VideoThumbnail">
        <div
          className="VideoThumbnail_inner"
          onClick={id => {
            this.props.handleTumbnailClick &&
              this.props.handleTumbnailClick(video.id);
          }}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <img
            className="VideoThumbnail_Image"
            src={video.smallImageUrl}
            alt="thumbnail"
          />
          {this.state.hover && (
            <img className="VideoThumbnail_Play" src={PlayIcon} alt="play" />
          )}
          <div className="VideoThumbnail_Duration">
            {video.duration &&
              moment("2015-01-01")
                .startOf("day")
                .seconds(video.duration)
                .format(video.duration >= 3600 ? "H:mm:ss" : "mm:ss")}
          </div>
          {this.renderDetails()}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <VideoThumbnail {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
