import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";

class NotificationDialog extends React.Component {
  handleClose(value) {
    if (this.props.closeDialog) {
      this.props.closeDialog();
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open || false}
          maxWidth={"xs"}
          fullWidth={false}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={()=>this.handleClose()} color="primary">
                        {Contexts.get(Contexts.Context.cancel)}
                        </Button> */}
            <Button
              onClick={() => {
                this.props.action();
              }}
              color="primary"
              autoFocus
            >
              {this.props.actionLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(NotificationDialog);
