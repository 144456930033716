import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import "../react-tags.css";
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import TextField from "@material-ui/core/TextField";
import { toArray } from "lodash";
import * as firebase from "firebase";
import AlbumList from "../components/AlbumList";
import { BaseContext } from "../baseContext";
import {
  addTag,
  addTagToAlbum,
  removeTagToAlbum,
  getFirebaseData,
  paths
} from "../api";
import ReactTags from "react-tag-autocomplete";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Albums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albums: {},
      search: "",
      tags: [],
      albumTags: []
    };
  }

  componentDidMount() {
    getFirebaseData(paths.tags).then(tags => {
      this.setState({ tags: toArray(tags) });
    });
    const commentsRef = firebase.database().ref(paths.albumTags);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.albumTags[data.key]) {
        that.state.albumTags[data.key] = data.val();
        that.setState(that.state.albumTags);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.albumTags[data.key] = data.val();
      that.setState(that.state.albumTags);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.albumTags[data.key];
      that.setState(that.state.albumTags);
    });
  }
  handleDeleteTag(tagId, albumId) {
    removeTagToAlbum(tagId, albumId);
  }
  handleAddTag(tag, albumId) {
    if (tag.id) {
      addTagToAlbum(tag.id, albumId);
    } else {
      addTag(tag.name).then(newTag => {
        this.state.tags.push(newTag);
        this.setState(this.state);
        addTagToAlbum(newTag.id, albumId);
      });
    }
  }
  handleChange(key, value) {
    this.setState({
      ...this.state,
      [key]: value
    });
  }

  renderalbumItem(album) {
    const { data } = { ...this.props.baseContext.baseContext };

    const videos = [];
    const albumVideoIds = data.albumVideos[album.id];
    if (albumVideoIds) {
      albumVideoIds.forEach(videoId => {
        videos.push(data.videos[videoId]);
      });
    }

    const tags = this.state.tags.filter(tag => {
      return (
        this.state.albumTags[album.id] &&
        this.state.albumTags[album.id].indexOf(tag.id) > -1
      );
    });

    const suggestions = this.state.tags.filter(tag => {
      return !(
        this.state.albumTags[album.id] &&
        this.state.albumTags[album.id].indexOf(tag.id) > -1
      );
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #d4d4d4",
          marginBottom: "10px"
        }}
      >
        <div style={{ width: "50%", marginRight: 20 }}>
          <AlbumList album={album} videos={videos} type={2} data={data} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <div>
            <strong>Album Id: </strong>
            {album.id}
          </div>
          <div style={{ display: "flex" }}>
            <ReactTags
              tags={tags}
              suggestions={suggestions}
              onAddition={tag => {
                this.handleAddTag(tag, album.id);
              }}
              onDelete={tag => {
                if (tags[tag]) this.handleDeleteTag(tags[tag].id, album.id);
              }}
              allowNew
            />
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { classes } = this.props;
    const { data } = { ...this.props.baseContext.baseContext };
    let items = [];
    if (data.albums) {
      items = toArray(data.albums).filter(album => {
        return (
          this.state.search.length === 0 ||
          (this.state.search.length > 0 &&
            album.name.toLowerCase().includes(this.state.search.toLowerCase()) >
              0)
        );
      });
    }

    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className={classes.cardTitleWhite}>All Albums</h4>
                <TextField
                  id="name"
                  label={"Search"}
                  type="text"
                  value={this.state.search}
                  onChange={e => this.setState({ search: e.target.value })}
                />
              </CardHeader>
              <CardBody>
                {items.map(item => {
                  return this.renderalbumItem(item);
                })}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <Albums {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withStyles(styles)(withRouter(withContext));
