import React from "react";
import "../Layouts.css";
import Unsubscribe from "../components/Unsubscribe";

export default class App extends React.Component {
  render() {
    return (
      <div className="Main">
        <Unsubscribe {...this.props} />
      </div>
    );
  }
}
