import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import TextArea from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import VideoSelectDialog from "./VideoSelectDialog";
import {
  activeUser,
  addChatMessage,
  paths,
  approveChatMessage,
  rejectChatMessage,
  copyVideoMessages,
} from "../api";
import { toArray } from "lodash";
import { withRouter } from "react-router-dom";
import Contexts from "../contexts";
import * as firebase from "firebase";
import ApproveIcon from "@material-ui/icons/CheckCircle";
import CopyIcon from "@material-ui/icons/FileCopy";
import RejectIcon from "@material-ui/icons/HighlightOff";
import ReplyIcon from "@material-ui/icons/Reply";

function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a style="color: #00a3b4" href="' + url + '" target="_blank">' + url + ' </a>';
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

class LiveChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: {},
      newMessage: "",
      replyingMessage: null,
      openVideoSelectDialog: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.video !== nextProps.video) {
      this.loadChatMessages();
    }
  }
  componentDidMount() {
    if (activeUser()) this.loadChatMessages();
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  sendChatMessage() {
    if (this.state.newMessage.length > 0) {
      addChatMessage(
        this.state.newMessage.substring(0, 500),
        this.props.video.id,
        this.state.replyingMessage ? this.state.replyingMessage.id : null,
        this.state.replyingMessage ? this.state.replyingMessage.userId : null
      ).then((result) => {
        this.setState({ newMessage: "", replyingMessage: null });
      });
    }
  }
  handleApprove(message) {
    approveChatMessage(activeUser(), message.videoId, message.id);
  }
  handleReject(message) {
    rejectChatMessage(activeUser(), message.videoId, message.id);
  }
  handleReply(message) {
    this.setState({ replyingMessage: message });
  }
  handleSelectedCopyItem(videoId) {
    console.log(videoId);
    copyVideoMessages(videoId, this.props.video.id);
  }
  handleApproveAll() {
    if (this.state.messages) {
      const user = activeUser();
      Object.keys(this.state.messages).forEach((mesageId) => {
        const message = this.state.messages[mesageId];
        if (message && !message.approveDate && !message.rejectDate)
          approveChatMessage(user, message.videoId, message.id);
      });
    }
  }
  handleRejectAll() {
    if (this.state.messages) {
      const user = activeUser();
      Object.keys(this.state.messages).forEach((mesageId) => {
        const message = this.state.messages[mesageId];
        if (message && !message.approveDate && !message.rejectDate)
          rejectChatMessage(user, message.videoId, message.id);
      });
    }
  }
  loadChatMessages() {
    if (this.props.video)
      var commentsRef = firebase
        .database()
        .ref(paths.videoChatMessages + "/" + this.props.video.id);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.messages[data.key]) {
        that.state.messages[data.key] = data.val();
        that.setState(that.state.messages);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.messages[data.key] = data.val();
      that.setState(that.state.messages);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.messages[data.key];
      that.setState(that.state.messages);
    });
  }
  renderMessageItem(message, isAnswer, isParent) {
    const { admin } = this.props;
    let messageStyle = null;
    if (
      !(
        typeof message.message === "string" || message.message instanceof String
      )
    )
      return null;
    if (isAnswer)
      messageStyle = {
        width: "calc(100% - 20px)",
        marginLeft: 20,
        borderTopRightRadius: 0,
      };
    if (isParent)
      messageStyle = {
        marginBottom: "-4px",
        backgroundColor: "#d1d1d1",
        borderBottomRightRadius: 0,
      };
    return (
      <span
        className="MainLiveView_Chat_MessageItem"
        style={{ ...messageStyle }}
        onClick={() =>
          navigator.clipboard.writeText(
            message.userName + ": " + message.message
          )
        }
      >
        <span style={{ marginRight: "8px" }}>{message.userName}</span>
        <strong dangerouslySetInnerHTML={{__html: urlify(message.message)}} />
        {admin && !message.rejectDate && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <ReplyIcon
              onClick={() => this.handleReply(message)}
              style={{ fill: "gray", cursor: "pointer" }}
            />
            {!message.approveDate && (
              <ApproveIcon
                onClick={() => this.handleApprove(message)}
                style={{ fill: "green", cursor: "pointer" }}
              />
            )}
            <RejectIcon
              onClick={() => this.handleReject(message)}
              style={{ fill: "red", cursor: "pointer" }}
            />
          </div>
        )}
      </span>
    );
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.sendChatMessage();
    }
  }
  scrollToBottom = () => {
    if (this.messageFlow) {
      const scrollHeight = this.messageFlow.scrollHeight;
      const height = this.messageFlow.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageFlow.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  render() {
    const user = activeUser();
    const { admin } = this.props;
    const messages = [];
    let unapproved = 0;
    if (this.state.messages) {
      toArray(this.state.messages)
        .sort((a, b) => {
          return a.approveDate
            ? a.approveDate - b.approveDate
            : a.date - b.date;
        })
        .forEach((message) => {
          if (
            !message.rejectDate &&
            user.uid !== message.uid &&
            !message.rejectDate
          ) {
            unapproved++;
          }
          if (
            message &&
            user &&
            (message.approveDate ||
              ((user.uid === message.userId ||
                user.uid === message.ownerUserId) &&
                !message.rejectDate) ||
              (admin && !message.rejectDate))
          )
            if (message.parentId) {
              const parentMessage = this.state.messages[message.parentId];
              if (parentMessage) {
                messages.push(
                  this.renderMessageItem(parentMessage, false, true)
                );
                messages.push(this.renderMessageItem(message, true, false));
              }
            } else {
              messages.push(this.renderMessageItem(message));
            }
        });
    }
    return (
      <div
        className="MainLiveView_Chat"
        style={{ height: this.props.maxHeight }}
      >
        <div
          className="h2"
          style={{
            padding: "6px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {Contexts.get(Contexts.Context.liveChat)}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {admin && unapproved > 0 && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ApproveIcon
                  onClick={() => this.handleApproveAll()}
                  style={{ fill: "green", cursor: "pointer" }}
                />
                <RejectIcon
                  onClick={() => this.handleRejectAll()}
                  style={{ fill: "red", cursor: "pointer" }}
                />
              </div>
            )}
            {admin && (
              <CopyIcon
                onClick={() => this.setState({ openVideoSelectDialog: true })}
                style={{ fill: "grey", cursor: "pointer" }}
              />
            )}
          </div>
        </div>
        <div
          className="MainLiveView_Chat_Flow"
          ref={(r) => (this.messageFlow = r)}
        >
          {messages}
          {messages.length === 0 && (
            <span>{Contexts.get(Contexts.Context.noMessage)}</span>
          )}
        </div>
        <div className="MainLiveView_Chat_MessageBox">
          {this.state.replyingMessage && (
            <div className="MainLiveView_Chat_MessageBox_Reply">
              <span style={{ fontWeight: 600 }}>
                {" "}
                Replying to {this.state.replyingMessage.userName}
              </span>
              <span> {this.state.replyingMessage.message}</span>
              <RejectIcon
                onClick={() => this.setState({ replyingMessage: null })}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 2,
                  top: 0,
                  width: 16,
                }}
              />
            </div>
          )}
          <TextArea
            multiline
            style={{ maxHeight: "80" }}
            placeholder={Contexts.get(Contexts.Context.messagePlaceHolder)}
            value={this.state.newMessage}
            inputProps={{
              "aria-label": "description",
            }}
            onKeyPress={(e) => this.handleKeyPress(e)}
            onChange={(e) =>
              this.setState({ newMessage: e.target.value.substring(0, 500) })
            }
          />
          <div style={{ textAlign: "right", marginTop: "6px" }}>
            <Button
              color="primary"
              style={{ width: 70 }}
              disabled={this.state.newMessage.length < 1 || !activeUser()}
              onClick={() => this.sendChatMessage()}
            >
              {Contexts.get(Contexts.Context.send)}
            </Button>
          </div>
        </div>
        <VideoSelectDialog
          open={this.state.openVideoSelectDialog}
          title={"Select original video to copy messages"}
          onChange={(v) => {
            this.handleSelectedCopyItem(v);
          }}
          closeDialog={() => {
            this.setState({ openVideoSelectDialog: false });
          }}
        />
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <LiveChat {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
