import React from "react";
import "../Components.css";
import { BaseContext } from "../baseContext";
import AddAlert from "@material-ui/icons/Error";
import Done from "@material-ui/icons/CloudDoneOutlined";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import Snackbar from "./materialComponents/Snackbar/Snackbar.jsx";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dropbox from "dropbox";
import Contexts from "../contexts";
import { activeUser, addNewFile } from "../api";
import { withRouter } from "react-router-dom";

const MAX_FILE_SIZE_LIMIT = 5 * 1024 * 1024 * 1024; // 5gb

class VideoUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 0,
      data: {
        nameError: "",
        file: null,
        name: "",
        description: ""
      }
    };
  }
  handleChange(key, value) {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [key]: value
      }
    });
  }

  handleFileChanged(e) {
    if (e.target.files.length > 1) {
      this.setState({
        error: Contexts.get(Contexts.Context.pleaseSelectOnFile),
        showError: true
      });
      return;
    }
    const file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE_LIMIT) {
      this.setState({
        error: Contexts.get(Contexts.Context.maximumFileSize),
        showError: true
      });
      return;
    }
    this.setState({ showError: false });
    this.handleChange("file", file);
  }
  validate() {
    let isValid = true;
    const { data } = this.state;
    data.nameError = "";
    if (data.name.length === 0) {
      data.nameError = Contexts.get(Contexts.Context.nameIsRequired);
      isValid = false;
    }
    if (data.name.length < 5) {
      data.nameError = Contexts.get(Contexts.Context.nameIsTooShort);
      isValid = false;
    }
    this.setState({ data: data });
    return isValid;
  }
  uploadFile() {
    if (this.validate()) {
      var file = this.state.data.file;
      const videoData = {
        fileName: file.name,
        name: this.state.data.name,
        description: this.state.data.description,
        fileSize: file.size,
        uid: activeUser().uid,
        date: new Date()
      };
      addNewFile(videoData).then(videoId => {
        const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
        var ACCESS_TOKEN =
          "PkxcjFJzdJAAAAAAAAAAHZ11o6TQz8GJ6w6Q7JrKZVcpcmvOt6sg9Fi-r3eE0rrY";
        var dbx = new Dropbox.Dropbox({ accessToken: ACCESS_TOKEN });
        this.setState({ status: 1 });

        if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
          // File is smaller than 150 Mb - use filesUpload API
          dbx
            .filesUpload({
              path: "/" + videoId + "/" + file.name,
              contents: file
            })
            .then(response => {
              this.setState({ status: 2 });
              console.log(response);
            })
            .catch(error => {
              this.setState({ status: 0 });
              this.setState({
                error: Contexts.get(Contexts.Context.somethingWentWrong),
                showError: true
              });
              console.error(error);
            });
        } else {
          // File is bigger than 150 Mb - use filesUploadSession* API
          const maxBlob = 8 * 1000 * 1000; // 8Mb - Dropbox JavaScript API suggested max file / chunk size
          var workItems = [];

          var offset = 0;
          while (offset < file.size) {
            var chunkSize = Math.min(maxBlob, file.size - offset);
            workItems.push(file.slice(offset, offset + chunkSize));
            offset += chunkSize;
          }

          const task = workItems.reduce((acc, blob, idx, items) => {
            if (idx === 0) {
              this.setState({ fileSize: items.length * 8, uploadedSize: 0 });
              // Starting multipart upload of file
              return acc.then(() => {
                return dbx
                  .filesUploadSessionStart({ close: false, contents: blob })
                  .then(response => response.session_id);
              });
            } else if (idx < items.length - 1) {
              // Append part to the upload session
              return acc.then(sessionId => {
                this.setState({ uploadedSize: idx * 8 });
                var cursor = { session_id: sessionId, offset: idx * maxBlob };
                return dbx
                  .filesUploadSessionAppendV2({
                    cursor: cursor,
                    close: false,
                    contents: blob
                  })
                  .then(() => sessionId);
              });
            } else {
              // Last chunk of data, close session
              return acc.then(function(sessionId) {
                var cursor = {
                  session_id: sessionId,
                  offset: file.size - blob.size
                };
                var commit = {
                  path: "/" + videoId + "/" + file.name,
                  mode: "add",
                  autorename: true,
                  mute: false
                };
                return dbx.filesUploadSessionFinish({
                  cursor: cursor,
                  commit: commit,
                  contents: blob
                });
              });
            }
          }, Promise.resolve());

          task
            .then(result => {
              this.setState({ status: 2 });
            })
            .catch(error => {
              this.setState({ status: 0 });
              this.setState({
                error: Contexts.get(Contexts.Context.somethingWentWrong),
                showError: true
              });
              console.error(error);
            });
        }
      });
    }
    return false;
  }
  render() {
    const { status, data } = this.state;
    return (
      <div className="VideoUpload">
        <div className="VideoUpload_Header">
          {Contexts.get(Contexts.Context.videoUpload)}
        </div>
        <div className="VideoUpload_Inner">
          {status === 1 && (
            <div className="VideoUpload_Uploading">
              <CircularProgress />
              {Contexts.get(Contexts.Context.fileIsUploading)}
              {this.state.fileSize && (
                <span>
                  ({this.state.uploadedSize} / {this.state.fileSize} MB)
                </span>
              )}
            </div>
          )}
          {status === 2 && (
            <div className="VideoUpload_Uploaded">
              <Done color="green" fontSize="large" />
              {Contexts.get(Contexts.Context.fileUploaded)}
              <Button
                onClick={() => this.props.history.push("/")}
                style={{ fontSize: "1em", marginTop: "20px" }}
                color="info"
              >
                {Contexts.get(Contexts.Context.back)}
              </Button>
            </div>
          )}
          {status === 0 && (
            <div className="SignIn_Inner_Form">
              <Snackbar
                place="bc"
                color="danger"
                icon={AddAlert}
                message={this.state.error}
                open={this.state.showError}
                closeNotification={() => this.setState({ showError: false })}
                close
              />
              <Snackbar
                place="bc"
                color="info"
                icon={AddAlert}
                message={this.state.message}
                open={this.state.showMessage}
                closeNotification={() => this.setState({ showMessage: false })}
                close
              />
              <TextField
                id="name"
                variant="outlined"
                label={Contexts.get(Contexts.Context.videoTile)}
                type="text"
                value={data.name}
                onChange={e => this.handleChange("name", e.target.value)}
              />
              {data.nameError && data.nameError.length > 0 && (
                <div className="input-error">{data.nameError}</div>
              )}
              <TextField
                id="description"
                style={{ marginTop: "20px" }}
                multiline
                rowsMax="4"
                variant="outlined"
                value={data.description}
                label={Contexts.get(Contexts.Context.videoDescription)}
                type="text"
                onChange={e => this.handleChange("description", e.target.value)}
              />
              <TextField
                id="file"
                style={{ marginTop: "20px" }}
                variant="outlined"
                label=""
                type="file"
                onChange={e => this.handleFileChanged(e)}
              />
              <Button
                onClick={() => this.uploadFile()}
                disabled={!this.state.data.file}
                style={{
                  width: "100%",
                  height: "50px",
                  fontSize: "1em",
                  marginTop: "20px"
                }}
                color="info"
              >
                {Contexts.get(Contexts.Context.upload)}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <VideoUpload {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
