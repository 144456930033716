import React from "react";
import "../../Components.css";
import { BaseContext } from "../../baseContext";
import { withRouter } from "react-router-dom";
import Button from "../materialComponents/CustomButtons/Button.jsx";
import { CircularProgress } from "@material-ui/core";
import AddAlert from "@material-ui/icons/Error";
import Done from "@material-ui/icons/Done";
import Snackbar from "../materialComponents/Snackbar/Snackbar.jsx";
import Contexts from "../../contexts";
import { activeUser, applyPromotionCode } from "../../api";
import { priceUnits } from "../../consts";

class OrderInfo extends React.Component {
  constructor(props) {
    super(props);

    const user = activeUser();

    this.state = {
      loading: false,
      error: null,
      name: user ? user.displayName : "",
      promotionCode: "",
      promotionCodeId: null,
      discount: 0,
      discountedPrice: props.paidEvent.price,
    };
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleApplyPromotionCode();
    }
  }
  handleApplyPromotionCode() {
    const { promotionCode } = this.state;
    const { paidEvent } = this.props;
    this.setState({ loading: true });
    applyPromotionCode(paidEvent.id, promotionCode, activeUser().uid).then(
      (result) => {
        if (result.error) {
          this.setState({ successMessage: "",error: Contexts.get(Contexts.Context[result.errorCode]), loading: false });
        } else {
          this.setState({
            error: "",
            successMessage: Contexts.get(Contexts.Context.promotionSuccess).replace("_discount_", result.discount),
            promotionCodeId: result.promotionCodeId,
            discount: result.discount,
            discountedPrice: result.discountedPrice,
            loading: false,
          });
          this.props.onSetPromotion(result.promotionCodeId, result.discountedPrice);
          debugger;
          if (result.discount == 100) {
            this.props.onPaymentSucceeded();
          }
        }
      }
    );
  }
  render() {
    const {
      promotionCode,
      discountedPrice,
      discount,
      promotionCodeId,
      loading,
    } = this.state;
    const { paidEvent } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Snackbar
          place="bc"
          color="danger"
          icon={AddAlert}
          message={this.state.error}
          open={this.state.error && this.state.error.length > 0}
          closeNotification={() => this.setState({ error: "" })}
          close
        />
        <Snackbar
          place="bc"
          color="success"
          icon={Done}
          message={this.state.successMessage}
          open={
            this.state.successMessage && this.state.successMessage.length > 0
          }
          closeNotification={() => this.setState({ successMessage: "" })}
          close
        />
        <div className={"card-item"}>
          <div className="card-item__side -front">
            <div style={{ backgroundColor: "#f6f6f7" }}>
              <img
                alt=""
                style={{ height: 270 }}
                src={paidEvent.thumbnailUrl}
                className="card-item__bg"
              />
            </div>
          </div>
        </div>
        <div className="card-form__inner" style={{ paddingTop: 120 }}>
          <h3 style={{ textAlign: "center", marginBottom: 20 }}> {Contexts.get(Contexts.Context.orderInfo)}</h3>
          <div className="card-input" style={{ marginBottom: 10 }}>
            <label className="card-input__label" htmlFor="name">
              {Contexts.get(Contexts.Context.eventName)}
            </label>
            <span style={{ marginTop: 0 }}>{paidEvent.name}</span>
          </div>
          <div className="card-input" style={{ marginBottom: 10 }}>
            <label className="card-input__label" htmlFor="name">
              {Contexts.get(Contexts.Context.description)}
            </label>
            <span style={{ marginTop: 0 }}>{paidEvent.description}</span>
          </div>
          <div className="card-input" style={{ marginBottom: 10 }}>
            <label className="card-input__label" htmlFor="promotionCode">
              {Contexts.get(Contexts.Context.promotionCode)}
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                id="promotionCode"
                className="StripeElement"
                style={{ height: 40, width: 150, marginRight: 10 }}
                value={promotionCode}
                onKeyPress={(e) => this.handleKeyPress(e)}
                onChange={(e) =>
                  this.setState({ promotionCode: e.target.value })
                }
              />
              <Button onClick={() => this.handleApplyPromotionCode()} color="info">
                {loading && <CircularProgress size={14} />}
                {!loading && Contexts.get(Contexts.Context.apply)}
              </Button>
            </div>
          </div>
          <div className="card-input" style={{ marginTop: 10 }}>
            <label className="card-input__label" htmlFor="name">
              {Contexts.get(Contexts.Context.price)}
            </label>
            {!discount && (
              <h3 style={{ marginTop: 0, fontWeight: 500 }}>
                {paidEvent.price + " " + priceUnits[paidEvent.priceUnit]}
              </h3>
            )}
            {!!(discount && discountedPrice !== paidEvent.price) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    margin: 0,
                    marginRight: 10,
                    textDecoration: "line-through",
                  }}
                >
                  {paidEvent.price + " " + priceUnits[paidEvent.priceUnit]}
                </h6>
                <h3 style={{ margin: 0 }}>
                  {discountedPrice + " " + priceUnits[paidEvent.priceUnit]}
                </h3>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() =>
                this.props.onContinue(promotionCodeId, discountedPrice)
              }
              color="info"
            >
              {Contexts.get(Contexts.Context.contunie)}
            </Button>
          </div>
          {this.state.loading && (
            <div className="SignIn_Header">Lütfen bekleyin</div>
          )}
        </div>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {(context) => <OrderInfo {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withRouter(withContext);
