import React from "react";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import Notifications from "@material-ui/icons/Notifications";
import Contexts from "../contexts";
import moment from "moment-timezone";

function LiveEventReminder({ ...props }) {
  const { userFollow, time } = props;
  return (
    <div className={"LiveEventReminder fade-in"}>
      <div className="LiveEventReminder_Container">
        <img src="/assets/images/live_icon.png" className="LiveIcon" />
        <div className="LiveEventReminder_Container_Info">
          <span>{Contexts.get(Contexts.Context.liveEvent)}</span>
          <span>{moment.tz(time*1000, "Europe/Istanbul").tz(moment.tz.guess()).format("Do MMMM - HH:mm z")}</span>
        </div>
        <div
          className="LiveEventReminder_Button"
          onClick={() => {
            props.onClick();
          }}
        >
          {!userFollow && Contexts.get(Contexts.Context.getNotification)}
          {userFollow && Contexts.get(Contexts.Context.dontGetNotification)}
          {userFollow && <NotificationsActive className="NotificationIcon" />}
          {!userFollow && <Notifications className="NotificationIcon" />}
        </div>
      </div>
    </div>
  );
}

export default LiveEventReminder;
