import React from "react";
import "../Components.css";
import VideoThumbnail from "./VideoThumbnail";
import Delete from "@material-ui/icons/Delete";
import Button from "./materialComponents/CustomButtons/Button.jsx";
import moment from "moment";

export default class AlbumList extends React.Component {
  renderBlank() {
    var videos = [];
    for (let i = 0; i < 10; i++) {
      videos.push(<VideoThumbnail key={i} blank />);
    }
    return (
      <div className="AlbumList">
        <div className="AlbumList_Title_blank" />
        <div className={"AlbumList_Videos_" + this.props.type}>{videos}</div>
      </div>
    );
  }
  render() {
    var videos = [];
    let { liveEvents } = this.props.data || {};
    if (!liveEvents) {
      liveEvents = [];
    }

    if (this.props.videos) {
      this.props.videos
        .sort((a, b) => {
          if (!this.props.sorted) {
            let liveEventA = liveEvents.find((liveEvent) => {
              return liveEvent.videoId === a.id;
            });
            let liveEventB = liveEvents.find((liveEvent) => {
              return liveEvent.videoId === b.id;
            });
            const aTime = liveEventA ? liveEventA.time :  moment(a.release_time);
            const bTime = liveEventB ? liveEventB.time :  moment(b.release_time);
            return bTime - aTime;
          }
        })
        .forEach((video) => {
          const rand = 1 + Math.random() * 10000;
          if (video) {
            videos.push(
              <VideoThumbnail
                key={video.id + rand}
                video={video}
                handleTumbnailClick={(id) =>
                  this.props.handleTumbnailClick &&
                  this.props.handleTumbnailClick(id)
                }
              />
            );
          }
        });
    } else {
      return this.renderBlank();
    }
    return (
      <div className="AlbumList">
        <div className="AlbumList_Title">
          {this.props.album.name}
          {this.props.admin && (
            <div>
              {this.props.upcomingEvents && (
                <Button
                  size="sm"
                  round
                  color="989898"
                  onClick={() => this.props.handleShowVideoSelect()}
                >
                  Add Event
                </Button>
              )}
              <Button
                size="sm"
                round
                color="989898"
                onClick={() =>
                  this.props.setType &&
                  this.props.setType(this.props.albumConfigId, 1)
                }
              >
                Type 1
              </Button>
              <Button
                size="sm"
                round
                color="989898"
                onClick={() =>
                  this.props.setType &&
                  this.props.setType(this.props.albumConfigId, 2)
                }
              >
                Type 2
              </Button>
              <Button
                size="sm"
                justIcon
                round
                color="989898"
                onClick={() => this.props.onDelete(this.props.albumConfigId)}
              >
                <Delete nativeColor="#fff" />
              </Button>
            </div>
          )}
        </div>
        <div className={"AlbumList_Videos_" + this.props.type}>{videos}</div>
      </div>
    );
  }
}
