import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import App from "./App";
import { createBrowserHistory } from "history";
import { Router, Route } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import "assets/css/material-dashboard-react.css?v=1.5.0";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";
import "./draft.css";
import moment from "moment";

const hist = createBrowserHistory();

/* const script = document.createElement("script");
window.CRISP_WEBSITE_ID = "a62457a8-9ed5-424d-b636-f95fd74c2650";
script.src = "https://client.crisp.im/l.js";
script.async = true;

document.head.appendChild(script);   */

let trLocale = require("moment/locale/tr");
moment.locale("tr", trLocale);
const lang = window.navigator.userLanguage || window.navigator.language;
if (lang == "tr" || lang == "tr-TR") {
    moment.locale("tr", trLocale);
} else  moment.locale("en-US");

Sentry.init({
  dsn: "https://cba02cd65b18411883c3d2d5be2c93fe@sentry.io/5188608",
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/", 
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    "Error: A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
  ]
});

ReactGA.initialize("UA-139121397-1");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
  <Router history={hist}>
    <div>
      <ScrollMemory />
      <Route exact path="/" render={() => <App mainPage />} />
      <Route exact path="/live" render={() => <App mainPage />} />
      <Route exact path="/watchLive" render={() => <App watchLiveVideo />} />
      <Route exact path="/videoUpload" render={() => <App videoUpload />} />
      <Route exact path="/signIn" render={() => <App signIn />} />
      <Route exact path="/validateEmail" render={() => <App validateEmail />} />
      <Route exact path="/unsubscribe" render={() => <App unsubscribe />} />
      <Route exact path="/signUp" render={() => <App signUp />} />
      <Route
        exact
        path="/termsandconditions"
        render={() => <App termsAndConditions />}
      />
      <Route exact path="/users" render={() => <App users />} />
      <Route
        exact
        path="/uploadedVideos"
        render={() => <App uploadedVideos />}
      />
      <Route exact path="/newMessages" render={() => <App newMessages />} />
      <Route exact path="/tags" render={() => <App tags />} />
      <Route exact path="/videos" render={() => <App videos />} />
      <Route exact path="/albums" render={() => <App albums />} />
      <Route exact path="/searchResult" render={() => <App searchResult />} />
      <Route exact path="/acceptPayment" render={() => <App acceptPayment />} />
      <Route exact path="/manageLive" render={() => <App manageLive />} />
      <Route exact path="/myPurchases" render={() => <App  myPurchases/>} />
      <Route exact path="/history" render={() => <App  myHistory/>} />
    </div>
  </Router>,
  document.getElementById("root")
);
