import React, { Component } from 'react';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Table from "components/materialComponents/Table/Table.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import forEach from 'lodash/forEach';
import { getUploadedVideos, getUsers } from '../api';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
};

class UploadedVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      uploadedVideos: null,
    }
  }

  componentDidMount() {
    getUploadedVideos().then((uploadedVideos) => {
      getUsers().then(users => {
        this.setState({ uploadedVideos: uploadedVideos, users: users });
      });
    });
  }
  render() {
    const { classes } = this.props;
    let columns = [
      { name: "id", type: "string", title: "ID" },
      { name: "user", type: "string", title: "User" },
      { name: "name", type: "string", title: "Name" },
      { name: "fileName", type: "string", title: "File Name" },
      { name: "description", type: "string", title: "Description" },
      { name: "date", type: "string", title: "Date" },
    ];
    const data = [];
    if (this.state.uploadedVideos) {
      forEach(this.state.uploadedVideos, (uploadedVideo, i) => {
        const user = this.state.users[uploadedVideo.uid];
        uploadedVideo.user = user ? user.name : '-';
        data.push(uploadedVideo);
      });
    }
    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Uploaded Videos</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  columns={columns}
                  tableData={data}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(UploadedVideos);
