import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Table from "components/materialComponents/Table/Table.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import Button from "../components/materialComponents/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import { paths } from "../api";
import * as firebase from "firebase";
import { BaseContext } from "../baseContext";
import { addTag, deleteTag } from "../api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: {},
      name: ""
    };
  }

  componentDidMount() {
    const commentsRef = firebase.database().ref(paths.tags);
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.tags[data.key]) {
        that.state.tags[data.key] = data.val();
        that.setState(that.state.tags);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.tags[data.key] = data.val();
      that.setState(that.state.tags);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.tags[data.key];
      that.setState(that.state.tags);
    });
  }
  handleDelete(tag) {
    if (this.state.tags) {
      deleteTag(tag.id);
    }
  }
  handleAdd() {
    addTag(this.state.name);
  }
  handleChange(key, value) {
    this.setState({
      ...this.state,
      [key]: value
    });
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleAdd();
    }
  }
  render() {
    const { classes } = this.props;
    let columns = [
      { name: "id", type: "string", title: "Id" },
      { name: "tag", type: "string", title: "Tag" },
      {
        name: "delete",
        type: "button",
        title: "Delete",
        action: id => this.handleDelete(id)
      }
    ];
    const items = [];
    if (this.state.tags) {
      Object.keys(this.state.tags).forEach(tagId => {
        const tag = this.state.tags[tagId];
        if (tag) {
          items.push({
            id: tagId,
            tag: tag.name,
            delete: tagId
          });
        }
      });
    }
    return (
      <div className="Main">
        <div style={{ display: "flex" }}>
          <TextField
            id="name"
            label={"Tag Name"}
            type="text"
            value={this.state.name}
            onKeyPress={e => this.handleKeyPress(e)}
            onChange={e => this.handleChange("name", e.target.value)}
          />
          <Button
            onClick={() => this.handleAdd()}
            style={{
              width: "100px",
              height: "50px",
              fontSize: "1em",
              marginLeft: "10px"
            }}
            color="info"
          >
            {"Add"}
          </Button>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className={classes.cardTitleWhite}>All Tags</h4>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  columns={columns}
                  tableData={items}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <Tags {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withStyles(styles)(withRouter(withContext));
