import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/materialComponents/Grid/GridItem.jsx";
import GridContainer from "components/materialComponents/Grid/GridContainer.jsx";
import Table from "components/materialComponents/Table/Table.jsx";
import Card from "components/materialComponents/Card/Card.jsx";
import CardHeader from "components/materialComponents/Card/CardHeader.jsx";
import CardBody from "components/materialComponents/Card/CardBody.jsx";
import Button from "@material-ui/core/Button";
import forEach from "lodash/forEach";
import { paths } from "../api";
import * as firebase from "firebase";
import { BaseContext } from "../baseContext";
import { activeUser, approveChatMessage, rejectChatMessage } from "../api";
const moment = require("moment");

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class WaitingApproveMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: {}
    };
  }

  componentDidMount() {
    const commentsRef = firebase.database().ref(paths.videoChatMessages + "/");
    const that = this;
    commentsRef.on("child_added", function(data) {
      if (!that.state.messages[data.key]) {
        that.state.messages[data.key] = data.val();
        that.setState(that.state.messages);
      }
    });
    commentsRef.on("child_changed", function(data) {
      that.state.messages[data.key] = data.val();
      that.setState(that.state.messages);
    });
    commentsRef.on("child_removed", function(data) {
      delete that.state.messages[data.key];
      that.setState(that.state.messages);
    });
  }
  handleApproveAll() {
    if (this.state.messages) {
      const user = activeUser();
      const { data } = this.props.baseContext.baseContext;
      Object.keys(this.state.messages).forEach(videoId => {
        const video = data.videos[videoId];
        if (video) {
          const videoChatMessages = this.state.messages[videoId];
          forEach(videoChatMessages, message => {
            if (message && !message.approveDate && !message.rejectDate)
              approveChatMessage(user, video.id, message.id);
          });
        }
      });
    }
  }
  handleRejectAll() {
    if (this.state.messages) {
      const user = activeUser();
      const { data } = this.props.baseContext.baseContext;
      Object.keys(this.state.messages).forEach(videoId => {
        const video = data.videos[videoId];
        if (video) {
          const videoChatMessages = this.state.messages[videoId];
          forEach(videoChatMessages, message => {
            if (message && !message.approveDate && !message.rejectDate)
              rejectChatMessage(user, video.id, message.id);
          });
        }
      });
    }
  }
  handleApprove(message) {
    approveChatMessage(activeUser(), message.videoId, message.messageId);
  }
  handleReject(message) {
    rejectChatMessage(activeUser(), message.videoId, message.messageId);
  }
  handleCopy(message) {
    navigator.clipboard.writeText(
      message.userName + ": " + message.message
    )
  }
  render() {
    const { classes } = this.props;
    let columns = [
      { name: "videoName", type: "string", title: "Video" },
      { name: "userName", type: "string", title: "User" },
      { name: "date", type: "string", title: "Date" },
      { name: "message", type: "string", title: "Message" },
      {
        name: "approve",
        type: "button",
        title: "Approve",
        action: id => this.handleApprove(id)
      },
      {
        name: "reject",
        type: "button",
        title: "Reject",
        action: id => this.handleReject(id)
      },
      {
        name: "copy",
        type: "button",
        title: "Copy",
        action: id => this.handleCopy(id)
      }
    ];
    const { data } = this.props.baseContext.baseContext;
    const items = [];
    if (this.state.messages && data.videos) {
      Object.keys(this.state.messages).forEach(videoId => {
        const video = data.videos[videoId];

        if (video) {
          const videoChatMessages = this.state.messages[videoId];
          forEach(videoChatMessages, message => {
            if (message && !message.approveDate && !message.rejectDate)
              items.push({
                videoName: video.name,
                userName: message.userName,
                date: moment(message.date * 1000).toString(),
                message: message.message.type ? message.message.type : message.message,
                videoId: video.id,
                messageId: message.id,
                approve: message.id
              });
          });
        }
      });
    }
    return (
      <div className="Main">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4 className={classes.cardTitleWhite}>New Chat Messages</h4>
                <Button
                  color="primary"
                  style={{ width: 120 }}
                  onClick={() => this.handleApproveAll()}
                >
                  Approve All
                </Button>
                <Button
                  color="primary"
                  style={{ width: 120 }}
                  onClick={() => this.handleRejectAll()}
                >
                  Reject All
                </Button>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  columns={columns}
                  tableData={items}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const withContext = React.forwardRef((props, ref) => (
  <BaseContext.Consumer>
    {context => <WaitingApproveMessages {...props} baseContext={context} />}
  </BaseContext.Consumer>
));

export default withStyles(styles)(withRouter(withContext));
